import { useState, useEffect } from "react";
import { calcularGanhosMensais } from "../utils/calc-gains";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import "./lp.css";
import Accordion from "../components/accordion";

const faqData = [
  {
    title: `Quais suplementos posso receber gratuitamente?`,
    text: `Você tem a opção de selecionar, juntamente com os gestores, um kit de suplementos no valor de até 400 reais. Ao atingir metas mensais, você pode renovar seus produtos mensais, o que significa que se alcançar as metas todos os meses, terá direito a receber suplementos gratuitos.`,
  },
  {
    title: `Quais metas devo atingir para ter produtos gratuitos todos os meses?`,
    text: `Para garantir produtos gratuitos todos os meses, você precisa ter 5 cupons ativos. Isso significa que durante o mês, é necessário influenciar seus seguidores a fazer compras utilizando o seu cupom exclusivo. Você poderá acompanhar o número de cupons utilizados no aplicativo exclusivo da Body Dry Nutrition.`,
  },
  {
    title: `Como consigo visualizar meus ganhos mensais?`,
    text: `Você pode visualizar todos os seus ganhos no aplicativo exclusivo desenvolvido para influenciadores. Lá, terá acesso a detalhes e informações sobre seus rendimentos mensais.`,
  },
  {
    title: `como posso indicar novos influenciadores ?`,
    text: `Você pode indicar novos influenciadores de diversas maneiras. Uma opção é anunciar em suas redes sociais ou indicar diretamente para pessoas com as quais você possui uma relação mais próxima. Recomendamos que compartilhe o link <a>https://influencer.bodydrynutrition.com.br/</a> com eles e sugira que preencham as informações, mencionando que foi você quem os indicou.
    <br>
    Após o preenchimento, nossa equipe realizará uma análise para verificar se a pessoa possui o perfil adequado para fazer parte do time. Se a candidatura for aceita, a pessoa será direcionada para a página de cadastro e ficará vinculada como um indicado seu. Essa abordagem permite que você amplie a rede de influenciadores associados à sua indicação.`,
  },
  {
    title: `Como ocorre o pagamento das comissões?`,
    text: `Ao realizar o cadastro, assegure-se de inserir corretamente os dados bancários. As comissões serão transferidas para sua conta bancária por meio de PIX ou transferência, até o quinto dia útil do mês seguinte. Por exemplo, se acumulou R$ 2.000,00 em comissões em janeiro, o pagamento será efetuado até o quinto dia útil de fevereiro, seguindo esse mesmo padrão nos meses subsequentes.`,
  },
  {
    title: `Quais suplementos você tem atualmente?`,
    text: `Atualmente, dispomos de quatro suplementos ativos: Thermo Day, Thermo Night, Tuned Coffee nos sabores tradicional e chocolate com avelã. Além desses, estamos em processo de fabricação da creatina e whey protein. Nossa meta até o final de 2024 é ampliar nossa linha para oferecer uma variedade de 15 suplementos.`,
  },
  {
    title: `Essa estimativa de comissão é baseada em quê?`,
    text: `Ela é fundamentada nas suas métricas e no comprometimento com a marca. Quanto mais postagens você realizar, maior a probabilidade de aprimorar essas projeções. Cada influenciador ativo em nossa plataforma possui um estudo de caso, e utilizamos essas métricas para chegar o mais próximo possível da comissão real correspondente ao seu perfil.`,
  },
  {
    title: `Eu não atingi as metas para ganhar produtos gratuitos. O que devo fazer?`,
    text: `Você pode solicitar à nossa equipe um desconto de 50% para adquirir os produtos a quase preço de custo, permitindo que continue a promover a nossa marca. Isso proporciona uma alternativa para manter seu envolvimento mesmo se não atingir as metas para ganhar produtos gratuitos.`,
  },
  {
    title: `Posso comprar os produtos com 50% de desconto e revender?`,
    text: `Não, essa prática não é permitida. Está expressamente proibido pelo contrato, e o descumprimento dessas normas pode acarretar em punições.`,
  },
  {
    title: `Posso divulgar outras marcas?`,
    text: `Sim, você pode divulgar outras marcas, contanto que não estejam relacionadas ao mesmo nicho ou ofereçam os mesmos suplementos que a Body Dry. Em situações de dúvida, recomendamos que entre em contato com nossa equipe para avaliarmos caso a caso. Nosso objetivo é garantir que você não divida a atenção do seu público promovendo outras marcas do mesmo nicho, possibilitando assim alcançar as metas e objetivos finais que beneficiarão ambas as partes.`,
  },
  {
    title: `Como funcionam os eventos?`,
    text: `Anualmente, realizaremos um evento especial para todos os influenciadores ativos, repleto de diversas atrações. Durante essa celebração, iremos premiar os influenciadores que se destacarem no ranking e destacar a importância de cada um em nosso time. As projeções do ranking estarão disponíveis no aplicativo exclusivo para influenciadores, e as datas do evento serão comunicadas com antecedência. Fique atento, pois os prêmios reservam surpresas, incluindo viagens internacionais e muito mais. Nossa meta é construir a melhor equipe e recompensá-los na mesma proporção.`,
  },
];

const LP = (props) => {
  const history = useHistory();

  const [formData, setFormData] = useState({
    storiesViews: "",
    reelsViews: "",
    influenceScale: 0,
    peopleRecomendations: "",
    galleryCount: "",
    canSendPhone: true,
    phone: "" || null,
  });
  const [gains, setGains] = useState(null);
  const [isButtonLoading, setButtonLoading] = useState(false);

  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const handleGains = async () => {
    if (
      formData.storiesViews <= 0 &&
      formData.reelsViews <= 0 &&
      formData?.peopleRecomendations <= 0 &&
      formData.galleryCount <= 0
    ) {
      toast.error("Preencha todas as informações!");
    } else {
      setGains(null);
      setButtonLoading(true);
      await sleep(5000);
      const ganhos = calcularGanhosMensais(
        formData.storiesViews,
        formData.reelsViews,
        formData.influenceScale + 1,
        formData?.peopleRecomendations,
        formData.galleryCount
      );
      setGains(ganhos);
      setButtonLoading(false);
    }
  };

  function sleep(intervalo) {
    return new Promise((resolve) => {
      setTimeout(resolve, intervalo);
    });
  }

  return (
    <div className="lp-container">
      <Helmet>
        <title>LP - Body Dry</title>
        <meta property="og:title" content="LP - Body Dry" />
      </Helmet>
      <div className="lp-container1">
        <img alt="image" src="/logo.svg" className="lp-logo" />
        <video
          controls
          src="https://bodydry.s3.sa-east-1.amazonaws.com/IMG_1079.MP4"
          className="lp-video"
        ></video>
        <div className="lp-visualizacoes-stories">
          <span className="lp-text">
            <span>
              Quantas visualizações, em média, você recebe diariamente nos
              stories do Instagram?
            </span>
          </span>
          <div className="lp-resposta">
            <span className="lp-label TextSmall-14Regular">
              <span>Resposta</span>
            </span>
            <input
              value={formData?.storiesViews}
              onChange={(e) => updateFormData({ storiesViews: e.target.value })}
              type="number"
              placeholder="Ex: 5.000"
              className="lp-textinput input"
            />
          </div>
        </div>
        <div className="lp-visualizacoes-reels">
          <span className="lp-text03">
            Qual é a média de visualizações que seus vídeos do Reels no
            Instagram costumam alcançar?
          </span>
          <div className="lp-resposta1">
            <span className="lp-label1 TextSmall-14Regular">
              <span>Resposta</span>
            </span>
            <input
              value={formData?.reelsViews}
              onChange={(e) => updateFormData({ reelsViews: e.target.value })}
              type="number"
              placeholder="Ex: 500"
              className="lp-textinput1 input"
            />
          </div>
        </div>
        <div className="lp-influencia-stories">
          <span className="lp-text05">
            Em uma escala de 1 a 3, como você avalia a influência das suas dicas
            nas pessoas que assistem os stories no Instagram?
          </span>
          <div className="lp-opcoes">
            <div
              onClick={() => updateFormData({ influenceScale: 0 })}
              className="lp-opcao1"
            >
              {formData?.influenceScale === 0 ? (
                <img
                  alt="radiobuttonchecked5562"
                  src="/external/radiobuttonchecked5562-hnq.svg"
                  className="lp-selected"
                />
              ) : (
                <img
                  alt="lens5562"
                  src="/external/lens5562-dcdb.svg"
                  className="lp-none"
                />
              )}
              <span className="lp-text06">1 - Bom</span>
            </div>
            <div
              onClick={() => updateFormData({ influenceScale: 1 })}
              className="lp-opcao2"
            >
              {formData?.influenceScale === 1 ? (
                <img
                  alt="radiobuttonchecked5562"
                  src="/external/radiobuttonchecked5562-hnq.svg"
                  className="lp-selected"
                />
              ) : (
                <img
                  alt="lens5562"
                  src="/external/lens5562-dcdb.svg"
                  className="lp-none"
                />
              )}
              <span className="lp-text07">2 - Muito bom</span>
            </div>
            <div
              onClick={() => updateFormData({ influenceScale: 2 })}
              className="lp-opcao3"
            >
              {formData?.influenceScale === 2 ? (
                <img
                  alt="radiobuttonchecked5562"
                  src="/external/radiobuttonchecked5562-hnq.svg"
                  className="lp-selected"
                />
              ) : (
                <img
                  alt="lens5562"
                  src="/external/lens5562-dcdb.svg"
                  className="lp-none"
                />
              )}
              <span className="lp-text08">3 - Hiper mega bom</span>
            </div>
          </div>
        </div>
        <div className="lp-recomendar-equipe">
          <span className="lp-text09">
            Quantas pessoas você acredita que poderia recomendar ou influenciar
            a se juntarem à nossa equipe de influenciadores, da mesma forma que
            você está prestes a fazer
          </span>
          <div className="lp-resposta2">
            <span className="lp-label2 TextSmall-14Regular">
              <span>Resposta</span>
            </span>
            <input
              value={formData?.peopleRecomendations}
              onChange={(e) =>
                updateFormData({ peopleRecomendations: e.target.value })
              }
              type="number"
              placeholder="Ex: 50"
              className="lp-textinput2 input"
            />
          </div>
        </div>
        <div className="lp-qntd-midia-mes">
          <span className="lp-text11">
            Quantos vídeos e imagens você poderia fornecer mensalmente para que
            possamos usar sua imagem na nossa estratégia de marketing e, assim,
            aumentar sua comissão e a visibilidade tanto das suas quanto das
            nossas redes sociais
          </span>
          <div className="lp-resposta3">
            <span className="lp-label3 TextSmall-14Regular">
              <span>Resposta</span>
            </span>
            <input
              value={formData?.galleryCount}
              onChange={(e) => updateFormData({ galleryCount: e.target.value })}
              type="number"
              placeholder="Ex: 50"
              className="lp-textinput3 input"
            />
          </div>
        </div>
        <div className="lp-contato">
          <span className="lp-text13">
            Você poderia fornecer seu contato para que possamos discutir em
            detalhes como suas comissões foram calculadas com base nas
            respostas que você der?
          </span>
          <div className="lp-opcoes1">
            <div
              onClick={() => updateFormData({ canSendPhone: true })}
              className="lp-opcao-sim"
            >
              {formData?.canSendPhone ? (
                <img
                  alt="radiobuttonchecked5562"
                  src="/external/radiobuttonchecked5562-hnq.svg"
                  className="lp-selected"
                />
              ) : (
                <img
                  alt="lens5562"
                  src="/external/lens5562-dcdb.svg"
                  className="lp-none"
                />
              )}
              <span className="lp-text14">Sim</span>
            </div>
            <div
              onClick={() => updateFormData({ canSendPhone: false })}
              className="lp-opcao-sim"
            >
              {!formData?.canSendPhone ? (
                <img
                  alt="radiobuttonchecked5562"
                  src="/external/radiobuttonchecked5562-hnq.svg"
                  className="lp-selected"
                />
              ) : (
                <img
                  alt="lens5562"
                  src="/external/lens5562-dcdb.svg"
                  className="lp-none"
                />
              )}
              <span className="lp-text14">Não</span>
            </div>
            {formData?.canSendPhone && (
              <div className="lp-telefone">
                <span className="lp-label4">Telefone</span>
                <input
                  value={formData?.phone}
                  onChange={(e) => updateFormData({ phone: e.target.value })}
                  type="text"
                  placeholder="DDD + Número"
                  className="lp-textinput4 input"
                />
                <span className="lp-supporting-text">
                  <span>Digite somente números</span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="submit-button">
          <button onClick={handleGains} className="lp-botao-entrar">
            <span>{isButtonLoading ? "Simulando..." : "Simular ganhos"}</span>
            {isButtonLoading && (
              <div className="spinner-container">
                <span>Calculando</span>
                <svg
                  className="spinner"
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                    stroke="white"
                  />
                </svg>
              </div>
            )}
          </button>
        </div>
        {gains !== null && (
          <div className="lp-resposta4">
            <span className="lp-text17">
              <span>Resposta</span>
            </span>
            <span className="lp-text19">
              <span>{gains}</span><br></br><br></br><small style={{ color: '#c4c4c4' }}>Atenção: os valores simulados são com base em nossa experiência com nossos influenciadores e não se trata de uma garantia de ganhos com este mesmo valor, podendo variar mediante público, comunicação e outros fatores.</small>
            </span>
          </div>
        )}
        {gains !== null && (
          <div className="lp-entrar-no-time">
            <span className="lp-text21">
              <span>
                Se nossa proposta faz sentido clique no botão e
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br></br>
              <span>entre para o nosso time!</span>
            </span>
            <button
              onClick={() => history.push("/cadastro")}
              className="lp-botao-entrar"
            >
              <span className="lp-text25">
                <span>Entrar para o time</span>
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="faq">
        <div className="faq-header">
          <h1>PRINCIPAIS DÚVIDAS</h1>
          <span>Tire suas principais dúvidas</span>
        </div>
        <div className="faq-container">
          {faqData.map((d) => {
            return <Accordion title={d.title} content={d.text} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default LP;
