import React, { useCallback } from 'react';
import UserCard from './UserCard';
import InfluencerCard from './InfluencerCard';
import styles from './UserList.module.css';

const UserList = ({ users, activeTab, onUpdate }) => {
  const handleUpdate = useCallback(() => {
    onUpdate();
  }, [onUpdate]);

  return (
    <div className={styles.userList}>
      {users.map((user) => 
        user.isInfluencer ? (
          <InfluencerCard 
            key={user._id} 
            influencer={user} 
            activeTab={activeTab} 
            onUpdate={handleUpdate}
          />
        ) : (
          <UserCard 
            key={user._id} 
            user={user} 
            activeTab={activeTab} 
            onUpdate={handleUpdate}
          />
        )
      )}
    </div>
  );
};

export default UserList;