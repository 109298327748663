import React from 'react';
import './popup-excluir-post.css';

const PopupExcluirPost = ({ type, postTitle, commentContent, onClose, onConfirm }) => {
    return (
      <div className="popup-excluir-post-container">
        <div className="popup-excluir-post-popup">
          <div className="popup-excluir-post-header">
            <h2 className="popup-excluir-post-title">{`Excluir ${type === 'post' ? 'Post' : 'Comentário'}`}</h2>
            <button className="popup-excluir-post-close" onClick={onClose} aria-label="Fechar">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="popup-excluir-post-content">
            <p className="popup-excluir-post-message">
              Você tem certeza que deseja excluir{' '}
              <span className="popup-excluir-post-title-item">{type === 'post' ? postTitle : commentContent}</span>
              ?
            </p>
            <p className="popup-excluir-post-description">Essa ação não poderá ser desfeita.</p>
          </div>
          <div className="popup-excluir-post-actions">
            <button className="popup-excluir-post-cancel" onClick={onClose}>
              Cancelar
            </button>
            <button className="popup-excluir-post-confirm" onClick={onConfirm}>
              Excluir
            </button>
          </div>
        </div>
      </div>
    );
  };
  

export default PopupExcluirPost;
