import { FaCheckCircle, FaTimesCircle, FaWhatsapp, FaMoneyBillWave, FaInfoCircle, FaUserFriends, FaChartLine } from 'react-icons/fa';
import { IoSearchOutline } from 'react-icons/io5';

export const pipelineStages = [
  { key: 'todos', label: 'Todos', icon: <IoSearchOutline /> },
  { key: 'extraido', label: 'Extraídos', icon: <FaUserFriends /> },
  { key: 'dm_enviada', label: 'DM Enviada', icon: <FaChartLine /> },
  { key: 'dm_vista', label: 'DM Vista', icon: <FaCheckCircle /> },
  { key: 'foi_para_whatsapp', label: 'Foi para WhatsApp', icon: <FaWhatsapp /> },
  { key: 'cadastrado', label: 'Cadastrados', icon: <FaUserFriends /> },
  { key: 'reprovado', label: 'Reprovados', icon: <FaTimesCircle /> },
  { key: 'influenciador_desativado', label: 'Desativados', icon: <FaTimesCircle /> },
  { key: 'influenciador_sem_venda', label: 'Sem Vendas', icon: <FaMoneyBillWave /> },
  { key: 'influenciador_ativo', label: 'Ativos', icon: <FaCheckCircle /> },
];