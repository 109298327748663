import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/header';
import axios from 'axios';
import { MdReplay, MdPlayArrow, MdCheckCircle, MdSchool, MdClose } from 'react-icons/md';
import styles from './academy.module.css';

const AcademyDashboard = () => {
  const history = useHistory();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const coursesResponse = await axios.get(`${process.env.REACT_APP_DATABASE}/academy/courses`, { headers });
        setCourses(coursesResponse.data.courses);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Ocorreu um erro ao carregar os dados. Por favor, tente novamente mais tarde.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleQuizResponse = (course) => {
    setSelectedCourse(course);
    setShowQuizModal(true);
  };

  const startQuiz = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/academy/quiz/${selectedCourse.quizInfo.id}`, { headers });
      history.push(`/academy/quiz/${selectedCourse.quizInfo.id}`);
    } catch (error) {
      console.error('Error starting quiz:', error);
      setError('Ocorreu um erro ao iniciar o quiz. Por favor, tente novamente mais tarde.');
    }
  };

  const QuizConfirmationModal = ({ course, onClose, onStart }) => (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <MdClose size={24} />
        </button>
        <h2 className={styles.modalTitle}>Pronto para o desafio?</h2>
        <div className={styles.quizInfo}>
          <p className={styles.quizInfoItem}>
            <span className={styles.quizInfoLabel}>Perguntas:</span>
            <span className={styles.quizInfoValue}>{course?.quizInfo?.questionCount}</span>
          </p>
          <p className={styles.quizInfoItem}>
            <span className={styles.quizInfoLabel}>Pontos possíveis:</span>
            <span className={styles.quizInfoValue}>Até {course?.quizInfo?.reward}</span>
          </p>
          <p className={styles.quizInfoItem}>
            <span className={styles.quizInfoLabel}>Duração:</span>
            <span className={styles.quizInfoValue}>{course?.quizInfo?.duration} minutos</span>
          </p>
        </div>
        <p className={styles.quizDescription}>
          Prepare-se para testar seus conhecimentos! Este quiz irá desafiar sua compreensão do curso "{course.nome}". 
          Lembre-se: uma vez iniciado, o tempo começará a contar e você não poderá pausar ou retomar mais tarde.
        </p>
        <div className={styles.modalActions}>
          <button className={styles.cancelButton} onClick={onClose}>Cancelar</button>
          <button className={styles.startQuizButton} onClick={onStart}>Iniciar Quiz</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.academyDashboard}>
      <Header />
      <main className={styles.dashboardContent}>
        <div className={styles.dashboardHeader}>
          <h1>Academy</h1>
          <div className={styles.descriptionContainer}>
            <MdSchool size={24} className={styles.descriptionIcon} />
            <p className={styles.description}>Complete todas as aulas de um curso para desbloquear o quiz final. Ao responder o quiz, você acumulará pontos que contribuirão para o seu ranking.</p>
          </div>
        </div>

        {isLoading ? (
          <div className={styles.loadingSpinner}>
            <div className={styles.spinner}></div>
            <p>Carregando cursos...</p>
          </div>
        ) : error ? (
          <div className={styles.errorMessage}>
            <p>{error}</p>
          </div>
        ) : (
          <div className={styles.coursesGrid}>
            {courses.length > 0 ? (
              courses.map((course) => (
                <div
                  className={`${styles.courseCard} ${course.completed ? styles.courseCardCompleted : ''} ${
                    course.quizCompleted ? styles.quizCompleted : ''
                  }`}
                  key={course._id}
                >
                  {course.completed && <div className={styles.completedSeal}>Concluído</div>}
                  <img src={course.capa} alt={course.nome} className={styles.courseCardImage} />
                  <div className={styles.courseCardContent}>
                    <h3 className={styles.courseTitleH3}>{course.nome}</h3>
                    <p className={styles.courseCategory}>{course.categoria}</p>
                    <div className={styles.courseProgressContainer}>
                      <div
                        className={styles.courseProgressBar}
                        style={{ width: `${course.progress}%` }}
                      ></div>
                      <span className={styles.courseProgressLabel}>{`${Math.round(course.progress)}%`}</span>
                    </div>
                    <div className={styles.courseActions}>
                      {course.completed && !course.quizCompleted ? (
                        <>
                          <button
                            className={`${styles.actionButton} ${styles.reviewCourseButton}`}
                            onClick={() => history.push(`/academy/aula/${course.nextLessonId}`)}
                          >
                            <MdReplay size={20} />
                            <span>Revisar Curso</span>
                          </button>
                          <button
                            className={`${styles.actionButton} ${styles.quizButton}`}
                            onClick={() => handleQuizResponse(course)}
                          >
                            <span>
                              Responder Quiz
                              <span className={styles.quizzPoints}>
                                <img src="/points.gif" alt="Pontos" className={styles.pointsIcon} />
                                {course.quizInfo.reward}
                              </span>
                            </span>
                          </button>
                        </>
                      ) : (
                        <button
                          className={`${styles.actionButton} ${styles.startCourseButton}`}
                          onClick={() => history.push(`/academy/aula/${course.nextLessonId}`)}
                        >
                          <MdPlayArrow size={20} />
                          <span>
                            {course.progress > 0 ? 'Continuar Curso' : 'Iniciar Curso'}
                          </span>
                        </button>
                      )}
                    </div>
                    {course.completed && !course.quizCompleted && (
                      <div className={styles.quizMessage}>
                        <p>
                          Parabéns! Você concluiu este curso! Responda ao quiz para ganhar mais pontos e aumentar suas
                          chances de ganhar prêmios no ranking.
                        </p>
                      </div>
                    )}
                    {course.quizCompleted && (
                      <div className={styles.quizCompletedMessage}>
                        <MdCheckCircle size={30} className={styles.quizCompletedIcon} />
                        <p>Você já respondeu ao quiz deste curso. Parabéns pelos {course.quizReward} pontos!</p>
                      </div>
                    )}
                    {!course.progress && (
                      <p className={styles.courseDescription}>
                        Dominar este curso te dará uma nova visão sobre como vender nossos produtos e aumentar suas
                        comissões.{' '}
                        <span className={styles.highlight}>Aprender é a chave para alcançar o topo!</span>
                      </p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noCoursesMessage}>
                <p>Não há cursos disponíveis no momento.</p>
              </div>
            )}
          </div>
        )}
      </main>
      {showQuizModal && selectedCourse && (
        <QuizConfirmationModal
          course={selectedCourse}
          onClose={() => setShowQuizModal(false)}
          onStart={startQuiz}
        />
      )}
    </div>
  );
};

export default AcademyDashboard;