import React, { useState } from 'react';
import './help.css';
import Header from '../components/header';
import { toast } from "react-toastify";

const Help = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles([...files, ...uploadedFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', message);
    files.forEach((file) => {
      formData.append('attachments', file);
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/user/contact`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        toast.success('E-mail enviado com sucesso. Responderemos sua solicitação o mais breve possível.')
        setSubject('');
        setMessage('');
        setFiles([]);
      } else {
        const result = await response.json();
        toast.error(result.message || 'Erro ao enviar o e-mail. Tente novamente mais tarde.');
      }
    } catch (error) {
      toast.error('Erro ao enviar o e-mail. Tente novamente mais tarde.');
      console.error('Erro ao enviar email:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="help-page">
      <Header hasBorderRadius={false} />
      <div className="help-content">
        <div className="help-info">
          <div className="help-info-overlay"></div>
          <div className="help-info-content">
            <h2 className="help-info-title">Central de Atendimento BodyDry Nutrition</h2>
            <p className="help-info-description">
              Envie sua dúvida, sugestão ou reclamação por e-mail e retornaremos em até 48 horas.
            </p>
            <img alt="Email" src="/emailicon.png" className="help-info-image" />
          </div>
        </div>
        <div className="help-form-container">
          <div className="help-form">
            <div className="help-form-header">
              <h3 className="help-form-title">Ajuda</h3>
              <p className="help-form-description">Escreva aqui como nossa equipe pode ajudar</p>
            </div>
            <form className="help-form-fields" onSubmit={handleSubmit}>
              <div className="help-form-field">
                <label htmlFor="subject" className="help-form-label">
                  Assunto
                </label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  onChange={handleSubjectChange}
                  placeholder="Assunto"
                  className="help-form-input"
                />
              </div>
              <div className="help-form-field">
                <label htmlFor="message" className="help-form-label">
                  Mensagem
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={handleMessageChange}
                  rows="5"
                  placeholder="Descreva sua solicitação aqui... "
                  className="help-form-textarea"
                ></textarea>
              </div>
              <div className="help-form-field">
                <label htmlFor="files" className="help-form-label">
                  Arquivos
                </label>
                <div className="help-form-file-upload">
                  <input
                    type="file"
                    id="files"
                    multiple
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="help-form-file-input"
                  />
                  <label htmlFor="files" className="help-form-file-label">
                    <svg viewBox="0 0 1024 1024" className="help-form-file-icon">
                      <path d="M726 554v-84h-172v-172h-84v172h-172v84h172v172h84v-172h172zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                    </svg>
                    <span className="help-form-file-text">Clique para carregar ou arraste e solte</span>
                  </label>
                </div>
                <div className="help-form-file-list">
                  {files.map((file, index) => (
                    <div key={index} className="help-form-file-item">
                      <img
                        alt={file.name}
                        src={URL.createObjectURL(file)}
                        className="help-form-file-image"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="help-form-file-remove"
                      >
                        <svg viewBox="0 0 1024 1024" className="help-form-file-remove-icon">
                          <path d="M726 554v-84h-428v84h428zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <button type="submit" className="help-form-submit-button" disabled={isLoading}>
                {isLoading ? (
                  <div className="loader">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                ) : (
                  'Enviar'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;