import { useState, Fragment, useEffect } from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

import "./perfil-form-dados-pessoais.css";

const PerfilFormDadosPessoais = (props) => {
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const dateParts = dateStr.split('T')[0].split('-');
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 12, 0, 0);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formData = props.formData;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    name: formData?.name,
    birthDate: formData?.birthDate ? formatDate(formData.birthDate) : "",
    gender: formData?.gender,
    rg: formData?.rg,
    cpf: formData?.cpf,
    email: formData?.email,
    phone: formData?.phone,
    username: formData?.username ? formData.username.replace('@', '') : "",
    instagram: formData?.instagram,
    facebook: formData?.facebook,
    youtube: formData?.youtube,
    tiktok: formData?.tiktok,
    profilePicture: formData?.profilePicture || "",
    profilePicturePreview: formData?.profilePicture || ""  
  });

  const handleGenderClick = (selectedGender) => {
    setData((prevData) => ({
      ...prevData,
      gender: selectedGender,
    }));
  };

  const handleKeyPressNumbersOnly = (e) => {
    const regex = /^[0-9]*$/;
    if (
      !regex.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }
  };

  const sanitizeEmail = (value) => {
    const parts = value.split("@");
    if (parts.length === 2) {
      const username = parts[0].replace(/[^a-zA-Z0-9._+-]/g, "");
      const domain = parts[1].replace(/[^a-zA-Z0-9.-]/g, "");
      const sanitizedUsername = username.replace(/\.+/g, ".");
      const sanitizedDomain = domain.replace(/\.+/g, ".");
      return `${sanitizedUsername}@${sanitizedDomain}`;
    }
    return value;
  };

  const sanitizeUsername = (value) => {
    let sanitizedValue = value.startsWith("@") ? value.slice(1) : value;
    sanitizedValue = sanitizedValue.replace(/[^a-zA-Z0-9_.]/g, "");
    return sanitizedValue; 
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
  
    if (name === "profilePicture" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      
      reader.onloadend = () => {
        setData((prevData) => ({
          ...prevData,
          profilePicture: file,
          profilePicturePreview: reader.result
        }));
      };
  
      reader.readAsDataURL(file);
    } else if (name === "username") {
      const sanitizedUsername = sanitizeUsername(value.replace('@', ''));
      setData((prevData) => ({
        ...prevData,
        [name]: sanitizedUsername,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };  

  useEffect(() => {
    if (!data.username) {
      setData({
        name: formData?.name,
        birthDate: formData?.birthDate ? formatDate(formData.birthDate) : "",
        gender: formData?.gender,
        rg: formData?.rg,
        cpf: formData?.cpf,
        email: formData?.email,
        phone: formData?.phone,
        username: formData?.username,
        instagram: formData?.instagram,
        facebook: formData?.facebook,
        youtube: formData?.youtube,
        tiktok: formData?.tiktok,
        profilePicture: formData?.profilePicture || "",
        profilePicturePreview: formData?.profilePicture || ""
      });
    }
  }, [props.formData]);
  

  return (
    <div className="perfil-form-dados-pessoais-perfil-form-dados-pessoais">
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open)
        }}
        className="perfil-form-dados-pessoais-frame354"
      >
        <span className="perfil-form-dados-pessoais-text HeaderH3-18Medium">
          <span>Dados pessoais</span>
        </span>
        {open ? (
          <img
            alt={props.keyboardarrowupAlt1}
            src={props.keyboardarrowupSrc1}
            className="perfil-form-dados-pessoais-keyboardarrowup"
          />
        ) : (
          <img
            alt={props.keyboardarrowupAlt}
            src={props.keyboardarrowupSrc}
            className="perfil-form-dados-pessoais-keyboardarrowdown"
          />
        )}
      </div>
      {open && (
        <Fragment>
          <div className="perfil-form-dados-pessoais-frame308">
            <span className="perfil-form-dados-pessoais-text02 TextMedium-16Medium">
              <span>Informações principais</span>
            </span>
            <div className="perfil-form-dados-pessoais-frame309">
            <div className="perfil-form-dados-pessoais-photo-upload">
  <img
    src={data.profilePicturePreview || process.env.PUBLIC_URL + "/profiledefault.jpg"}
    alt="Foto de Perfil"
    className="perfil-form-dados-pessoais-profile-photo-preview"
  />
  <input
    type="file"
    accept="image/*"
    className="input"
    name="profilePicture"
    onChange={handleInputChange}
    style={{ display: "none" }}
    id="profilePictureInput"
  />
  <label htmlFor="profilePictureInput" className="perfil-form-dados-pessoais-alterar-foto-btn">
    Alterar foto
  </label>
            </div>
              <div className="perfil-form-dados-pessoais-textfields">
                <span className="perfil-form-dados-pessoais-text04 TextSmall-14Regular">
                  <span>Nome</span>
                </span>
                <input
                  type="text"
                  placeholder={props.textinputPlaceholder1}
                  className="input"
                  name="name"
                  value={data.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="perfil-form-dados-pessoais-textfields1">
                <span className="perfil-form-dados-pessoais-text06 TextSmall-14Regular">
                  <span>Nascimento</span>
                </span>
                <div className="perfil-form-dados-pessoais-frame2">
                  <img
                    alt={props.calendartodayAlt}
                    src={props.calendartodaySrc}
                    className="perfil-form-dados-pessoais-calendartoday"
                  />
                  <InputMask
                    mask="99/99/9999"
                    type="text"
                    placeholder={props.textinputPlaceholder4}
                    value={data.birthDate}
                    name="birthDate"
                    onChange={handleInputChange}
                    className="perfil-form-dados-pessoais-textinput1 input"
                  />
                </div>
              </div>
              <div className="perfil-form-dados-pessoais-textfields2">
                <span className="perfil-form-dados-pessoais-text08 TextSmall-14Regular">
                  <span>Gênero</span>
                </span>
                <div
                  onClick={() => handleGenderClick("masculino")}
                  className="perfil-form-dados-pessoais-frame348"
                >
                  {data.gender === "masculino" ? (
                    <img
                      alt={props.selectedAlt}
                      src={props.selectedSrc}
                      className="perfil-form-dados-pessoais-selected"
                    />
                  ) : (
                    <img
                      alt={props.noneAlt}
                      src={props.noneSrc}
                      className="perfil-form-dados-pessoais-none"
                    />
                  )}
                  <span className="perfil-form-dados-pessoais-text10 TextMedium-16Regular">
                    {props.text}
                  </span>
                </div>
                <div
                  onClick={() => handleGenderClick("feminino")}
                  className="perfil-form-dados-pessoais-frame3481"
                >
                  {data.gender === "feminino" ? (
                    <img
                      alt={props.selectedAlt1}
                      src={props.selectedSrc1}
                      className="perfil-form-dados-pessoais-selected1"
                    />
                  ) : (
                    <img
                      alt={props.noneAlt1}
                      src={props.noneSrc1}
                      className="perfil-form-dados-pessoais-none1"
                    />
                  )}
                  <span className="perfil-form-dados-pessoais-text11 TextMedium-16Regular">
                    {props.text1}
                  </span>
                </div>
                <div
                  onClick={() => handleGenderClick("outro")}
                  className="perfil-form-dados-pessoais-frame3482"
                >
                  {data.gender === "outro" ? (
                    <img
                      alt={props.selectedAlt2}
                      src={props.selectedSrc2}
                      className="perfil-form-dados-pessoais-selected2"
                    />
                  ) : (
                    <img
                      alt={props.noneAlt2}
                      src={props.noneSrc2}
                      className="perfil-form-dados-pessoais-none2"
                    />
                  )}
                  <span className="perfil-form-dados-pessoais-text12 TextMedium-16Regular">
                    {props.text2}
                  </span>
                </div>
              </div>
              <div className="perfil-form-dados-pessoais-frame311">
                <div className="perfil-form-dados-pessoais-textfields3">
                  <span className="perfil-form-dados-pessoais-text13 TextSmall-14Regular">
                    <span>RG</span>
                  </span>
                  <input
                    value={data.rg}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPressNumbersOnly}
                    type="text"
                    name="rg"
                    placeholder={props.textinputPlaceholder5}
                    className="input"
                  />
                  {!props.isUserPage && (
                    <span className="perfil-form-dados-pessoais-text15 TextExtrasmall-12Regular">
                      <span>Digite somente números</span>
                    </span>
                  )}
                </div>
                <div className="perfil-form-dados-pessoais-textfields4">
                  <span className="perfil-form-dados-pessoais-text17 TextSmall-14Regular">
                    <span>CPF</span>
                  </span>
                  <input
                    value={data.cpf}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPressNumbersOnly}
                    type="text"
                    name="cpf"
                    placeholder={props.textinputPlaceholder6}
                    className="input"
                  />
                    <span className="perfil-form-dados-pessoais-text19 TextExtrasmall-12Regular">
                      <span>Digite somente números</span>
                    </span>
                </div>
              </div>
              <div className="perfil-form-dados-pessoais-textfields5">
                <span className="perfil-form-dados-pessoais-text21 TextSmall-14Regular">
                  <span>E-mail</span>
                </span>
                <input
                  value={data.email}
                  onChange={handleInputChange}
                  type="email"
                  name="email"
                  placeholder={props.textinputPlaceholder2}
                  className="input"
                />
              </div>
              <div className="perfil-form-dados-pessoais-textfields6">
                <span className="perfil-form-dados-pessoais-text23 TextSmall-14Regular">
                  <span>Telefone móvel</span>
                </span>
                    <input
                    type="text"
                    placeholder="DDD + Número"
                    className="input"
                    name="phone"
                    value={data.phone}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPressNumbersOnly}
                  />
                  <span className="perfil-form-dados-pessoais-text25 TextExtrasmall-12Regular">
                    <span>Digite somente números</span>
                  </span>
              </div>
            </div>
          </div>
          <div className="perfil-form-dados-pessoais-frame3091">
            <span className="perfil-form-dados-pessoais-text27 TextMedium-16Medium">
              <span>Dados de acesso</span>
            </span>
            <div className="perfil-form-dados-pessoais-textfields7">
              <span className="perfil-form-dados-pessoais-text29 TextSmall-14Regular">
                <span>Nome de usuário</span>
              </span>
              <input
                  readOnly={props.isUserPage}
                  value={`@${data.username}`}
                  onChange={handleInputChange}
                  type="text"
                  name="username"
                  placeholder={props.textinputPlaceholder}
                  className="input"
                />
              {!props.isUserPage && (
                <span
                  onClick={() => {
                    props.handleOpenChangePasswordPopup();
                  }}
                  className="perfil-form-dados-pessoais-text31 TextExtrasmall-12Regular"
                >
                  <span>Alterar senha</span>
                </span>
              )}
            </div>
          </div>
          <div className="page-cadastro-form-step2-frame3093">
          <div className="page-cadastro-form-step2-header">
            <span className="page-cadastro-form-step2-text40 TextMedium-16Medium">
              <span>Redes Sociais</span>
            </span>
          </div>
          <div className="page-cadastro-form-step2-frame3094">
            <div className="page-cadastro-form-step2-social-input rtx-instagram">
              <div className="page-cadastro-form-step2-icon-container">
                <img src="/instagram.png" alt="Instagram" className="page-cadastro-form-step2-icon" />
              </div>
              <div className="page-cadastro-form-step2-input-container">
                <span className="page-cadastro-form-step2-text44 TextSmall-14Regular">
                  <span>Instagram</span>
                </span>
                <input
                  type="text"
                  placeholder="Link do perfil no Instagram"
                  className="input"
                  name="instagram"
                  value={data.instagram}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="page-cadastro-form-step2-social-input rtx-facebook">
              <div className="page-cadastro-form-step2-icon-container">
                <img src="/facebook.png" alt="Facebook" className="page-cadastro-form-step2-icon" />
              </div>
              <div className="page-cadastro-form-step2-input-container">
                <span className="page-cadastro-form-step2-text46 TextSmall-14Regular">
                  <span>Facebook</span>
                </span>
                <input
                  type="text"
                  placeholder="Link do perfil no Facebook"
                  className="input"
                  name="facebook"
                  value={data.facebook}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="page-cadastro-form-step2-social-input rtx-youtube">
              <div className="page-cadastro-form-step2-icon-container">
                <img src="/youtube.png" alt="YouTube" className="page-cadastro-form-step2-icon" />
              </div>
              <div className="page-cadastro-form-step2-input-container">
                <span className="page-cadastro-form-step2-text48 TextSmall-14Regular">
                  <span>YouTube</span>
                </span>
                <input
                  type="text"
                  placeholder="Link do canal no YouTube"
                  className="input"
                  name="youtube"
                  value={data.youtube}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="page-cadastro-form-step2-social-input rtx-tiktok">
              <div className="page-cadastro-form-step2-icon-container">
                <img src="/tiktok.png" alt="TikTok" className="page-cadastro-form-step2-icon" />
              </div>
              <div className="page-cadastro-form-step2-input-container">
                <span className="page-cadastro-form-step2-text50 TextSmall-14Regular">
                  <span>TikTok</span>
                </span>
                <input
                  type="text"
                  placeholder="Link do perfil no TikTok"
                  className="input"
                  name="tiktok"
                  value={data.tiktok}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
            <div className="perfil-form-dados-pessoais-frame306">
            {!props.isUserPage && (
              <button
                onClick={props.handleOpenRemoveAccountPopup}
                className="perfil-form-dados-pessoais-buttons"
              >
                <span className="perfil-form-dados-pessoais-text33 TextMedium-16Medium">
                  <span>Excluir conta</span>
                </span>
              </button>
              )}
              <button
                  className="perfil-form-dados-pessoais-buttons1"
                  onClick={() => {
                    const updatedData = {
                      ...data,
                      username: data.username.replace('@', '')
                    };
                    props.handleUpdateUser(updatedData);
                  }}
                >
                  <span className="perfil-form-dados-pessoais-text35 TextMedium-16Medium">
                    <span>Atualizar informações</span>
                  </span>
                </button>

            </div>
        </Fragment>
      )}
    </div>
  );
};

PerfilFormDadosPessoais.defaultProps = {
  text: "Masculino",
  textinputPlaceholder6: "CPF",
  textinputPlaceholder2: "E-mail",
  text1: "Feminino",
  selectedSrc: "/external/radiobuttonchecked5562-hnq.svg",
  noneSrc2: "/external/lens5562-dcdb.svg",
  calendartodaySrc: "/external/calendartodayi523-7ry5.svg",
  noneAlt1: "lens5562",
  selectedAlt: "radiobuttonchecked5562",
  calendartodayAlt: "calendartodayI523",
  keyboardarrowupSrc1: "/external/keyboardarrowup1141-5bxq.svg",
  noneSrc: "/external/lens5562-dcdb.svg",
  noneAlt2: "lens5562",
  noneSrc1: "/external/lens5562-dcdb.svg",
  selectedAlt2: "radiobuttonchecked5562",
  noneAlt: "lens5562",
  selectedSrc2: "/external/radiobuttonchecked5562-hnq.svg",
  selectedSrc1: "/external/radiobuttonchecked5562-hnq.svg",
  keyboardarrowupAlt: "keyboardarrowup1141",
  keyboardarrowupAlt1: "keyboardarrowup1141",
  textinputPlaceholder: "Usuário",
  text2: "Outro",
  textinputPlaceholder1: "Nome",
  selectedAlt1: "radiobuttonchecked5562",
  textinputPlaceholder5: "RG",
  textinputPlaceholder4: "dd/mm/yyyy",
  textinputPlaceholder3: "Telefone",
  keyboardarrowupSrc: "/external/keyboardarrowup1141-5bxq.svg",
};

PerfilFormDadosPessoais.propTypes = {
  text: PropTypes.string,
  textinputPlaceholder6: PropTypes.string,
  textinputPlaceholder2: PropTypes.string,
  text1: PropTypes.string,
  selectedSrc: PropTypes.string,
  noneSrc2: PropTypes.string,
  calendartodaySrc: PropTypes.string,
  noneAlt1: PropTypes.string,
  selectedAlt: PropTypes.string,
  calendartodayAlt: PropTypes.string,
  keyboardarrowupSrc1: PropTypes.string,
  noneSrc: PropTypes.string,
  noneAlt2: PropTypes.string,
  noneSrc1: PropTypes.string,
  selectedAlt2: PropTypes.string,
  noneAlt: PropTypes.string,
  selectedSrc2: PropTypes.string,
  selectedSrc1: PropTypes.string,
  keyboardarrowupAlt: PropTypes.string,
  keyboardarrowupAlt1: PropTypes.string,
  textinputPlaceholder: PropTypes.string,
  text2: PropTypes.string,
  textinputPlaceholder1: PropTypes.string,
  selectedAlt1: PropTypes.string,
  textinputPlaceholder5: PropTypes.string,
  textinputPlaceholder4: PropTypes.string,
  textinputPlaceholder3: PropTypes.string,
  keyboardarrowupSrc: PropTypes.string,
};

export default PerfilFormDadosPessoais;
