import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { MdRefresh, MdInfoOutline, MdNavigateBefore, MdNavigateNext, MdSearch, MdArrowDropDown } from 'react-icons/md';
import { TiBatteryLow } from "react-icons/ti";
import Header from '../components/header';
import styles from './minhas-vendas.module.css';

const MinhasVendas = () => {
  const history = useHistory();
  const [vendas, setVendas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedVenda, setSelectedVenda] = useState(null);
  const [tipoVendaFiltro, setTipoVendaFiltro] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterRef = useRef(null);

  const fetchVendas = useCallback(async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/user/sales`, {
        params: {
          page: currentPage,
          search: debouncedSearchTerm,
          type: tipoVendaFiltro
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      const formattedSales = response.data.sales.map(sale => ({
        id: sale._id,
        data: new Date(sale.saleDate).toLocaleString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }),
        produto: sale.metadata.products.join(', '),
        valor: sale.totalAmount,
        comissao: sale.commissionAmount,
        status: sale.canceled ? 'CANCELADA' : 'APROVADA',
        codigoCupom: sale.metadata.couponCode || '',
        comprador: sale.metadata.customerName,
        pontuacao: sale.pontuacao,
        tipoVenda: sale.couponUsed === 'organic' ? 'Orgânico' : 
                   sale.couponUsed === 'ads' ? 'Ads' : 'Indicação',
        indicador: sale.indicador
      }));

      window.scrollTo(0,0);
      setVendas(formattedSales);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Erro ao buscar vendas:', error);
      setError('Não foi possível carregar as vendas. Por favor, tente novamente.');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  }, [currentPage, debouncedSearchTerm, tipoVendaFiltro]);

  useEffect(() => {
    fetchVendas();
  }, [fetchVendas]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const handleRefresh = () => {
    fetchVendas();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleOpenPopup = (venda) => {
    setSelectedVenda(venda);
  };

  const handleClosePopup = () => {
    setSelectedVenda(null);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={styles.paginationButton}
        >
          <MdNavigateBefore />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`${styles.paginationButton} ${currentPage === number ? styles.active : ''}`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={styles.paginationButton}
        >
          <MdNavigateNext />
        </button>
      </div>
    );
  };

  return (
    <div className={styles.minhasVendas}>
      <Header />
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Vendas</h1>
            <div className={styles.actions}>
              <div className={styles.searchContainer}>
                <MdSearch className={styles.searchIcon} />
                <input
                  type="text"
                  placeholder="Pesquisar produto ou cliente"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="Pesquisar por produto ou cliente"
                  className={styles.searchInput}
                />
              </div>
              <div className={styles.filterContainer} ref={filterRef}>
                <button 
                  className={styles.filterButton} 
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                  aria-expanded={isFilterOpen}
                  aria-haspopup="listbox"
                >
                  {tipoVendaFiltro || 'Todos os tipos'}
                  <MdArrowDropDown className={styles.filterIcon} />
                </button>
                {isFilterOpen && (
                  <ul className={styles.filterDropdown} role="listbox">
                    <li 
                      role="option" 
                      aria-selected={tipoVendaFiltro === ''}
                      onClick={() => {
                        setTipoVendaFiltro('');
                        setIsFilterOpen(false);
                        setCurrentPage(1);
                      }}
                    >
                      Todos os tipos
                    </li>
                    <li 
                      role="option" 
                      aria-selected={tipoVendaFiltro === 'Orgânico'}
                      onClick={() => {
                        setTipoVendaFiltro('Orgânico');
                        setIsFilterOpen(false);
                        setCurrentPage(1);
                      }}
                    >
                      Orgânico
                    </li>
                    <li 
                      role="option" 
                      aria-selected={tipoVendaFiltro === 'Ads'}
                      onClick={() => {
                        setTipoVendaFiltro('Ads');
                        setIsFilterOpen(false);
                        setCurrentPage(1);
                      }}
                    >
                      Ads
                    </li>
                    <li 
                      role="option" 
                      aria-selected={tipoVendaFiltro === 'Indicação'}
                      onClick={() => {
                        setTipoVendaFiltro('Indicação');
                        setIsFilterOpen(false);
                        setCurrentPage(1);
                      }}
                    >
                      Indicação
                    </li>
                  </ul>
                )}
              </div>
              <button
                className={styles.refreshButton}
                onClick={handleRefresh}
                aria-label="Atualizar vendas"
                disabled={isRefreshing}
              >
                <MdRefresh className={styles.refreshIcon} aria-hidden="true" />
                <span className={styles.refreshText}>Atualizar</span>
              </button>
            </div>
        </div>
        <div className={styles.content}>
          {loading ? (
            <div className={styles.loading} aria-live="polite">Carregando vendas...</div>
          ) : error ? (
            <div className={styles.error} aria-live="assertive">{error}</div>
          ) : vendas.length > 0 ? (
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Produtos</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Comissão</th>
                    <th scope="col">Status</th>
                    <th scope="col">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  {vendas.map((venda) => (
                    <tr key={venda.id}>
                      <td>{venda.data}</td>
                      <td>{venda.produto}</td>
                      <td>{formatCurrency(venda.valor)}</td>
                      <td>{formatCurrency(venda.comissao)}</td>
                      <td>
                        <span className={`${styles.status} ${styles[`status${venda.status.toLowerCase()}`]}`}>
                          {venda.status}
                        </span>
                      </td>
                      <td>
                        <button
                          className={styles.infoButton}
                          onClick={() => handleOpenPopup(venda)}
                          aria-label="Ver detalhes da venda"
                        >
                          <MdInfoOutline />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.emptyState}>
              <TiBatteryLow className={styles.emptyStateIcon} />
              <h2 className={styles.emptyStateTitle}>Nenhuma venda encontrada</h2>
              <p className={styles.emptyStateDescription}>
                Parece que você ainda não realizou nenhuma venda. Acesse nosso Academy e utilize as melhores estratégias para vender mais!
              </p>
              <button className={styles.emptyStateButton} onClick={() => {
                history.push('/academy');
              }}>
                Aprender a vender mais e melhor
              </button>
            </div>
          )}
        </div>
        {!loading && !error && vendas && vendas.length > 0 && renderPagination()}
      </div>
      {selectedVenda && (
        <div className={styles.popupOverlay} onClick={handleClosePopup}>
          <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
            <h2>Detalhes da Venda</h2>
            <p><strong>Data:</strong> {selectedVenda.data}</p>
            <p><strong>Produtos:</strong> {selectedVenda.produto}</p>
            <p><strong>Valor:</strong> {formatCurrency(selectedVenda.valor)}</p>
            <p><strong>Comissão:</strong> {formatCurrency(selectedVenda.comissao)}</p>
            <p><strong>Status:</strong> {selectedVenda.status}</p>
            <p><strong>Código do Cupom:</strong> {selectedVenda.codigoCupom || 'N/A'}</p>
            <p><strong>Comprador:</strong> {selectedVenda.comprador}</p>
            <p><strong>Pontuação:</strong> {selectedVenda.pontuacao}</p>
            <p><strong>Tipo de Venda:</strong> {selectedVenda.tipoVenda}</p>
            {selectedVenda.tipoVenda === 'Indicação' && (
              <p><strong>Indicador:</strong> {selectedVenda.indicador || 'N/A'}</p>
            )}
            <button className={styles.popupClose} onClick={handleClosePopup}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MinhasVendas;