import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { MdCheckCircle, MdPlayArrow, MdFileDownload } from 'react-icons/md';
import { motion } from 'framer-motion';
import Header from '../components/header';
import VideoPlayer from '../components/videoplayer';
import styles from './aulaDetail.module.css';

const Lesson = () => {
  const { lessonId } = useParams();
  const history = useHistory();
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLessonData = useCallback(async (id) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const lessonResponse = await axios.get(`${process.env.REACT_APP_DATABASE}/academy/lesson/${id}`, { headers });
      setCourse(lessonResponse.data.data.curso);
      setLessons(lessonResponse.data.data.aulas);
      setCurrentLesson({
        ...lessonResponse.data.data.lesson,
        concluida: lessonResponse.data.data.concluida
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching lesson data:', error);
      setError('Falha ao carregar os dados da aula. Por favor, tente novamente.');
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLessonData(lessonId);
  }, [lessonId, fetchLessonData]);

  const handleLessonClick = useCallback((lesson) => {
    history.push(`/academy/aula/${lesson._id}`);
    fetchLessonData(lesson._id);
  }, [history, fetchLessonData]);

  const handleNextLesson = useCallback(() => {
    const currentIndex = lessons.findIndex((lesson) => lesson._id === currentLesson._id);
    const nextLesson = lessons[currentIndex + 1];
    if (nextLesson) {
      handleLessonClick(nextLesson);
    }
  }, [lessons, currentLesson, handleLessonClick]);

  const handleLessonComplete = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post(`${process.env.REACT_APP_DATABASE}/academy/lesson/${currentLesson._id}/completion`, {}, { headers });
      setCurrentLesson(prevLesson => ({
        ...prevLesson,
        concluida: !prevLesson.concluida
      }));
      setLessons(prevLessons => prevLessons.map(lesson => 
        lesson._id === currentLesson._id ? { ...lesson, concluida: !lesson.concluida } : lesson
      ));
    } catch (error) {
      console.error('Error marking lesson as complete:', error);
      setError('Falha ao marcar a aula como concluída. Por favor, tente novamente.');
    }
  };

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!course || !currentLesson) {
    return null;
  }

  const currentLessonIndex = lessons.findIndex((lesson) => lesson._id === currentLesson._id);
  const isLastLesson = currentLessonIndex === lessons.length - 1;

  return (
    <div className={styles.lessonPage}>
      <Header />
      <div className={styles.lessonContainer}>
        <header className={styles.lessonHeader}>
          <div className={styles.courseInfos}>
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className={styles.courseTitle}
            >
              {course.nome}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className={styles.courseDescription}
            >
              {course.descricao}
            </motion.p>
          </div>
          <div className={styles.courseProgress}>
            <div className={styles.progressBar}>
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width: `${(lessons.filter((l) => l.concluida).length / lessons.length) * 100}%`,
                }}
                transition={{ duration: 0.8 }}
                className={styles.progressFill}
              />
            </div>
            <span className={styles.progressLabel}>
              {lessons.filter((l) => l.concluida).length} de {lessons.length} aulas concluídas
            </span>
          </div>
        </header>
        <div className={styles.lessonContents}>
          <aside className={styles.lessonSidebar}>
            <h2 className={styles.sidebarTitle}>Aulas</h2>
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className={styles.lessonList}
            >
              {lessons.map((lesson, index) => (
                <motion.li
                  key={lesson._id}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleLessonClick(lesson)}
                  className={`${styles.lessonItem} ${lesson.concluida ? styles.completed : ''} ${
                    currentLesson._id === lesson._id ? styles.current : ''
                  }`}
                >
                  <span className={styles.lessonNumber}>{index + 1}</span>
                  <span className={styles.lessonName}>{lesson.nome}</span>
                  {lesson.concluida && <MdCheckCircle size={20} className={styles.completedIcon} />}
                </motion.li>
              ))}
            </motion.ul>
          </aside>
          <main className={styles.lessonMain}>
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className={styles.lessonTitle}
          >
            {currentLesson.nome}
          </motion.h2>
          {currentLesson.link && (
            <VideoPlayer key={currentLesson._id} src={currentLesson.link} />
          )}            
          <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className={styles.lessonDescription}
            >
              {currentLesson.descricao}
            </motion.div>
            {currentLesson.arquivos && currentLesson.arquivos.length > 0 && (
              <div className={styles.lessonFiles}>
                <h3 className={styles.filesTitle}>Arquivos Complementares</h3>
                <ul className={styles.filesList}>
                  {currentLesson.arquivos.map((file, index) => (
                    <motion.li key={index} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                      <a href={file} target="_blank" rel="noopener noreferrer" className={styles.downloadLink}>
                        <MdFileDownload size={20} className={styles.downloadIcon} />
                        <span>Arquivo {index + 1}</span>
                      </a>
                    </motion.li>
                  ))}
                </ul>
              </div>
            )}
          <div className={styles.lessonActions}>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`${styles.actionButton} ${currentLesson.concluida ? styles.uncompleteButton : styles.completeButton}`}
              onClick={handleLessonComplete}
            >
              <MdCheckCircle size={20} />
              <span>{currentLesson.concluida ? 'Desmarcar como Concluída' : 'Marcar como Concluída'}</span>
            </motion.button>
            {!isLastLesson && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`${styles.actionButton} ${styles.nextLessonButton}`}
                onClick={handleNextLesson}
              >
                <MdPlayArrow size={20} />
                <span>Próxima Aula</span>
              </motion.button>
            )}
          </div>
        </main>
      </div>
    </div>
    </div>
  );
};

export default Lesson;
