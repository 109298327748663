import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";

import "./perfil-form-dados-pessoais.css";

const FormCuponsDesconto = (props) => {
    const [open, setOpen] = useState(false);
    const formData = props.formData;
    const [data, setData] = useState({
        couponOrg: formData?.couponOrg,
        couponTP: formData?.couponTP
    })

    const handleInputChange = (e) => {
        // Remove espaços em branco do valor do input
        const newValue = e.target.value.replace(/\s/g, '');
        setData({
           ...data,
            [e.target.name]: newValue
        })
    }

    useEffect(() => {
      setData({
           ...data,
            couponOrg: formData?.couponOrg,
            couponTP: formData?.couponTP
        })
    }, [formData?.couponOrg, formData?.couponTP]);

  return (
    <div className="perfil-form-dados-pessoais-perfil-form-dados-pessoais">
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open)
        }}
        className="perfil-form-dados-pessoais-frame354"
      >
        <span className="perfil-form-dados-pessoais-text HeaderH3-18Medium">
          <span>Seus cupons</span>
        </span>
        {open ? (
          <img
            alt={props.keyboardarrowupAlt1}
            src={props.keyboardarrowupSrc1}
            className="perfil-form-dados-pessoais-keyboardarrowup"
          />
        ) : (
          <img
            alt={props.keyboardarrowupAlt}
            src={props.keyboardarrowupSrc}
            className="perfil-form-dados-pessoais-keyboardarrowdown"
          />
        )}
      </div>
      {open && (
        <Fragment>
          <div className="perfil-form-dados-pessoais-frame308">
            <span className="perfil-form-dados-pessoais-text02 TextMedium-16Medium">
              <span>Cupons de desconto</span>
            </span>
            <div className="perfil-form-dados-pessoais-frame309">
              <div className="perfil-form-dados-pessoais-textfields">
                <span className="perfil-form-dados-pessoais-text04 TextSmall-14Regular">
                  <span>Cupom Orgânico (20% de comissão)</span>
                </span>
                <input
                  type="text"
                  placeholder="Cupom Orgânico"
                  className="input"
                  name="couponOrg"
                  value={data.couponOrg}
                  onChange={handleInputChange}
                />
              </div>
              <div className="perfil-form-dados-pessoais-textfields">
                <span className="perfil-form-dados-pessoais-text04 TextSmall-14Regular">
                  <span>Cupom Tráfego Pago (8% de comissão)</span>
                </span>
                <input
                  type="text"
                  placeholder="Cupom Tráfego Pago"
                  className="input"
                  name="couponTP"
                  value={data.couponTP}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
            <div className="perfil-form-dados-pessoais-frame306">
             
              <button
                className="perfil-form-dados-pessoais-buttons1"
                onClick={() => {
                  props.handleUpdateUser(data)
                }}
              >
                <span className="perfil-form-dados-pessoais-text35 TextMedium-16Medium">
                  <span>Atualizar informações</span>
                </span>
              </button>
            </div>
        </Fragment>
      )}
    </div>
  );
};

FormCuponsDesconto.defaultProps = {
  text: "Masculino",
  textinputPlaceholder6: "CPF",
  textinputPlaceholder2: "E-mail",
  text1: "Feminino",
  selectedSrc: "/external/radiobuttonchecked5562-hnq.svg",
  noneSrc2: "/external/lens5562-dcdb.svg",
  calendartodaySrc: "/external/calendartodayi523-7ry5.svg",
  noneAlt1: "lens5562",
  selectedAlt: "radiobuttonchecked5562",
  calendartodayAlt: "calendartodayI523",
  keyboardarrowupSrc1: "/external/keyboardarrowup1141-5bxq.svg",
  noneSrc: "/external/lens5562-dcdb.svg",
  noneAlt2: "lens5562",
  noneSrc1: "/external/lens5562-dcdb.svg",
  selectedAlt2: "radiobuttonchecked5562",
  noneAlt: "lens5562",
  selectedSrc2: "/external/radiobuttonchecked5562-hnq.svg",
  selectedSrc1: "/external/radiobuttonchecked5562-hnq.svg",
  keyboardarrowupAlt: "keyboardarrowup1141",
  keyboardarrowupAlt1: "keyboardarrowup1141",
  textinputPlaceholder: "Usuário",
  text2: "Outro",
  textinputPlaceholder1: "Nome",
  selectedAlt1: "radiobuttonchecked5562",
  textinputPlaceholder5: "RG",
  textinputPlaceholder4: "dd/mm/yyyy",
  textinputPlaceholder3: "Telefone",
  keyboardarrowupSrc: "/external/keyboardarrowup1141-5bxq.svg",
};

FormCuponsDesconto.propTypes = {
  text: PropTypes.string,
  textinputPlaceholder6: PropTypes.string,
  textinputPlaceholder2: PropTypes.string,
  text1: PropTypes.string,
  selectedSrc: PropTypes.string,
  noneSrc2: PropTypes.string,
  calendartodaySrc: PropTypes.string,
  noneAlt1: PropTypes.string,
  selectedAlt: PropTypes.string,
  calendartodayAlt: PropTypes.string,
  keyboardarrowupSrc1: PropTypes.string,
  noneSrc: PropTypes.string,
  noneAlt2: PropTypes.string,
  noneSrc1: PropTypes.string,
  selectedAlt2: PropTypes.string,
  noneAlt: PropTypes.string,
  selectedSrc2: PropTypes.string,
  selectedSrc1: PropTypes.string,
  keyboardarrowupAlt: PropTypes.string,
  keyboardarrowupAlt1: PropTypes.string,
  textinputPlaceholder: PropTypes.string,
  text2: PropTypes.string,
  textinputPlaceholder1: PropTypes.string,
  selectedAlt1: PropTypes.string,
  textinputPlaceholder5: PropTypes.string,
  textinputPlaceholder4: PropTypes.string,
  textinputPlaceholder3: PropTypes.string,
  keyboardarrowupSrc: PropTypes.string,
};

export default FormCuponsDesconto;
