import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Header from '../components/header';
import './community.css';
import VisualizarImagem from '../components/visualizar-imagem';
import PopupNovoPost from '../components/popup-novo-post';
import PopupExcluirPost from '../components/popup-excluir-post';
import { MdPushPin } from 'react-icons/md';
import { IoMdTrash } from "react-icons/io";
import { FaRegLightbulb } from 'react-icons/fa';
import { useUser } from "../states";
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

const Comunidade = () => {
  const { user } = useUser();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('recent');
  const [searchQuery, setSearchQuery] = useState('');
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState({});
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const observer = useRef();
  const cancelTokenSource = useRef();

  const countDirectComments = (comments) => {
    return comments.filter(comment => comment.parentComment === null).length;
  };
  

  const lastPostElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  const fetchPosts = useCallback(async () => {
    try {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Operation canceled due to new request.");
      }
      cancelTokenSource.current = axios.CancelToken.source();

      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/community/posts`, {
        params: {
          type: activeTab,
          page: currentPage,
          limit: 10,
          search: searchQuery
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        cancelToken: cancelTokenSource.current.token
      });

      setPosts(prevPosts => {
        const newPosts = response.data.posts.filter(newPost => 
          !prevPosts.some(prevPost => prevPost._id === newPost._id)
        );
        const combinedPosts = [...prevPosts, ...newPosts];
        combinedPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        return combinedPosts;
      });

      setHasMore(response.data.hasMore);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching posts:', error);
      }
      setIsLoading(false);
    }
  }, [activeTab, currentPage, searchQuery]);

  const debouncedFetchPosts = useMemo(
    () => debounce(fetchPosts, 300),
    [fetchPosts]
  );

  useEffect(() => {
    setPosts([]);
    setCurrentPage(1);
    setHasMore(true);
    debouncedFetchPosts();

    return () => {
      debouncedFetchPosts.cancel();
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Component unmounted");
      }
    };
  }, [activeTab, searchQuery, debouncedFetchPosts]);

  useEffect(() => {
    if (currentPage > 1) {
      debouncedFetchPosts();
    }
  }, [currentPage, debouncedFetchPosts]);

  const handlePinPost = async (postId) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_DATABASE}/community/post/${postId}/pin`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        if(response.data.isPinned) {
          setActiveTab('fixed');
          toast.success('Post fixado com sucesso!');
        } else {
          setActiveTab('recent');
          toast.success('Post desfixado com sucesso!');
        }
      }
    } catch (error) {
      console.error('Error pinning post:', error);
    }
  };

  const handleLikePost = async (postId) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_DATABASE}/community/post/${postId}/like`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        setPosts(prevPosts => prevPosts.map(post => 
          post._id === postId ? { ...post, likes: response.data.likes } : post
        ));
      }
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const getUserInitials = (fullName) => {
    const names = fullName.split(' ');
    const firstInitial = names[0].charAt(0).toUpperCase();
    const lastInitial = names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const openImageViewer = (images) => {
    setCurrentImages(images);
    setIsImageViewerOpen(true);
  };

  const handleDeletePost = (postId, postTitle) => {
    setCurrentPost({ id: postId, title: postTitle });
    setIsDeletePopupOpen(true);
  };

  const confirmDeletePost = async () => {
    if (currentPost) {
      try {
        await axios.delete(`${process.env.REACT_APP_DATABASE}/community/post/${currentPost.id}/delete`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setPosts(prevPosts => prevPosts.filter(post => post._id !== currentPost.id));
        setIsDeletePopupOpen(false);
        setCurrentPost(null);
        toast.success('Post deletado com sucesso');
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  const highlightText = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <mark key={index}>{part}</mark> : part
    );
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
    setCurrentImages([]);
  };

  const formatPostDate = (date) => {
    const postDate = new Date(date);
    const now = new Date();
    const diffInHours = Math.abs(now - postDate) / 36e5;

    if (diffInHours < 24) {
      return formatDistanceToNow(postDate, { addSuffix: true, locale: ptBR });
    } else if (diffInHours < 48) {
      return `Ontem às ${format(postDate, 'HH:mm')}`;
    } else {
      return format(postDate, "d 'de' MMMM 'às' HH:mm", { locale: ptBR });
    }
  };

  return (
    <div className="comunidade">
      <Header />
      <div className="comunidade__head">
        <div className="comunidade__column">
          <div className="comunidade__row">
            <div className="comunidade__search-bar">
              <img
                alt="search"
                src="/external/searchi523-8nw.svg"
                className="comunidade__search-icon"
              />
              <input
                type="text"
                placeholder="Pesquise por posts ou comentários"
                className="comunidade__input"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="comunidade__filters">
            <div className={`comunidade__filter ${activeTab === 'recent' ? 'active' : ''}`} onClick={() => setActiveTab('recent')}>
              <svg viewBox="0 0 1024 1024" className="comunidade__filter-icon">
                <path d="M692 692l34-56-192-116v-222h-64v256zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
              </svg>
              <span className="comunidade__filter-text">Recentes</span>
            </div>
            <div className={`comunidade__filter ${activeTab === 'fixed' ? 'active' : ''}`} onClick={() => setActiveTab('fixed')}>
              <svg viewBox="0 0 1024 1024" className="comunidade__filter-icon">
                <path d="M704 256h64v490q0 98-68 167t-166 69-167-69-69-167v-532q0-70 51-121t121-51 120 51 50 121v448q0 44-31 75t-75 31-76-31-32-75v-406h64v406q0 18 13 30t31 12 30-12 12-30v-448q0-44-31-76t-75-32-76 32-32 76v532q0 70 51 121t121 51 120-51 50-121v-490z"></path>
              </svg>
              <span className="comunidade__filter-text">Fixos</span>
            </div>
            <div className={`comunidade__filter ${activeTab === 'trending' ? 'active' : ''}`} onClick={() => setActiveTab('trending')}>
              <svg viewBox="0 0 1024 1024" className="comunidade__filter-icon">
                <path d="M682 256h256v256l-98-98-268 268-170-170-256 256-60-60 316-316 170 170 208-208z"></path>
              </svg>
              <span className="comunidade__filter-text">Em alta</span>
            </div>
          </div>
        </div>
      </div>
      <div className="comunidade__posts">
      {posts.length > 0 ? (
        posts.map((post, index) => (
          <div key={post._id} className="comunidade__card" ref={index === posts.length - 1 ? lastPostElementRef : null}>
            <div className="comunidade__card-header">
              <div className="comunidade__card-author">
                {post.author.profilePicture ? (
                  <img
                    alt="author"
                    src={post.author.profilePicture}
                    className="comunidade__author-image"
                  />
                ) : (
                  <div className="comunidade__author-initials">
                    {getUserInitials(post.author.name)}
                  </div>
                )}
                <div className="comunidade__author-info">
                  <span className="comunidade__author-name">{post.author.name}</span>
                  {post.author.type === 'admin' && (
                    <span className="comunidade__author-admin-badge">Admin</span>
                  )}
                  <span className="comunidade__post-date">{formatPostDate(post.createdAt)}</span>
                </div>
              </div>
              <div className="controls">
                {user?.type === 'admin' && (
                  <MdPushPin
                    className={`comunidade__pin-icon ${post.isPinned ? 'pinned' : ''}`}
                    onClick={() => handlePinPost(post._id)}
                  />
                )}
                {(user?.type === 'admin' || post.author._id === user._id) && (
                  <IoMdTrash 
                    className="comunidade__delete-icon"
                    onClick={() => handleDeletePost(post._id, post.title)}
                  />
                )}
              </div>
            </div>
            <div className="comunidade__card-body">
              <span className="comunidade__post-title">
                {searchQuery ? highlightText(post.title, searchQuery) : post.title}
              </span>
              <span className="comunidade__post-content">          
                {searchQuery ? highlightText(post.content, searchQuery) : post.content}
              </span>
              {post.tags && post.tags.length > 0 && (
                <span className="comunidade__post-tags">{
                  post.tags.map(tag => (
                    searchQuery && tag.includes(searchQuery) 
                      ? <mark key={tag}>{'#' + tag + ' '}</mark> 
                      : <span key={tag}>{'#' + tag + ' '}</span>
                  ))     
                }</span>
              )}
              <div className="comunidade__post-images">
                {post.images.map((image, index) => (
                  index <= 2 && (
                    <div key={index} className="comunidade__post-image-container" onClick={() => openImageViewer(post.images)}>
                      <img
                        alt="post"
                        src={image}
                        className="comunidade__post-image"
                      />
                      {index === 2 && post.images.length > 3 && (
                        <div className="comunidade__view-more">
                          <span className="comunidade__view-more-text">{post.images.length - 3}+</span>
                        </div>
                      )}
                    </div>
                  )
                ))}
              </div>
              {searchQuery && post.comentariosCorrespondentes && post.comentariosCorrespondentes.length > 0 && (
                <div className="comunidade__matching-comments">
                  <h4>Comentários correspondentes:</h4>
                  {post.comentariosCorrespondentes.map(comment => (
                    <div 
                      key={comment._id} 
                      className="comunidade__comment-preview"
                      onClick={() => history.push(`/post/${post._id}`)}
                    >
                      <span className="comunidade__comment-author">{comment.authorName}</span>
                      <p className="comunidade__comment-content">
                        {highlightText(comment.content, searchQuery)}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="comunidade__card-footer">
              <div className="comunidade__likes" onClick={() => handleLikePost(post._id)}>
                <svg viewBox="0 0 914.2857142857142 1024" className={`comunidade__like-icon ${post.likes.includes(user._id) ? 'liked' : ''}`}>
                  <path d="M146.286 768c0-20-16.571-36.571-36.571-36.571-20.571 0-36.571 16.571-36.571 36.571 0 20.571 16 36.571 36.571 36.571 20 0 36.571-16 36.571-36.571zM237.714 475.429v365.714c0 20-16.571 36.571-36.571 36.571h-164.571c-20 0-36.571-16.571-36.571-36.571v-365.714c0-20 16.571-36.571 36.571-36.571h164.571c20 0 36.571 16.571 36.571 36.571zM914.286 475.429c0 30.286-12 62.857-31.429 85.143 6.286 18.286 8.571 35.429 8.571 43.429 1.143 28.571-7.429 55.429-24.571 78.286 6.286 21.143 6.286 44 0 66.857-5.714 21.143-16.571 40-30.857 53.714 3.429 42.857-6.286 77.714-28 103.429-24.571 29.143-62.286 44-112.571 44.571h-73.714c-81.714 0-158.857-26.857-220.571-48-36-12.571-70.286-24.571-90.286-25.143-19.429-0.571-36.571-16.571-36.571-36.571v-366.286c0-18.857 16-34.857 34.857-36.571 21.143-1.714 76-69.714 101.143-102.857 20.571-26.286 40-50.857 57.714-68.571 22.286-22.286 28.571-56.571 35.429-89.714 6.286-33.714 13.143-69.143 37.714-93.143 6.857-6.857 16-10.857 25.714-10.857 128 0 128 102.286 128 146.286 0 46.857-16.571 80-32 109.714-6.286 12.571-12 18.286-16.571 36.571h158.286c59.429 0 109.714 50.286 109.714 109.714z"></path>
                </svg>
                <span className="comunidade__like-count">{post.likes.length}</span>
              </div>
              <div className="comunidade__comments" onClick={() => history.push(`/post/${post._id}`)}>
                <svg viewBox="0 0 1024 1024" className="comunidade__comment-icon">
                  <path d="M938 170v768l-170-170h-598q-34 0-59-26t-25-60v-512q0-34 25-59t59-25h684q34 0 59 25t25 59z"></path>
                </svg>
                <span className="comunidade__comment-count">{countDirectComments(post.comments)}</span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="comunidade__empty-state">
          <FaRegLightbulb className="comunidade__empty-state-icon" />
          <h2 className="comunidade__empty-state-title">Nenhum post encontrado</h2>
          <p className="comunidade__empty-state-description">
            Seja o primeiro a compartilhar suas ideias com a comunidade de influenciadores!
          </p>
          <button className="comunidade__empty-state-button" onClick={() => setIsPopupOpen(true)}>
            Criar novo post
          </button>
        </div>
      )}
      </div>
      {isLoading && (
        <div className="comunidade__loading">
          <div className="comunidade__loading-spinner"></div>
          <span>Carregando mais posts...</span>
        </div>
      )}
      {isImageViewerOpen && <VisualizarImagem images={currentImages} onClose={closeImageViewer} />}
      {isPopupOpen && <PopupNovoPost type="post" onClose={() => setIsPopupOpen(false)} onAddPost={fetchPosts} />}
      {isDeletePopupOpen && (
        <PopupExcluirPost
          postTitle={currentPost?.title}
          onClose={() => setIsDeletePopupOpen(false)}
          onConfirm={confirmDeletePost}
        />
      )}
      <div className="comunidade__add-post" onClick={() => setIsPopupOpen(true)}>
        <svg viewBox="0 0 1024 1024" className="comunidade__add-post-icon">
          <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
        </svg>
      </div>
    </div>
  );
};

export default Comunidade;
