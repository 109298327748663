import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/header';
import TabContainer from './TabContainer';
import SearchBar from './SearchBar';
import UserList from './UserList';
import Pagination from './Pagination';
import EmptyState from './EmptyState';
import { fetchUsersAndMetrics } from './api';
import { useAction } from '../../states';
import styles from './Usuarios.module.css';

const Usuarios = () => {
  const [activeTab, setActiveTab] = useState('todos');
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [userMetrics, setUserMetrics] = useState({});
  const { undoLastAction } = useAction();
  const usersPerPage = 18;

  const fetchUsers = useCallback(() => {
    fetchUsersAndMetrics(activeTab, currentPage, searchTerm, usersPerPage)
      .then(({ users, totalPages, metrics }) => {
        setUsers(users);
        setTotalPages(totalPages);
        setUserMetrics(metrics);
      })
      .catch(error => console.error('Erro ao buscar usuários e métricas:', error));
  }, [activeTab, currentPage, searchTerm]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.ctrlKey && event.key === 'z') {
        event.preventDefault();
        const success = await undoLastAction(activeTab);
        if (success) {
          fetchUsers();
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [activeTab, undoLastAction, fetchUsers]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    setSearchTerm('');
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleUpdate = useCallback(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <div className={styles.usuariosMain}>
      <Header />
      <div className={styles.usuariosContainer}>
        <h1 className={styles.usuariosTitle}>Influenciadores</h1>
        <TabContainer
          activeTab={activeTab}
          onTabChange={handleTabChange}
          userMetrics={userMetrics}
        />
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
        {users.length > 0 ? (
          <>
            <UserList 
              users={users} 
              activeTab={activeTab} 
              onUpdate={handleUpdate}
            />
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={paginate}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default Usuarios;