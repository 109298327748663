import React from 'react';
import { FaUserFriends } from 'react-icons/fa';
import styles from './EmptyState.module.css';

const EmptyState = () => (
  <div className={styles.emptyState}>
    <FaUserFriends className={styles.emptyStateIcon} />
    <h2>Nenhum usuário encontrado</h2>
    <p>Tente ajustar seus filtros ou pesquisa para encontrar usuários.</p>
  </div>
);

export default EmptyState;