import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Header from '../components/header';
import { MdPerson, MdRefresh, MdContentCopy } from 'react-icons/md';
import styles from './indicacoes.module.css';
import { toast } from 'react-toastify';
import { useUser } from '../states';

const Indicacoes = () => {
  const [indications, setIndications] = useState([]);
  const [period, setPeriod] = useState('1');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [showReferralTooltip, setShowReferralTooltip] = useState(false);
  const { user } = useUser();

  const fetchIndications = useCallback(async () => {
    setLoading(true);
    setError(null);
    setIsRefreshing(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/user/indications`, {
        params: { period },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const sortedIndications = response.data.data.sort((a, b) => {
        if (a.rank === "N/A" && b.rank === "N/A") return 0;
        if (a.rank === "N/A") return 1;
        if (b.rank === "N/A") return -1;
        return a.rank - b.rank;
      });

      setIndications(sortedIndications);
      sessionStorage.setItem(`indications_${period}`, JSON.stringify({
        data: sortedIndications,
        timestamp: Date.now(),
      }));
    } catch (error) {
      console.error('Erro ao buscar indicações:', error);
      setError('Não foi possível carregar as indicações. Por favor, tente novamente.');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  }, [period]);

  const loadIndications = useCallback(() => {
    const cachedData = sessionStorage.getItem(`indications_${period}`);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const currentTime = Date.now();
      if (currentTime - timestamp < 5 * 60 * 1000) {
        setIndications(data);
        setLoading(false);
        return;
      }
    }
    fetchIndications();
  }, [period, fetchIndications]);

  useEffect(() => {
    loadIndications();
    if (user) {
      const baseUrl = window.location.origin;
      setReferralLink(`${baseUrl}/cadastro/${user._id}`);
    }
  }, [loadIndications, user]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Nunca';
    const date = new Date(dateString);
    return date.toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleRefresh = () => {
    loadIndications();
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const copyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success('Link de indicação copiado para a área de transferência!');
    setShowReferralTooltip(true);
    setTimeout(() => setShowReferralTooltip(false), 3000);
  };

  return (
    <div className={styles.indicacoes}>
      <Header />
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Indicações</h1>
          <div className={styles.actions}>
            <div className={styles.periodSelector}>
              <select value={period} onChange={handlePeriodChange} aria-label="Selecione o período">
                <option value="1">Mês atual</option>
                <option value="2">Último mês</option>
                <option value="3">Últimos 3 meses</option>
              </select>
            </div>
            <button
              className={`${styles.actionButton} ${styles.refreshButton}`}
              onClick={handleRefresh}
              aria-label="Atualizar indicações"
              disabled={isRefreshing}
            >
              <MdRefresh className={styles.icon} aria-hidden="true" />
              <span>Atualizar</span>
            </button>
            <div className={styles.referralContainer}>
              <button
                className={`${styles.actionButton} ${styles.copyButton}`}
                onClick={copyReferralLink}
                onMouseEnter={() => setShowReferralTooltip(true)}
                onMouseLeave={() => setShowReferralTooltip(false)}
              >
                <MdContentCopy className={styles.icon} aria-hidden="true" />
                <span>Copiar link</span>
              </button>
              {showReferralTooltip && (
                <div className={styles.referralTooltip}>
                  <p>Clique para copiar seu link de indicação e convidar alguém para vender produtos Bodydry!</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {loading ? (
            <div className={styles.loading} aria-live="polite">Carregando indicações...</div>
          ) : error ? (
            <div className={styles.error} aria-live="assertive">{error}</div>
          ) : indications.length === 0 ? (
            <EmptyState
              title="Nenhuma indicação encontrada"
              description="Comece a indicar pessoas usando seu link de indicação e acompanhe seus resultados aqui!"
              buttonText="Copiar Link de Indicação"
              onClick={copyReferralLink}
            />
          ) : (
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Rank</th>
                    <th scope="col">Faturamento total</th>
                    <th scope="col">Sua comissão</th>
                    <th scope="col">Status</th>
                    <th scope="col">Último login</th>
                  </tr>
                </thead>
                <tbody>
                  {indications.map((indication) => (
                    <tr key={indication._id}>
                      <td data-label="Nome">
                        <div className={styles.user}>
                          {indication.profilePicture ? (
                            <img src={indication.profilePicture} alt="" className={styles.userImage} />
                          ) : (
                            <div className={styles.userInitials} aria-hidden="true">
                              {indication.initials}
                            </div>
                          )}
                          <span className={styles.userName}>{indication.name}</span>
                        </div>
                      </td>
                      <td data-label="Rank">
                        <div className={styles.rank}>
                          <span>{indication.rank ? `${String(indication.rank).padStart(2, '0')}${indication.rank !== 'N/A' ? '°' : ''}` : 'N/A'}</span>
                        </div>
                      </td>
                      <td data-label="Faturamento total">{formatCurrency(indication.faturamentoTotal)}</td>
                      <td data-label="Sua comissão">{formatCurrency(indication.suaComissao)}</td>
                      <td data-label="Status">
                        <span className={`${styles.status} ${styles[`status${indication.status.toLowerCase()}`]}`}>
                          {indication.status}
                        </span>
                      </td>
                      <td data-label="Último login">{formatDate(indication.ultimoLogin)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmptyState = ({ title, description, buttonText, onClick }) => (
  <div className={styles.emptyState}>
    <div className={styles.emptyStateIcon}>
      <MdPerson />
    </div>
    <h3 className={styles.emptyStateTitle}>{title}</h3>
    <p className={styles.emptyStateDescription}>{description}</p>
    <button className={styles.emptyStateButton} onClick={onClick}>{buttonText}</button>
  </div>
);

export default Indicacoes;