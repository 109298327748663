import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import './visualizar-imagem.css';

const VisualizarImagem = ({ images, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowLeft') {
        setPreviousImage();
      } else if (event.key === 'ArrowRight') {
        setNextImage();
      } else if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentImageIndex, onClose]);

  const setPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const setNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className="image-viewer-overlay" onClick={onClose}>
      <div className="image-viewer" onClick={(e) => e.stopPropagation()}>
        <button
          className={`image-viewer__arrow ${currentImageIndex === 0 ? 'image-viewer__arrow--disabled' : ''}`}
          onClick={(e) => { e.stopPropagation(); setPreviousImage(); }}
          disabled={currentImageIndex === 0}
        >
          <svg viewBox="0 0 1024 1024" className="image-viewer__icon">
            <path d="M658 316l-196 196 196 196-60 60-256-256 256-256z"></path>
          </svg>
        </button>
        <div className="image-viewer__image-container">
          <img src={images[currentImageIndex]} alt="Visualizar Imagem" className="image-viewer__image" />
        </div>
        <button
          className={`image-viewer__arrow ${currentImageIndex === images.length - 1 ? 'image-viewer__arrow--disabled' : ''}`}
          onClick={(e) => { e.stopPropagation(); setNextImage(); }}
          disabled={currentImageIndex === images.length - 1}
        >
          <svg viewBox="0 0 1024 1024" className="image-viewer__icon">
            <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
          </svg>
        </button>
        <button className="image-viewer__close" onClick={onClose}>
          <MdClose className="image-viewer__close-icon" />
        </button>
      </div>
    </div>
  );
};

export default VisualizarImagem;
