import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './avaliar-app.css';
import Header from '../components/header';
import axios from 'axios';
import { toast } from 'react-toastify';

const AvaliarApp = () => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const history = useHistory();

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DATABASE}/user/review`, {
        rating,
        comment
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        history.push('/');
        toast.success('Agradecemos pelo seu feedback. Você ganhou 5 pontos!');
        setRating(null);
        setComment('');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Algo deu errado, tente novamente mais tarde.');
      console.error('Error while submitting review:', error);
    }
  };

  const ratingOptions = [
    { id: 1, label: 'Muito Ruim', emoji: '/emoji-muitoruim-200h.png' },
    { id: 2, label: 'Ruim', emoji: '/emoji-ruim-200h.png' },
    { id: 3, label: 'Médio', emoji: '/emoji-medio-200h.png' },
    { id: 4, label: 'Bom', emoji: '/emoji-bom-200h.png' },
    { id: 5, label: 'Muito Bom', emoji: '/emoji-muitobom-200h.png' },
  ];

  return (
    <div className="avaliar-app">
      <Header />
      <main className="avaliar-app__content">
        <div className="avaliar-app__column">
          <h2 className="avaliar-app__title">Avalie nosso App</h2>
          <p className="avaliar-app__subtitle">
            Deixe aqui seu comentário sincero sobre sua experiência para que possamos melhorar cada vez mais
          </p>
        </div>
        <div className="avaliar-app__card">
          <div className="avaliar-app__rating-options">
            {ratingOptions.map((option) => (
              <img
                key={option.id}
                alt={option.label}
                src={option.emoji}
                className={`avaliar-app__rating-emoji ${rating === option.id ? 'avaliar-app__rating-emoji--selected' : ''}`}
                onClick={() => handleRatingClick(option.id)}
              />
            ))}
          </div>
          <div className="avaliar-app__input-container">
            <textarea
              rows="5"
              placeholder="Adicione um comentário"
              className="avaliar-app__comment-textarea"
              value={comment}
              onChange={handleCommentChange}
            ></textarea>
            <button className="avaliar-app__submit-button" onClick={handleSubmit}>
              <span>Enviar</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AvaliarApp;
