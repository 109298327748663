import React, { useState, useRef, useEffect } from 'react';
import { MdDateRange, MdArrowBack, MdArrowForward } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import HomeRanking from '../components/home-ranking';
import HomeEstatisticas from '../components/home-estatisticas';
import './home.css';
import { useUser } from '../states';

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const Home = (props) => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const dropdownRef = useRef(null);

  const handleMonthSelect = (index, year) => {
    setSelectedDate(new Date(year, index, 1));
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePrevMonth = () => {
    setSelectedDate((prevDate) => {
      const earliestDate = new Date(new Date().getFullYear(), new Date().getMonth() - 11, 1);
      if (prevDate > earliestDate) {
        return new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
      }
      return prevDate;
    });
  };

  const handleNextMonth = () => {
    setSelectedDate((prevDate) => {
      const latestDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      if (prevDate < latestDate) {
        return new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
      }
      return prevDate;
    });
  };

  const getMonthOptions = () => {
    const options = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      options.push(date);
    }
    return options;
  };

  const monthOptions = getMonthOptions();
  const isPrevDisabled = selectedDate.getMonth() === monthOptions[11].getMonth() && selectedDate.getFullYear() === monthOptions[11].getFullYear();
  const isNextDisabled = selectedDate.getMonth() === monthOptions[0].getMonth() && selectedDate.getFullYear() === monthOptions[0].getFullYear();

  return (
    <div className="home-container">
      <Helmet>
        <title>Body Dry</title>
        <meta property="og:title" content="Body Dry" />
      </Helmet>
      {user && (
        <div className="home-painel-home">
          <Header />
          <div className="home-frame319">
            <span className="home-text HeaderH1-24Medium">
              <span>Home</span>
            </span>
            <div className="home-frame321">
              <HomeRanking rootClassName="home-ranking-root-class-name" />
              <div style={{ flex: 1, gap: '24px', display: 'flex', flexDirection: 'column' }}>
                <div className="month-dropdown" ref={dropdownRef}>
                  <div className="dropdown-header" role="button" aria-expanded={isOpen}>
                    <MdArrowBack
                      size={16}
                      className={`nav-icon ${isPrevDisabled ? 'disabled' : ''}`}
                      onClick={handlePrevMonth}
                      disabled={isPrevDisabled}
                    />
                    <div className="icon-label" onClick={() => setIsOpen(!isOpen)}>
                      <MdDateRange size={16} />
                      <span>
                        {selectedDate ? `${meses[selectedDate.getMonth()]} de ${selectedDate.getFullYear()}` : 'Selecione um mês'}
                      </span>
                    </div>
                    <MdArrowForward
                      size={16}
                      className={`nav-icon ${isNextDisabled ? 'disabled' : ''}`}
                      onClick={handleNextMonth}
                      disabled={isNextDisabled}
                    />
                  </div>
                  {isOpen && (
                    <div className="dropdown-grid" role="listbox">
                      {monthOptions.reverse().map((date) => (
                        <div
                          key={date}
                          onClick={() => handleMonthSelect(date.getMonth(), date.getFullYear())}
                          role="option"
                          className="dropdown-grid-item"
                          style={ `${meses[date.getMonth()]} de ${date.getFullYear()}` ===  `${meses[selectedDate.getMonth()]} de ${selectedDate.getFullYear()}` ? { fontWeight: '600', background: 'rgba(44, 76, 24, 1)', color: 'white' } : {} }
                        >
                          {meses[date.getMonth()]} de {date.getFullYear()}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <HomeEstatisticas selectedDate={selectedDate} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
