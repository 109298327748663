import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useHistory } from 'react-router-dom';

import Header from "../components/header";
import PerfilFormDadosPessoais from "../components/perfil-form-dados-pessoais";
import PerfilFormDadosBancarios from "../components/perfil-form-dados-bancarios";
import { toast } from "react-toastify";
import PageResetarSenhaTrocarSenhaToken from '../components/page-resetar-senha-trocar-senha-token'; 
import PopupExcluirConta from '../components/excluir-conta';
import { useUser } from "../states";
import FormCuponsDesconto from "../components/form-cupom";
import "./perfil.css";

const Perfil = (props) => {
  const history = useHistory();
  const { user, setUser } = useUser();
  const [isChangePasswordPopupOpen, setIsChangePasswordPopupOpen] = useState(false);
  const [isRemoveAccountPopupOpen, setIsRemoveAccountPopupOpen] = useState(false);

  const handleOpenChangePasswordPopup = () => {
    setIsChangePasswordPopupOpen(true);
  };

  const handleOpenRemoveAccountPopup = () => {
    setIsRemoveAccountPopupOpen(true);
  };

  const handleCloseChangePasswordPopup = () => {
    setIsChangePasswordPopupOpen(false);
  };

  const handleCloseRemoveAccountPopup = () => {
    setIsRemoveAccountPopupOpen(false);
  };
  
  const handleChangePassword = async (password, confirmPassword) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_DATABASE}/user/change-password`, 
        { newPassword: password, confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success('Senha alterada com sucesso!');
      handleCloseChangePasswordPopup();
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Erro ao alterar a senha!");
    }
  };

  const handleUpdateUser = async (updateData) => {
    try {
      const formData = new FormData();
      
      Object.keys(updateData).forEach(key => {
        if (key === 'profilePicture' && updateData[key] instanceof File) {
          formData.append('profilePicture', updateData[key]);
        } else if (updateData[key] === null) {
          formData.append(key, ''); 
        } else if (typeof updateData[key] === 'object' && updateData[key] !== null) {
          formData.append(key, JSON.stringify(updateData[key]));
        } else {
          formData.append(key, updateData[key] === undefined ? '' : updateData[key]);
        }
      });
  
      const response = await axios.put(
        `${process.env.REACT_APP_DATABASE}/user/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );
      toast.success('Perfil atualizado com sucesso!');
      setUser(response.data.user);
    } catch (error) {
      if (error.response?.data?.errors && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0].msg);
      } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Erro ao atualizar o perfil.");
      }
      console.error(error);
    }
  };
  

  const handleRemoveAccount = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_DATABASE}/user/delete-account`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );
      localStorage.removeItem("token");
      history.push("/")
      handleCloseRemoveAccountPopup();
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Erro ao excluir conta!");
    }
  };

  const updateFormData = (newData) => {
    setUser((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <div className="perfil-container">
      <Helmet>
        <title>Perfil - Body Dry</title>
        <meta property="og:title" content="Perfil - Body Dry" />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      {isChangePasswordPopupOpen && (
      <PageResetarSenhaTrocarSenhaToken
        isPopup={true}
        onClose={handleCloseChangePasswordPopup}
        onResetPassword={handleChangePassword}
      />
      )}
      {isRemoveAccountPopupOpen && (
        <PopupExcluirConta
        onRemoveAccount={handleRemoveAccount}
        onClose={handleCloseRemoveAccountPopup}
        />
      )}
      <div className="perfil-painel-meuperfil">
        <div className="perfil-frame319">
          <span className="perfil-text HeaderH1-24Medium">
            <span>Meu perfil</span>
          </span>
          <div className="perfil-frame321">
            {user?.indicatorId && (
              <div className="perfil-frame326">
              <div className="perfil-frame355">
                <span className="perfil-text2 HeaderH3-18Medium">
                  <span>Indicado por</span>
                </span>
              </div>
              <div className="perfil-frame356">
                <div className="perfil-frame303">
                  <img
                    alt="person1141"
                    src="/external/person1141-buil.svg"
                    className="perfil-person"
                  />
                </div>
                <div className="perfil-frame357">
                  <span className="perfil-text4 TextMedium-16Regular">
                    <span>{user?.indicator?.name}</span>
                  </span>
                  <span className="perfil-text6 TextSmall-14Regular">
                    <span>{user?.indicator?.email}</span>
                  </span>
                </div>
              </div>
            </div>
            )}
            <PerfilFormDadosPessoais
              formData={user}
              updateFormData={updateFormData}
              handleOpenChangePasswordPopup={handleOpenChangePasswordPopup}
              handleOpenRemoveAccountPopup={handleOpenRemoveAccountPopup}
              handleUpdateUser={handleUpdateUser}
            ></PerfilFormDadosPessoais>
            <PerfilFormDadosBancarios
              formData={user}
              updateFormData={updateFormData}
              handleOpenRemoveAccountPopup={handleOpenRemoveAccountPopup}
              handleUpdateUser={handleUpdateUser}
            ></PerfilFormDadosBancarios>
            <FormCuponsDesconto
              formData={user}
              updateFormData={updateFormData}
              handleOpenRemoveAccountPopup={handleOpenRemoveAccountPopup}
              handleUpdateUser={handleUpdateUser}
            ></FormCuponsDesconto>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
