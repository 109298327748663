import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './popup-novo-post.css';

const PopupNovoPost = ({ type, onClose, onAddPost }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsVisible(false);
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleAddTag = () => {
    if (newTag.trim() !== '') {
      setTags([...tags, newTag.trim()]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleRemoveImage = (imageToRemove) => {
    setImages(images.filter((image) => image !== imageToRemove));
  };

  const handlePublish = async () => {
    try {
      const newPost = {
        title,
        content: message,
        tags: tags.join(','),
      };

      const formData = new FormData();
      Object.keys(newPost).forEach(key => {
        formData.append(key, newPost[key]);
      });
      images.forEach((image, index) => formData.append(`images`, image));

      const response = await axios.post(`${process.env.REACT_APP_DATABASE}/community/post/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      onAddPost(response.data.post);
      toast.success('Post publicado com sucesso!')
      setIsVisible(false);
      onClose();
    } catch (error) {
      console.error('Error publishing post:', error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const validateFiles = (files) => {
    let validImages = [];
    let hasVideo = false;
    for (const file of files) {
      if (file.type.startsWith('image/') && file.size <= 3 * 1024 * 1024) {
        validImages.push(file);
      } else if (file.type.startsWith('video/') && !hasVideo) {
        hasVideo = true;
        validImages.push(file);
      } else {
        setErrorMessage('Arquivo inválido! Imagens devem ter no máximo 3MB e apenas 1 vídeo é permitido.');
        return [];
      }
    }
    return validImages;
  };

  const handleFileChange = (event) => {
    setErrorMessage('');
    const selectedFiles = Array.from(event.target.files);
    const newImages = validateFiles(selectedFiles);
    if (newImages.length > 0) {
      setImages([...images, ...newImages]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setErrorMessage('');
    const files = Array.from(event.dataTransfer.files);
    const newImages = validateFiles(files);
    if (newImages.length > 0) {
      setImages([...images, ...newImages]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    isVisible && (
      <div className="popup-container">
        <div className="popupnp-content" ref={popupRef}>
          <h2 className="popupnp-title">{type === 'post' ? 'Novo Post' : 'Nova Pergunta'}</h2>
          <div className="popup-inputs">
            <div className="popup-input-group">
              <label htmlFor="title" className="popup-label">
                Título
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Digite o título"
                className="popup-input"
              />
            </div>
            <div className="popup-input-group">
              <label htmlFor="message" className="popup-label">
                Mensagem
              </label>
              <textarea
                resize="none"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Digite a mensagem"
                className="popup-textarea"
              ></textarea>
            </div>
            <div className="popup-input-group">
              <label className="popup-label">Tags</label>
              <div className="popup-tags-input">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="Digite uma tag"
                  className="popup-input"
                />
                <button className="popup-add-tag-button" onClick={handleAddTag}>
                  <svg viewBox="0 0 24 24" className="popup-add-tag-icon">
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </button>
              </div>
              <div className="popup-tags-list">
                {tags.map((tag, index) => (
                  <div key={index} className="popup-tag">
                    <span className="popup-tag-text">{tag}</span>
                    <button
                      className="popup-remove-tag-button"
                      onClick={() => handleRemoveTag(tag)}
                    >
                      <svg viewBox="0 0 24 24" className="popup-remove-tag-icon">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="popup-input-group">
              <label className="popup-label">Arquivos</label>
              <div
                className="popup-upload-area"
                onClick={handleUploadClick}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <svg viewBox="0 0 24 24" className="popup-upload-icon">
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                </svg>
                <span className="popup-upload-text">Clique para carregar ou arraste e solte</span>
                <span className="popup-upload-limit">3mb cada imagem 1 vídeo por post</span>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*,video/*"
                multiple
              />
              {errorMessage && <div className="popup-error-message">{errorMessage}</div>}
              <div className="popup-images-list">
                {images.map((image, index) => (
                  <div key={index} className="popup-image">
                    <img src={URL.createObjectURL(image)} alt={`Imagem ${index + 1}`} className="popup-image-preview" />
                    <button
                      className="popup-remove-image-button"
                      onClick={() => handleRemoveImage(image)}
                    >
                      <svg viewBox="0 0 24 24" className="popup-remove-image-icon">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="popupnp-buttons">
            <button className="popup-button popup-cancel-button" onClick={() => { setIsVisible(false); onClose(); }}>
              Cancelar
            </button>
            <button className="popup-button popup-publish-button" onClick={handlePublish}>
              Publicar
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PopupNovoPost;