import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const UserContext = createContext();
const ActionContext = createContext();

export const useUser = () => {
  const user = useContext(UserContext);

  return { ...user };
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const logOut = () => {
    localStorage.removeItem("token");
    window.location.replace("/login");
  };

  return (
    <UserContext.Provider value={{ user, setUser, logOut }}>
      {children}
    </UserContext.Provider>
  );
};

export const useAction = () => useContext(ActionContext);

export const ActionProvider = ({ children }) => {
  const [actions, setActions] = useState({});

  const addAction = (tab, action) => {
    setActions(prev => ({ ...prev, [tab]: [...(prev[tab] || []), action] }));
    console.log(tab,action);
    console.log("blabla", actions);
  };

  const undoLastAction = async (tab) => {
    if (actions[tab] && actions[tab].length > 0) {
      const lastAction = actions[tab][actions[tab].length - 1];
      try {
        await axios.post(`${process.env.REACT_APP_DATABASE}/admin/backup/${lastAction.actionLogId}`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setActions(prev => ({
          ...prev,
          [tab]: prev[tab].slice(0, -1)
        }));
        return true;
      } catch (error) {
        console.error('Error undoing action:', error);
        return false;
      }
    }
    return false;
  };

  return (
    <ActionContext.Provider value={{ actions, addAction, undoLastAction }}>
      {children}
    </ActionContext.Provider>
  );
};


