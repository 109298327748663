import React, { useRef, useState, useEffect } from 'react';
import { MdPlayArrow, MdPause, MdVolumeUp, MdVolumeOff, MdFullscreen, MdFullscreenExit, MdForward10, MdReplay10 } from 'react-icons/md';
import styles from './videoplayer.module.css';

const VideoPlayer = ({ src }) => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const progressBarRef = useRef(null);
  const volumeBarRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [showControls, setShowControls] = useState(false);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    let newPlayer;

    const initializePlayer = () => {
      if (window.YT && window.YT.Player) {
        newPlayer = new window.YT.Player(playerRef.current, {
          height: '100%',
          width: '100%',
          videoId: getYouTubeId(src),
          playerVars: {
            controls: 0,
            disablekb: 1,
            enablejsapi: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            fs: 0,
            playsinline: 1,
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
        setPlayer(newPlayer);
      }
    };

    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = initializePlayer;
    } else {
      initializePlayer();
    }

    return () => {
      if (newPlayer) {
        newPlayer.destroy();
      }
    };
  }, [src]);

                    const getYouTubeId = (url) => {
                      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
                      const match = url.match(regExp);
                      return (match && match[2].length === 11) ? match[2] : null;
                    };

                    const onPlayerReady = (event) => {
                      setVolume(event.target.getVolume() / 100);
                    };

                    const onPlayerStateChange = (event) => {
                      setIsPlaying(event.data === window.YT.PlayerState.PLAYING);
                    };

                    useEffect(() => {
                      if (player) {
                        const updateProgress = () => {
                          const currentTime = player.getCurrentTime();
                          const duration = player.getDuration();
                          setProgress((currentTime / duration) * 100);
                        };

                        const progressInterval = setInterval(updateProgress, 1000);
                        return () => clearInterval(progressInterval);
                      }
                    }, [player]);

                    const togglePlay = () => {
                      if (player) {
                        isPlaying ? player.pauseVideo() : player.playVideo();
                        setIsPlaying(!isPlaying);
                      }
                    };

                    const toggleMute = () => {
                      if (player) {
                        isMuted ? player.unMute() : player.mute();
                        setIsMuted(!isMuted);
                      }
                    };

                    const toggleFullscreen = () => {
                      if (!document.fullscreenElement) {
                        if (containerRef.current.requestFullscreen) {
                          containerRef.current.requestFullscreen();
                        } else if (containerRef.current.mozRequestFullScreen) {
                          containerRef.current.mozRequestFullScreen();
                        } else if (containerRef.current.webkitRequestFullscreen) {
                          containerRef.current.webkitRequestFullscreen();
                        } else if (containerRef.current.msRequestFullscreen) {
                          containerRef.current.msRequestFullscreen();
                        }
                      } else {
                        if (document.exitFullscreen) {
                          document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                          document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                          document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) {
                          document.msExitFullscreen();
                        }
                      }
                    };

                    useEffect(() => {
                      const handleFullscreenChange = () => {
                        setIsFullscreen(!!document.fullscreenElement);
                      };

                      document.addEventListener('fullscreenchange', handleFullscreenChange);
                      document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
                      document.addEventListener('mozfullscreenchange', handleFullscreenChange);
                      document.addEventListener('MSFullscreenChange', handleFullscreenChange);

                      return () => {
                        document.removeEventListener('fullscreenchange', handleFullscreenChange);
                        document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
                        document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
                        document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
                      };
                    }, []);

                    const handleProgressClick = (e) => {
                      if (player) {
                        const progressBarWidth = progressBarRef.current.offsetWidth;
                        const clickPosition = e.nativeEvent.offsetX;
                        const newTime = (clickPosition / progressBarWidth) * player.getDuration();
                        player.seekTo(newTime);
                      }
                    };

                    const handleVolumeClick = (e) => {
                      if (player) {
                        const volumeBarWidth = volumeBarRef.current.offsetWidth;
                        const clickPosition = e.nativeEvent.offsetX;
                        const newVolume = clickPosition / volumeBarWidth;
                        player.setVolume(newVolume * 100);
                        setVolume(newVolume);
                      }
                    };

                    const skipForward = () => {
                      if (player) {
                        player.seekTo(player.getCurrentTime() + 10, true);
                      }
                    };

                    const skipBackward = () => {
                      if (player) {
                        player.seekTo(player.getCurrentTime() - 10, true);
                      }
                    };

                    const changePlaybackRate = () => {
                      if (player) {
                        const newPlaybackRate = playbackRate >= 2 ? 1 : playbackRate + 0.5;
                        player.setPlaybackRate(newPlaybackRate);
                        setPlaybackRate(newPlaybackRate);
                      }
                    };

                    const handleMouseOver = () => {
                      setShowControls(true);
                    };

                    const handleMouseOut = () => {
                      setShowControls(false);
                    };

                    return (
                      <div ref={containerRef} className={styles.videoPlayer} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                        <div className={styles.videoWrapper}>
                          <div ref={playerRef} className={styles.youtubePlayer} />                  
                          <div className={styles.overlay} onClick={togglePlay} />
                          <div className={styles.progressBarWrapper}>
                            <div className={styles.progressBar} ref={progressBarRef} onClick={handleProgressClick}>
                              <div className={styles.progress} style={{ width: `${progress}%` }}>
                                <div className={styles.progressThumb} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles.controls} ${showControls ? styles.show : ''}`}>
                          <div className={styles.leftControls}>
                            <button className={styles.playPause} onClick={togglePlay}>
                              {isPlaying ? <MdPause /> : <MdPlayArrow />}
                              <span className={styles.tooltip}>{isPlaying ? 'Pausar' : 'Reproduzir'}</span>
                            </button>
                            <button className={styles.skipBackward} onClick={skipBackward}>
                              <MdReplay10 />
                              <span className={styles.tooltip}>Recuar 10s</span>
                            </button>
                            <button className={styles.skipForward} onClick={skipForward}>
                              <MdForward10 />
                              <span className={styles.tooltip}>Avançar 10s</span>
                            </button>
                            <button className={styles.playbackRate} onClick={changePlaybackRate}>
                              {playbackRate}x
                              <span className={styles.tooltip}>Velocidade de Reprodução</span>
                            </button>
                          </div>
                          <div className={styles.rightControls}>
                            <button className={styles.mute} onClick={toggleMute}>
                              {isMuted ? <MdVolumeOff /> : <MdVolumeUp />}
                              <span className={styles.tooltip}>{isMuted ? 'Desmutar' : 'Mutar'}</span>
                            </button>
                            <div className={styles.volumeBarWrapper}>
                              <div className={styles.volumeBar} ref={volumeBarRef} onClick={handleVolumeClick}>
                                <div className={styles.volume} style={{ width: `${volume * 100}%` }}>
                                  <div className={styles.volumeThumb} />
                                </div>
                              </div>
                            </div>
                            <button className={styles.fullscreen} onClick={toggleFullscreen}>
                              {isFullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
                              <span className={styles.tooltip}>{isFullscreen ? 'Sair da Tela Cheia' : 'Tela Cheia'}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  };

                  export default VideoPlayer;