import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../components/header';
import { toast } from 'react-toastify';
import { FaUndo, FaInfoCircle, FaTimes, FaFilter, FaSearch, FaCalendarAlt } from 'react-icons/fa';
import { IoMdCash } from 'react-icons/io';
import styles from './vendas-admin.module.css';

const SalesAdmin = () => {
  const [sales, setSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totals, setTotals] = useState({ paidCommission: 0, unpaidCommission: 0 });
  const [orderYampiId, setOrderYampiId] = useState('');
  const [comissionPaid, setComissionPaid] = useState('');
  const [saleType, setSaleType] = useState('');
  const [showSaleDetails, setShowSaleDetails] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  useEffect(() => {
    fetchSales();
  }, [page, selectedUser, dateRange, orderYampiId, comissionPaid, saleType]);

  const fetchUsers = async () => {
    if (searchTerm.length < 3) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/search/users?name=${searchTerm}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data.users);
      } else {
        toast.error('Erro ao buscar usuários');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Erro ao buscar usuários');
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchUsers();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchSales = async () => {
    try {
      const queryParams = new URLSearchParams({
        page,
        limit: 10,
        userId: selectedUser ? selectedUser._id : '',
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        orderYampiId,
        comissionPaid,
        saleType,
      });

      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/sales?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSales(data.sales);
        setTotalPages(data.totalPages);
        setTotals(data.totals);
      } else {
        toast.error('Erro ao carregar vendas');
      }
    } catch (error) {
      console.error('Error fetching sales:', error);
      toast.error('Erro ao carregar vendas');
    }
  };

  const handlePayCommission = async (saleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/sales/${saleId}/pay-commission`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        toast.success('Comissão paga com sucesso');
        fetchSales();
      } else {
        toast.error('Erro ao pagar comissão');
      }
    } catch (error) {
      console.error('Error paying commission:', error);
      toast.error('Erro ao pagar comissão');
    }
  };

  const handleUnpayCommission = async (saleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/sales/${saleId}/unpay-commission`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        toast.success('Comissão marcada como não paga');
        fetchSales();
      } else {
        toast.error('Erro ao marcar comissão como não paga');
      }
    } catch (error) {
      console.error('Error unpaying commission:', error);
      toast.error('Erro ao marcar comissão como não paga');
    }
  };

  const handlePayAllCommissions = async () => {
    const userInput = prompt("Para confirmar, digite: Eu desejo marcar todas comissões do período como paga");
    
    if (!userInput) return;
  
    const normalizedInput = userInput.toLowerCase().replace(/\s+/g, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const expectedInput = "eudesejomarcartodascomissoesdoperiodocomopaga";
  
    if (normalizedInput !== expectedInput) {
      toast.error('Confirmação inválida. Operação cancelada.');
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/sales/pay-all-commissions`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          userId: selectedUser ? selectedUser._id : '',
        }),
      });
  
      if (response.ok) {
        toast.success('Todas as comissões foram pagas com sucesso');
        fetchSales();
      } else {
        toast.error('Erro ao pagar todas as comissões');
      }
    } catch (error) {
      console.error('Error paying all commissions:', error);
      toast.error('Erro ao pagar todas as comissões');
    }
  };

  const handleFilter = () => {
    setPage(1);
    fetchSales();
  };

  const handleSaleDetails = (sale) => {
    setSelectedSale(sale);
    setShowSaleDetails(true);
  };

  const handleExport = async () => {
    try {
      const queryParams = new URLSearchParams({
        userId: selectedUser ? selectedUser._id : '',
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        orderYampiId,
        comissionPaid,
        saleType,
      });
  
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/sales/export?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'vendas.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('Erro ao exportar vendas');
      }
    } catch (error) {
      console.error('Error exporting sales:', error);
      toast.error('Erro ao exportar vendas');
    }
  };

  const SaleDetailsPopup = ({ sale, onClose }) => {
    return (
      <motion.div
        className={styles.overlay}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className={styles.popup}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
        >
          <h2>Detalhes da Venda</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
          <div className={styles.popupContent}>
            <div className={styles.popupInfo}>
              <p><strong>ID da Venda:</strong> {sale.orderYampiId}</p>
              <p><strong>Data e Hora:</strong> {new Date(sale.saleDate).toLocaleString()}</p>
              <p><strong>Vendedor:</strong> {sale.user.name}</p>
              <p><strong>Valor Total:</strong> {formatCurrency(sale.totalAmount)}</p>
              <p><strong>Comissão:</strong> {formatCurrency(sale.commissionAmount)}</p>
              <p><strong>Status:</strong> {sale.canceled ? 'Cancelada' : (sale.comissionPaid ? 'Paga' : 'Pendente')}</p>
              <p><strong>Tipo de Venda:</strong> {
                sale.couponUsed === 'organic' ? 'Orgânico' : 
                sale.couponUsed === 'ads' ? 'Tráfego Pago' : 
                sale.couponUsed === 'referral' ? 'Indicação' : 'N/A'
              }</p>
              <p><strong>Cliente:</strong> {sale.metadata.customerName}</p>
              <p><strong>CPF/CNPJ do Cliente:</strong> {sale.metadata.customerCPF || sale.metadata.customerCNPJ}</p>
              <p><strong>Código do Cupom:</strong> {sale.metadata.couponCode || 'N/A'}</p>
            </div>
            <div className={styles.popupProducts}>
              <h3>Produtos Comprados:</h3>
              <ul>
                {sale.metadata.products.map((product, index) => (
                  <li key={index}>{product}</li>
                ))}
              </ul>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  return (
    <div className={styles.salesAdmin}>
      <Header />
      <div className={styles.container}>
        <motion.div
          className={styles.header}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className={styles.title}>Gerenciamento de Comissões</h1>
          <div style={{display: 'flex', gap: '1rem'}}>
          <button
            className={styles.exportButton}
            onClick={handleExport}
          >
            Exportar
          </button>
          <button
            className={styles.filterToggle}
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
          >
            <FaFilter /> Filtros
          </button>
          </div>
        </motion.div>
        <AnimatePresence>
          {isFilterExpanded && (
            <motion.div
              className={styles.filters}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className={styles.filterGroup}>
                <div className={styles.searchInputWrapper}>
                  <FaSearch className={styles.searchIcon} />
                  <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Buscar vendedor..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {searchResults.length > 0 && (
                  <ul className={styles.searchResults}>
                    {searchResults.map((user) => (
                      <li key={user._id} onClick={() => {
                        setSelectedUser(user);
                        setSearchTerm('');
                        setSearchResults([]);
                      }}>
                        {user.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className={styles.filterGroup}>
                <div className={styles.dateInputWrapper}>
                  <FaCalendarAlt className={styles.calendarIcon} />
                  <input
                    type="date"
                    className={styles.dateInput}
                    value={dateRange.startDate}
                    onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                  />
                </div>
                <div className={styles.dateInputWrapper}>
                  <FaCalendarAlt className={styles.calendarIcon} />
                  <input
                    type="date"
                    className={styles.dateInput}
                    value={dateRange.endDate}
                    onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                  />
                </div>
              </div>
              <div className={styles.filterGroup}>
                <input
                  type="text"
                  className={styles.input}
                  placeholder="ID da Venda Yampi"
                  value={orderYampiId}
                  onChange={(e) => setOrderYampiId(e.target.value)}
                />
                <select
                  className={styles.select}
                  value={comissionPaid}
                  onChange={(e) => setComissionPaid(e.target.value)}
                >
                  <option value="">Status da Comissão</option>
                  <option value="true">Paga</option>
                  <option value="false">Não Paga</option>
                </select>
                <select
                  className={styles.select}
                  value={saleType}
                  onChange={(e) => setSaleType(e.target.value)}
                >
                  <option value="">Tipo de Venda</option>
                  <option value="Orgânico">Orgânico</option>
                  <option value="Ads">Tráfego Pago</option>
                  <option value="Indicação">Indicação</option>
                </select>
              </div>
              <button className={styles.filterButton} onClick={handleFilter}>Aplicar Filtros</button>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          className={styles.totals}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <p>Comissões pagas: <span className={styles.totalAmount}>{formatCurrency(totals.paidCommission)}</span></p>
          <p>Comissões a pagar: <span className={styles.totalAmount}>{formatCurrency(totals.unpaidCommission)}</span></p>
        </motion.div>
        {(dateRange.startDate || dateRange.endDate || (selectedUser && selectedUser._id)) &&  (
          <motion.button
          className={styles.payAllButton}
          onClick={comissionPaid === 'true' ? null : handlePayAllCommissions}
        >
          Pagar todas as comissões do período
        </motion.button>
        )}
        {selectedUser && sales && sales.length > 0 && (
          <motion.div
            className={styles.userInfo}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h3>Informações Bancárias de {selectedUser.name}</h3>
            <p>Banco: {sales[0].user.bankName}</p>
            <p>Agência: {sales[0].user.bankAgency}</p>
            <p>Conta: {sales[0].user.bankAccount}</p>
            <p>Chave PIX: {sales[0].user.pixKey}</p>
          </motion.div>
        )}
        <motion.div
          className={styles.content}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {sales.length === 0 ? (
            <div className={styles.emptyState}>
              <h3>Nenhuma venda encontrada</h3>
              <p>Ajuste os filtros para visualizar as vendas</p>
            </div>
          ) : (
            <div className={styles.tableWrapper}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Data</th>
                    <th>Vendedor</th>
                    <th>Comissão</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                {sales.map((sale, index) => (
                    <motion.tr
                      key={sale._id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.05 }}
                    >
                      <td>{sale.orderYampiId}</td>
                      <td>{new Date(sale.saleDate).toLocaleString()}</td>
                      <td>{sale.user.name}</td>
                      <td>{formatCurrency(sale.commissionAmount)}</td>
                      <td>
                        <span className={`${styles.status} ${styles[sale.canceled ? 'canceled' : (sale.comissionPaid ? 'paid' : 'pending')]}`}>
                          {sale.canceled ? 'Cancelada' : (sale.comissionPaid ? 'Paga' : 'Pendente')}
                        </span>
                      </td>
                      <td>
                        {sale.couponUsed === 'organic' ? 'Orgânico' : 
                         sale.couponUsed === 'ads' ? 'Tráfego Pago' : 
                         sale.couponUsed === 'referral' ? 'Indicação' : 'N/A'}
                      </td>
                      <td>
                        {!sale.canceled && (
                          sale.comissionPaid ? (
                            <motion.button
                              className={`${styles.actionButton} ${styles.unpayButton}`}
                              onClick={() => handleUnpayCommission(sale._id)}
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                            >
                              <FaUndo />
                            </motion.button>
                          ) : (
                            <motion.button
                              className={`${styles.actionButton} ${styles.payButton}`}
                              onClick={() => handlePayCommission(sale._id)}
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                            >
                              <IoMdCash />
                            </motion.button>
                          )
                        )}
                        <motion.button
                          className={`${styles.actionButton} ${styles.infoButton}`}
                          onClick={() => handleSaleDetails(sale)}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <FaInfoCircle />
                        </motion.button>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </motion.div>
        <motion.div
          className={styles.pagination}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <button
            className={styles.paginationButton}
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          >
            Anterior
          </button>
          <span className={styles.pageInfo}>
            Página {page} de {totalPages}
          </span>
          <button
            className={styles.paginationButton}
            disabled={page === totalPages}
            onClick={() => setPage(page + 1)}
          >
            Próxima
          </button>
        </motion.div>
      </div>
      <AnimatePresence>
        {showSaleDetails && (
          <SaleDetailsPopup
            sale={selectedSale}
            onClose={() => setShowSaleDetails(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default SalesAdmin;