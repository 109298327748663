import React, { useRef, useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { pipelineStages } from './constants';
import styles from './TabContainer.module.css';

const TabContainer = ({ activeTab, onTabChange, userMetrics }) => {
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const tabContainerRef = useRef(null);

  useEffect(() => {
    const checkScroll = () => {
      if (tabContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = tabContainerRef.current;
        setShowLeftScroll(scrollLeft > 0);
        setShowRightScroll(scrollLeft < scrollWidth - clientWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, []);

  const handleTabScroll = (direction) => {
    if (tabContainerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      tabContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.tabContainerWrapper}>
      <button 
        className={`${styles.tabScrollButton} ${styles.tabScrollButtonLeft}`} 
        onClick={() => handleTabScroll('left')}
        style={{ visibility: showLeftScroll ? 'visible' : 'hidden' }}
      >
        <MdChevronLeft />
      </button>
      <div 
        ref={tabContainerRef} 
        className={styles.tabContainer}
        onScroll={() => {
          setShowLeftScroll(tabContainerRef.current.scrollLeft > 0);
          setShowRightScroll(
            tabContainerRef.current.scrollLeft < 
            tabContainerRef.current.scrollWidth - tabContainerRef.current.clientWidth
          );
        }}
      >
        {pipelineStages.map((stage) => (
          <button
            key={stage.key}
            className={`${styles.tabButton} ${activeTab === stage.key ? styles.active : ''}`}
            onClick={() => onTabChange(stage.key)}
          >
            {stage.icon}
            <span className={styles.tabLabel}>{stage.label}</span>
            <span className={styles.badge}>{userMetrics[stage.key] || 0}</span>
          </button>
        ))}
      </div>
      <button 
        className={`${styles.tabScrollButton} ${styles.tabScrollButtonRight}`} 
        onClick={() => handleTabScroll('right')}
        style={{ visibility: showRightScroll ? 'visible' : 'hidden' }}
      >
        <MdChevronRight />
      </button>
    </div>
  );
};

export default TabContainer;