import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './popup-editadd-curso.module.css';

const PopupEditAddCurso = ({ isEditing, initialData, onSave, onCancel }) => {
  const [formData, setFormData] = useState(initialData || {
    capa: null,
    nome: '',
    descricao: '',
    categoria: '',
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isEditing && initialData.capa) {
      setPreviewUrl(initialData.capa);
    }
  }, [isEditing, initialData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'capa') {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        capa: file,
      }));
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setPreviewUrl(null);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setError(null);
  };

  const handleCapaClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEditing && !formData.capa && !previewUrl) {
      setError('A capa do curso é obrigatória');
      return;
    }
    const dataToSave = {
      ...formData,
      id: initialData?._id || undefined,
    };
    onSave(dataToSave);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleChange({ target: { name: 'capa', files: [file] } });
    }
  };

  return (
    <motion.div 
      className={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className={styles.modal}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
      >
        <h2 className={styles.title}>{isEditing ? 'Editar Curso' : 'Novo Curso'}</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="capa">Capa do Curso</label>
            <div
              className={`${styles.fileUpload} ${previewUrl ? styles.hasImage : ''} ${isDragging ? styles.dragging : ''}`}
              onClick={handleCapaClick}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                type="file"
                id="capa"
                name="capa"
                accept="image/*"
                onChange={handleChange}
                ref={fileInputRef}
                className={styles.fileInput}
              />
              {previewUrl ? (
                <img src={previewUrl} alt="Preview" className={styles.capaPreview} />
              ) : (
                <div className={styles.capaPlaceholder}>
                  <i className="fas fa-cloud-upload-alt"></i>
                  <p>Clique ou arraste uma imagem aqui</p>
                  <span>Formatos aceitos: JPG, PNG, GIF (Max. 5MB)</span>
                </div>
              )}
            </div>
            {error && <p className={styles.errorMessage}>{error}</p>}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="nome">Nome do Curso</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              placeholder="Digite o nome do curso"
              required
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="descricao">Descrição</label>
            <textarea
              id="descricao"
              name="descricao"
              value={formData.descricao}
              onChange={handleChange}
              placeholder="Digite a descrição do curso"
              rows="4"
              required
              className={styles.textarea}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="categoria">Categoria</label>
            <input
              type="text"
              id="categoria"
              name="categoria"
              value={formData.categoria}
              onChange={handleChange}
              placeholder="Digite a categoria do curso"
              required
              className={styles.input}
            />
          </div>
          <div className={styles.buttonGroup}>
            <motion.button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onCancel}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Cancelar
            </motion.button>
            <motion.button 
              type="submit" 
              className={styles.saveButton}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isEditing ? 'Salvar Alterações' : 'Criar Curso'}
            </motion.button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default PopupEditAddCurso;