import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import { MdPerson, MdPersonAdd, MdPersonRemove, MdMale, MdFemale, MdTransgender, MdStar, MdAttachMoney, MdTrendingUp, MdCalendarToday, MdDateRange, MdViewWeek } from 'react-icons/md';
import styles from './dashboard.module.css';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [rankingType, setRankingType] = useState('points');
  const [rankingPeriod, setRankingPeriod] = useState('geral');

  const rankingPeriods = [
    { value: 'geral', label: 'Geral', icon: MdCalendarToday },
    { value: 'mensal', label: 'Mensal', icon: MdDateRange },
    { value: 'semanal', label: 'Semanal', icon: MdViewWeek },
  ];

  const fetchDashboardData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/admin/dashboard`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { type: rankingPeriod }
      });
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }, [rankingType, rankingPeriod]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleRankingTypeChange = (type) => {
    setRankingType(type);
  };

  const handleRankingPeriodChange = (period) => {
    setRankingPeriod(period);
  };

  const formatMonth = (value) => {
    const monthNames = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
    const [month, year] = value.split(' ');
    return `${monthNames[new Date(value).getMonth()]}/${year.slice(-2)}`;
  };

  const formatCurrency = (value) => value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  const MetricCard = ({ icon: Icon, title, value, period, bgColor }) => (
    <div className={styles.metricCard}>
      <div className={`${styles.metricIcon} ${styles[bgColor]}`}>
        <Icon size={32} />
      </div>
      <div className={styles.metricContent}>
        <h3>{title}</h3>
        <p className={styles.metricValue}>{value}</p>
        {period && <p className={styles.metricPeriod}>{period}</p>}
      </div>
    </div>
  );

  return (
    <>
      <Header />
      <div className={styles.dashboard}>
        <div className={styles.dashboardContainer}>
          <h1>Dashboard</h1>
          <div className={styles.metricsGrid}>
            <MetricCard icon={MdPersonAdd} title="Novos Influenciadores" value={dashboardData?.newInfluencers30Days} period="(últimos 30 dias)" bgColor="primaryBg" />
            <MetricCard icon={MdPersonRemove} title="Influenciadores Desativados" value={dashboardData?.deactivatedInfluencers} bgColor="secondaryBg" />
            <MetricCard icon={MdPerson} title="Total de Influenciadores" value={dashboardData?.activeApprovedInfluencers} bgColor="primaryBg" />
            <MetricCard icon={MdFemale} title="Influenciadoras Mulheres" value={dashboardData?.femaleInfluencers} bgColor="secondaryBg" />
            <MetricCard icon={MdMale} title="Influenciadores Homens" value={dashboardData?.maleInfluencers} bgColor="primaryBg" />
            <MetricCard icon={MdTransgender} title='Influenciadores com Gênero "Outro"' value={dashboardData?.otherGenderInfluencers} bgColor="secondaryBg" />
            <div className={`${styles.metricCard} ${styles.highlightCard} ${styles.ratingCard}`}>
              <div className={`${styles.metricIcon} ${styles.starBg}`}>
                <MdStar size={32} />
              </div>
              <div className={styles.metricContent}>
                <h3>Avaliação Média</h3>
                <p className={styles.metricValue}>{dashboardData?.appRating.average}</p>
                <p className={styles.metricPeriod}>{dashboardData?.appRating.count} avaliações</p>
                <Link to="/avaliacoes" className={styles.btn}>Ver Avaliações</Link>
              </div>
            </div>
          </div>
          <div className={styles.chartsGrid}>
            <div className={styles.chartCard}>
              <h3>Influenciadores Novos e Desativados por Mês</h3>
              <ResponsiveContainer width="100%" aspect={2} className={styles.chartContainer}>
                <LineChart data={dashboardData?.monthlyInfluencerStats}>
                  <XAxis dataKey="label" tickFormatter={formatMonth} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip labelFormatter={formatMonth} />
                  <Legend />
                  <Line type="monotone" dataKey="newUsers" name="Novos" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="deactivatedUsers" name="Desativados" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className={styles.chartCard}>
              <h3>Comissão Mensal por Tipo</h3>
              <ResponsiveContainer width="100%" aspect={2} className={styles.chartContainer}>
                <BarChart data={dashboardData?.monthlyCommissionStats}>
                  <XAxis dataKey="label" tickFormatter={formatMonth} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip labelFormatter={formatMonth} formatter={formatCurrency} />
                  <Legend />
                  <Bar dataKey="organic" name="Orgânico" fill="#8884d8" />
                  <Bar dataKey="ads" name="Ads" fill="#82ca9d" />
                  <Bar dataKey="referral" name="Indicação" fill="#ffc658" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <MetricCard 
              icon={MdAttachMoney} 
              title="Comissão Total do Mês Atual" 
              value={formatCurrency(dashboardData?.currentMonthCommission)} 
              bgColor="moneyBg" 
            />
            <MetricCard 
              icon={MdTrendingUp} 
              title="Receita Total do Mês Atual" 
              value={formatCurrency(dashboardData?.currentMonthRevenue)} 
              bgColor="successBg" 
            />
            <div className={styles.chartCard}>
              <h3>Receita Mensal por Tipo de Cupom</h3>
              <ResponsiveContainer width="100%" aspect={2} className={styles.chartContainer}>
                <BarChart data={dashboardData?.monthlyRevenueStats}>
                  <XAxis dataKey="label" tickFormatter={formatMonth} />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip labelFormatter={formatMonth} formatter={formatCurrency} />
                  <Legend />
                  <Bar dataKey="organic" name="Organico" fill="#8884d8" />
                  <Bar dataKey="ads" name="Ads" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className={styles.rankingCard}>
              <div className={styles.rankingHeader}>
                <h3>Ranking</h3>
                <div className={styles.rankingTypeSelector}>
                  <button 
                    className={`${styles.rankingTypeButton} ${rankingType === 'points' ? styles.active : ''}`}
                    onClick={() => handleRankingTypeChange('points')}
                  >
                    Por Pontos
                  </button>
                  <button 
                    className={`${styles.rankingTypeButton} ${rankingType === 'referral' ? styles.active : ''}`}
                    onClick={() => handleRankingTypeChange('referral')}
                  >
                    Por Indicações
                  </button>
                </div>
                <div className={styles.rankingPeriodSelector}>
                {rankingPeriods.map((period) => (
                  <button
                    key={period.value}
                    className={`${styles.rankingPeriodButton} ${rankingPeriod === period.value ? styles.active : ''}`}
                    onClick={() => handleRankingPeriodChange(period.value)}
                  >
                    <period.icon className={styles.rankingPeriodIcon} />
                    {period.label}
                  </button>
                ))}
              </div>
              </div>
              <ul className={styles.rankingList}>
                {(rankingType === 'points' ? dashboardData?.topPointsUsers : dashboardData?.topReferralUsers)?.map((user) => (
                  <Link to={`/usuario/${user.username}`} key={user._id} className={styles.rankingLink}> 
                    <li className={styles.rankingItem}>
                      <span className={styles.rankingPosition}>{user.rank}º</span>
                      {user.profilePicture ? (
                        <img src={user.profilePicture} alt={user.name} className={styles.rankingAvatar} />
                      ) : (
                        <span className={styles.rankingAvatarPlaceholder}>{user.acronym}</span>
                      )}
                      <span className={styles.rankingName}>{user.name}</span>
                      <span className={styles.rankingPoints}>
                        {rankingType === 'points' ? user.totalPoints : user.referralCount}
                        {rankingType === 'points' ? (
                          <img src="./points.gif" alt="Pontos" className={styles.rankingPointsIcon} />
                        ) : (
                          <span className={styles.rankingIndicationsText}> Indicações</span>
                        )}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;