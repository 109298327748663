import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle, FaWhatsapp } from 'react-icons/fa';
import { MdOutlineArrowForward } from 'react-icons/md';
import { approveOrRejectUser, toggleUserStatus } from './api';
import { useAction } from '../../states';
import styles from './UserCard.module.css';
import { format, formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ApprovalComponent from '../../components/admin/influenciadores/user-approval';

const UserCard = ({ user, activeTab, onUpdate }) => {
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const history = useHistory();
  const { addAction } = useAction();
  const { _id, name, profilePicture, instagram, facebook, youtube, tiktok, isActive, isApproved, phone, username, lastLogin, totalSales, totalRevenue, totalCommissions, totalReferrals, referralRevenue, eligiblePoints, inactive30d } = user;

  const handleManagePoints = async (action) => {
    const points = prompt('Quantos pontos você deseja ' + (action === 'add' ? 'adicionar' : 'remover') + '?');
    if (!points || isNaN(points) || points <= 0) {
      alert('Quantidade de pontos inválida.');
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_DATABASE}/admin/users/manage-points`, {
        userId: _id,
        action,
        points: Number(points),
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (response.data.success) {
        alert(`Pontos ${action === 'add' ? 'adicionados' : 'removidos'} com sucesso!`);
        onUpdate(); 
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao gerenciar pontos do usuário:', error);
      alert('Erro ao gerenciar pontos do usuário.');
    }
  };
  
  
  const handleApproveOrReject = async (action) => {
    try {
      const response = await approveOrRejectUser(_id, action);
      addAction(activeTab, { actionLogId: response.actionLogId, type: action });
      onUpdate();
    } catch (error) {
      console.error('Error approving/rejecting user:', error);
    }
  };

  const handleToggleStatus = async () => {
    try {
      const response = await toggleUserStatus(_id);
      addAction(activeTab, { actionLogId: response.actionLogId, type: isActive ? 'deactivated' : 'activated' });
      onUpdate();
    } catch (error) {
      console.error('Error toggling user status:', error);
    }
  };

  const acronym = name.split(' ').map((word) => word.charAt(0)).join('').slice(0, 2).toUpperCase();

  const formatRelativeTime = (dateString) => {
    if (!dateString) return 'Data desconhecida';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error('Data inválida');
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);
      if (diffInSeconds < 60) return 'Agora';
      if (diffInSeconds < 86400) return formatDistanceToNow(date, { addSuffix: true, locale: ptBR });
      return format(date, "d 'de' MMMM 'de' yyyy", { locale: ptBR });
    } catch (error) {
      console.error('Erro ao formatar data:', error);
      return 'Data inválida';
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const renderSocialIcon = (platform, link) => {
    const opacity = link ? 1 : 0.3;
    const cursor = link ? 'pointer' : 'default';
    return (
      <a 
        href={link} 
        target="_blank" 
        rel="noopener noreferrer" 
        className={styles.socialIcon} 
        style={{ opacity, cursor: cursor }}
        onClick={(e) => !link && e.preventDefault()}
      >
        <img src={`/${platform}.png`} alt={platform} className={styles.socialIconImage} />
      </a>
    );
  };

  return (
    <>
      <div className={`${styles.userCard} ${!isApproved ? styles.notApproved : ''}`}>
        <div className={styles.cardHeader}>
          <div className={styles.profileImage}>
            {profilePicture ? (
              <img src={profilePicture} alt={name} className={styles.profilePicture} />
            ) : (
              <div className={styles.profileAcronym}>{acronym}</div>
            )}
          </div>
          <div className={styles.userInfo}>
            <div className={styles.userNameContainer}>
              <h3 className={styles.userName}>{name}</h3>
              {inactive30d && (
                <span className={styles.inactiveWarning} title="Inativo nos últimos 30 dias">
                  ⚠️
                </span>
              )}
            </div>
            <div className={styles.socialMedia}>
              {renderSocialIcon('instagram', instagram)}
              {renderSocialIcon('facebook', facebook)}
              {renderSocialIcon('youtube', youtube)}
              {renderSocialIcon('tiktok', tiktok)}
            </div>
          </div>
        </div>

        <div className={styles.cardBody}>
          {['influenciador_desativado', 'influenciador_sem_venda', 'influenciador_ativo'].includes(activeTab) && formatRelativeTime(lastLogin) !== 'Data desconhecida' && (
            <div className={styles.lastLogin}>
              <span className={styles.infoValue}>{formatRelativeTime(lastLogin)}</span>
            </div>
          )}
          {activeTab === 'influenciador_ativo' && (
            <div className={styles.salesInfo}>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Vendas</span>
                <span className={styles.infoValue}>{totalSales}</span>
              </div>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Indicações</span>
                <span className={styles.infoValue}>{totalReferrals}</span>
              </div>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Receita</span>
                <span className={styles.infoValue}>{formatCurrency(totalRevenue)}</span>
              </div>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Receita Ind.</span>
                <span className={styles.infoValue}>{formatCurrency(referralRevenue)}</span>
              </div>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Comissões</span>
                <span className={styles.infoValue}>{formatCurrency(totalCommissions)}</span>
              </div>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Pontuação Voucher</span>
                <span className={styles.infoValue}>{eligiblePoints}</span>
                <div className={styles.pointsButtons}>
                  <button className={styles.addPointsButton} onClick={() => handleManagePoints('add')}>+</button>
                  <button className={styles.removePointsButton} onClick={() => handleManagePoints('remove')}>-</button>
                </div>
              </div>
            </div>
          )}
          {activeTab === "influenciador_sem_venda" && (
            <div className={styles.salesInfo}>
              <div className={styles.infoRow}>
                <span className={styles.infoLabel}>Pontuação Voucher</span>
                <span className={styles.infoValue}>{eligiblePoints}</span>
                <div className={styles.pointsButtons}>
                  <button className={styles.addPointsButton} onClick={() => handleManagePoints('add')}>+</button>
                  <button className={styles.removePointsButton} onClick={() => handleManagePoints('remove')}>-</button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.cardFooter}>
          <button onClick={() => history.push(`/usuario/${username}`)} className={styles.viewMoreButton}>
            Ver mais <MdOutlineArrowForward />
          </button>
          {phone && (
            <a href={`https://wa.me/${phone}`} target="_blank" rel="noopener noreferrer" className={styles.whatsappButton}>
              <FaWhatsapp />
            </a>
          )}
        </div>

        {isApproved ? (
          <div className={styles.userStatus}>
            <button 
              className={`${styles.statusButton} ${isActive ? styles.active : styles.inactive}`} 
              onClick={handleToggleStatus}
            >
              {isActive ? 'Ativo' : 'Inativo'}
            </button>
          </div>
  ) : (
      <div className={styles.userApproval}>
        <button className={styles.approveButton} onClick={() => setShowApprovalPopup(true)}>
          <FaCheckCircle />
        </button>
        {activeTab !== 'reprovado' && (
          <button className={styles.rejectButton} onClick={() => handleApproveOrReject('reject')}>
            <FaTimesCircle />
          </button>
        )}
      </div>
    )}
      </div>
      {showApprovalPopup && (
        <ApprovalComponent userId={_id} onClose={() => {
          setShowApprovalPopup(false);
        }} />
      )}
    </>
);
};

export default UserCard;