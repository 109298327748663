import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './page-resetar-senha-trocar-senha-token.css';

const PageResetarSenhaTrocarSenhaToken = ({ onResetPassword, token, isPopup, onClose }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('As senhas não coincidem.');
      return;
    }

    if (isPopup) {
      onResetPassword(password, confirmPassword);
    } else {
      onResetPassword(token, password);
    }
  };

  const renderForm = () => (
    <>
      <div className="page-resetar-senha-trocar-senha-token-frame347">
        <span className="page-resetar-senha-trocar-senha-token-text HeaderH1-24Medium">{isPopup ? 'Alterar Senha' : 'Resetar Senha'}</span>
        <span className="page-resetar-senha-trocar-senha-token-text1">{isPopup ? 'Defina sua nova senha' : 'Defina sua nova senha abaixo'}</span>
      </div>
      <div className="page-resetar-senha-trocar-senha-token-container">
        <div className="page-resetar-senha-trocar-senha-token-textfields">
          <span className="page-resetar-senha-trocar-senha-token-text2 TextSmall-14Regular">Senha</span>
          <input type="password" placeholder="Senha" className="input" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="page-resetar-senha-trocar-senha-token-textfields1">
          <span className="page-resetar-senha-trocar-senha-token-text3 TextSmall-14Regular">Confirmar senha</span>
          <input type="password" placeholder="Confirmar senha" className="input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
      </div>
      <div className={`${isPopup ? 'excluir-conta-frame306' : 'page-resetar-senha-trocar-senha-token-buttons-container'}`}>
        {isPopup && (
          <button className="excluir-conta-buttons" onClick={onClose}>
            <span className="page-resetar-senha-trocar-senha-token-text4 TextMedium-16Medium">Cancelar</span>
          </button>
        )}
        <button className="page-resetar-senha-trocar-senha-token-buttons" style={isPopup ? { flexGrow: '1' } : {}} onClick={handleSubmit}>
          <span className="page-resetar-senha-trocar-senha-token-text4 TextMedium-16Medium">{isPopup ? 'Alterar' : 'Trocar senha'}</span>
        </button>
      </div>
    </>
  );

  return (
    <div style={isPopup ? { position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 } : {}}>
      <div className={`page-resetar-senha-enviar-email-reset-enviar-email-reset ${isPopup ? 'popup-content excluir-conta-frame301' : ''}`}>
        {renderForm()}
      </div>
    </div>
  );
};

export default PageResetarSenhaTrocarSenhaToken;