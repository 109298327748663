import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Header from "../components/header";
import PerfilFormDadosPessoais from "../components/perfil-form-dados-pessoais";
import PerfilFormDadosBancarios from "../components/perfil-form-dados-bancarios";
import FormCuponsDesconto from "../components/form-cupom";
import { toast } from "react-toastify";
import PopupExcluirConta from "../components/excluir-conta";
import { useParams } from "react-router-dom";
import "./perfil.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { FaUser, FaChartLine, FaLock, FaTrash } from "react-icons/fa";

const UserPage = () => {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("data");
  const [monthlyCommissionChart, setMonthlyCommissionChart] = useState([]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { username } = useParams();
  const history = useHistory();

  const [isRemoveAccountPopupOpen, setIsRemoveAccountPopupOpen] = useState(false);

  const handleOpenRemoveAccountPopup = () => {
    setIsRemoveAccountPopupOpen(true);
  };

  const handleCloseRemoveAccountPopup = () => {
    setIsRemoveAccountPopupOpen(false);
  };

  const handleUpdateAccount = async (updateData) => {
    try {
      const formData = new FormData();
      
      Object.keys(updateData).forEach(key => {
        if (updateData[key] !== undefined && updateData[key] !== null) {
          if (key === 'mainAddress' || key === 'deliveryAddress') {
            formData.append(key, JSON.stringify(updateData[key]));
          } else if (key === 'profilePicture' && updateData[key] instanceof File) {
            formData.append('profilePicture', updateData[key]);
          } else {
            formData.append(key, updateData[key].toString());
          }
        }
      });
  
      const response = await axios.put(
        `${process.env.REACT_APP_DATABASE}/admin/user/${username}/edit`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );      
      if (response.data.success) {
        setUser(prevUser => ({
          ...prevUser,
          ...response.data.user
        }));
        toast.success("Perfil atualizado com sucesso!");
      } else {
        if (response.data.errors && Array.isArray(response.data.errors)) {
          response.data.errors.forEach(error => {
            toast.error(error.msg);
          });
        } else {
          toast.error(response.data.message || "Erro ao atualizar perfil.");
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach(err => {
          toast.error(err.msg);
        });
      } else {
        toast.error(error?.response?.data?.message || "Algo deu errado.");
      }
      console.error(error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATABASE}/admin/user/${username}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data) {
        setUser(response.data);
      } else {
        history.replace("/usuarios");
        toast.error("O usuário não existe");
      }
    } catch (error) {
      history.replace("/usuarios");
      toast.error("Erro ao buscar usuário");
    }
  };

  const handleRemoveProfilePicture = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DATABASE}/admin/user/${username}/profile-picture`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success) {
        setUser(prevUser => ({
          ...prevUser,
          profilePicture: null,
        }));
        toast.success("Foto de perfil removida com sucesso!");
      } else {
        toast.error(response.data.message || "Erro ao remover foto de perfil.");
      }
    } catch (error) {
      toast.error("Algo deu errado ao remover a foto de perfil.");
    }
  };

  const handleRemoveAccount = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DATABASE}/admin/user/${username}/delete`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success) {
        handleCloseRemoveAccountPopup();
        history.push("/usuarios");
        toast.success("Usuário removido com sucesso!");
      } else {
        toast.error(response.data.message || "Erro ao excluir conta!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao excluir conta!");
    }
  };

  const fetchUserMetrics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATABASE}/admin/user/${username}/stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data) {
        setUser((prevUser) => ({
          ...prevUser,
          organicCommission: response.data.organicCommission,
          adsCommission: response.data.adsCommission,
          referralCommission: response.data.referralCommission,
          referredUsers: response.data.referrals,
          ranking: response.data.ranking,
          totalCommission: response.data.totalCommission,
        }));
        setMonthlyCommissionChart(response.data.monthlyCommission);
      } else {
        toast.error("Erro ao buscar métricas do usuário");
      }
    } catch (error) {
      console.error("Error fetching user metrics:", error);
      toast.error("Erro ao buscar métricas do usuário");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
  
    if (newPassword !== confirmPassword) {
      toast.error("A nova senha e a confirmação não correspondem");
      return;
    }
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_DATABASE}/admin/user/${username}/reset-password`,
        { newPassword, confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (response.data.success) {
        toast.success("Senha redefinida com sucesso");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        toast.error(response.data.message || "Erro ao redefinir a senha");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao redefinir a senha");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user && activeTab === "metrics") {
      fetchUserMetrics();
    }
  }, [activeTab]);

  const renderUserAvatar = () => {
    if (user?.profilePicture) {
      return (
        <div className="adm-user-profile-picture">
          <img
            src={user.profilePicture}
            alt="User Profile"
            className="perfil-user-avatar"
          />
        </div>
      );
    } else if (user?.name) {
      const words = user.name.split(" ");
      const acronym = words.slice(0, 2).map(word => word[0]).join("").toUpperCase();
      return <div className="perfil-user-avatar-acronym">{acronym}</div>;
    } else {
      return <div className="perfil-user-avatar-acronym">?</div>;
    }
  };

  return (
    <div className="perfil-container">
      <Helmet>
        <title>Perfil - Body Dry</title>
        <meta property="og:title" content="Perfil - Body Dry" />
      </Helmet>
      <Header rootClassName="header-root-class-name" />
      {isRemoveAccountPopupOpen && (
        <PopupExcluirConta
          name={user?.name?.split(" ")[0]}
          onRemoveAccount={handleRemoveAccount}
          onClose={handleCloseRemoveAccountPopup}
        />
      )}
      <div className="perfil-content">
        <div className="perfil-sidebar">
          <div className="perfil-user-info">
            {renderUserAvatar()}
            <h2 className="perfil-user-name">{user?.name}</h2>
            <p className="perfil-user-email">{user?.email}</p>
          </div>
          <nav className="perfil-nav">
            <ul>
              <li
                className={`perfil-nav-item ${
                  activeTab === "data" ? "active" : ""
                }`}
                onClick={() => setActiveTab("data")}
              >
                <FaUser className="perfil-nav-icon" />
                <span>Dados</span>
              </li>
              <li
                className={`perfil-nav-item ${
                  activeTab === "metrics" ? "active" : ""
                }`}
                onClick={() => setActiveTab("metrics")}
              >
                <FaChartLine className="perfil-nav-icon" />
                <span>Métricas</span>
              </li>
              <li
                className={`perfil-nav-item ${
                  activeTab === "password" ? "active" : ""
                }`}
                onClick={() => setActiveTab("password")}
              >
                <FaLock className="perfil-nav-icon" />
                <span>Redefinir Senha</span>
              </li>
            </ul>
          </nav>
          <button
            className="perfil-remove-account-btn"
            onClick={handleOpenRemoveAccountPopup}
          >
            <FaTrash className="perfil-remove-account-icon" />
            <span>Excluir {user?.name?.split(" ")[0]}</span>
          </button>
        </div>
        <div className="perfil-main-content">
          {activeTab === "data" && (
            <div className="perfil-data-tab">
              <h2 className="perfil-tab-title">Dados</h2>
              {user?.indicatorName && (
                <div className="perfil-referral-info">
                  <h3>Indicado por</h3>
                  <div className="perfil-referral-user">
                    {user.indicatorProfilePicture ? (
                      <img
                        src={user.indicatorProfilePicture}
                        alt="Referral User"
                        className="perfil-referral-user-avatar"
                      />
                    ) : (
                      <div className="perfil-referral-user-avatar-acronym">
                        {user.indicatorName
                          .split(" ")
                          .map((word) => word[0])
                          .join("")
                          .toUpperCase()}
                      </div>
                    )}
                    <div>
                      <p className="perfil-referral-user-name">
                        {user.indicatorName}
                      </p>
                      <p className="perfil-referral-user-email">
                        {user.indicatorEmail}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="perfil-forms">
                <PerfilFormDadosPessoais
                  isUserPage
                  formData={user}
                  handleUpdateUser={(data) => handleUpdateAccount({ ...data })}
                />
                <PerfilFormDadosBancarios
                  isUserPage
                  handleUpdateUser={(data) => handleUpdateAccount({ ...data })}
                  formData={user}
                />
                <FormCuponsDesconto
                  isUserPage
                  handleUpdateUser={(data) => handleUpdateAccount({ ...data })}
                  formData={user}
                />
              </div>
            </div>
          )}
          {activeTab === "metrics" && (
            <div className="perfil-metrics-tab">
              <h2 className="perfil-tab-title">Métricas</h2>
              <div className="perfil-metrics-grid">
                <div className="perfil-metric-card">
                  <h3 className="perfil-metric-title">
                    Comissão Orgânico no Mês Atual
                  </h3>
                  <p className="perfil-metric-value">
                    {user?.organicCommission?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }) || "R$ 0,00"}
                  </p>
                </div>
                <div className="perfil-metric-card">
                  <h3 className="perfil-metric-title">
                    Comissão Tráfego Pago no Mês Atual
                  </h3>
                  <p className="perfil-metric-value">
                    {user?.adsCommission?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }) || "R$ 0,00"}
                  </p>
                </div>
                <div className="perfil-metric-card">
                  <h3 className="perfil-metric-title">
                    Comissão Indicação no Mês Atual
                  </h3>
                  <p className="perfil-metric-value">
                    {user?.referralCommission?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }) || "R$ 0,00"}
                  </p>
                </div>
                <div className="perfil-metric-card">
                  <h3 className="perfil-metric-title">
                    Usuários Indicados
                  </h3>
                  <p className="perfil-metric-value">
                    {user?.referredUsers || 0}
                  </p>
                </div>
                <div className="perfil-metric-card">
                  <h3 className="perfil-metric-title">
                    Posição no Ranking
                  </h3>
                  <p className="perfil-metric-value">{user?.ranking}</p>
                </div>
                <div className="perfil-metric-card perfil-metric-card-highlight">
                  <h3 className="perfil-metric-title">
                    Valor Total Comissão no Mês Atual
                  </h3>
                  <p className="perfil-metric-value">
                    {user?.totalCommission?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }) || "R$ 0,00"}
                  </p>
                </div>
              </div>
              <div className="perfil-chart-container">
                <h3 className="perfil-chart-title">Comissão Mensal por Tipo</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={monthlyCommissionChart}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip
                      formatter={(value) =>
                        `${value?.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }) || "R$ 0,00"}`
                      }
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="organic"
                      name="Orgânico"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="ads"
                      name="Tráfego Pago"
                      stroke="#82ca9d"
                    />
                    <Line
                      type="monotone"
                      dataKey="referral"
                      name="Indicação"
                      stroke="#ffc658"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {activeTab === "password" && (
            <div className="perfil-password-tab">
              <h2 className="perfil-tab-title">Redefinir Senha</h2>
              <form className="perfil-password-form" onSubmit={handleResetPassword}>
                <div className="perfil-form-group">
                  <label htmlFor="newPassword">Nova Senha</label>
                  <input
                    type="password"
                    id="newPassword"
                    className="perfil-form-input input"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className="perfil-form-group">
                  <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    className="perfil-form-input input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="perfil-form-submit-btn">
                  Redefinir Senha
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPage;