import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { MdTimer, MdClose, MdLock } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../components/header';
import styles from './quizz.module.css';

const Quizz = () => {
  const navigate = useHistory();
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [score, setScore] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const timerRef = useRef(null);

  const fetchQuiz = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/academy/quiz/${quizId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.data && response.data.quiz) {
        setQuiz(response.data.quiz);
        
        if (response.data.quizFinalizado) {
          setQuizCompleted(true);
          setScore(response.data.acertos || 0);
        } else {
          setTimeRemaining(response.data.tempoRestante ? response.data.tempoRestante / 1000 : 0);
          setAnsweredQuestions(response.data.respostasAtuais ? response.data.respostasAtuais.map(r => r.pergunta) : []);
          setCurrentQuestion(response.data.respostasAtuais ? response.data.respostasAtuais.length : 0);
        }
      } else {
        throw new Error('Dados do quiz não encontrados na resposta');
      }
      
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao buscar o quiz:', error);
      setError('Ocorreu um erro ao carregar o quiz. Por favor, tente novamente.');
      setIsLoading(false);
    }
  }, [quizId]);

  useEffect(() => {
    fetchQuiz();
  }, [fetchQuiz]);

  useEffect(() => {
    if (timeRemaining > 0 && !quizCompleted) {
      timerRef.current = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current);
            handleQuizCompletion();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [timeRemaining, quizCompleted]);

  const handleAnswerSelection = (answer) => {
    if (!answeredQuestions.includes(currentQuestion)) {
      setSelectedAnswer(answer);
    }
  };

  const handleNextQuestion = async () => {
    if (isSubmitting || !quiz || !quiz.perguntas) return;

    setIsSubmitting(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_DATABASE}/academy/quiz/responder`,
        {
          quizId,
          perguntaId: quiz.perguntas[currentQuestion].id,
          alternativaId: quiz.perguntas[currentQuestion].alternativas[selectedAnswer].id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.correta) {
        setScore((prevScore) => prevScore + 1);
      }

      setAnsweredQuestions([...answeredQuestions, currentQuestion]);
      setSelectedAnswer(null);

      if (currentQuestion === quiz.perguntas.length - 1 || response.data.quizFinalizado) {
        handleQuizCompletion();
      } else {
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      }
    } catch (error) {
      console.error('Erro ao responder pergunta:', error);
      setError('Ocorreu um erro ao enviar sua resposta. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleQuizCompletion = () => {
    clearInterval(timerRef.current);
    setQuizCompleted(true);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleCloseQuiz = () => {
    navigate.push('/academy');
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p>Carregando quiz...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <p>{error}</p>
      </div>
    );
  }

  if (!quiz || !quiz.perguntas || quiz.perguntas.length === 0) {
    return (
      <div className={styles.errorContainer}>
        <p>Não foi possível carregar o quiz. Por favor, tente novamente mais tarde.</p>
      </div>
    );
  }

  return (
    <div className={styles.quizzPage}>
      <Header />
      <div className={styles.quizContainer}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={styles.quizHeader}
        >
          <h2 className={styles.quizTitle}>{quiz.curso?.nome || 'Quiz'}</h2>
          {!quizCompleted && (
            <div className={styles.quizTimer}>
              <MdTimer size={24} />
              <span>{formatTime(timeRemaining)}</span>
            </div>
          )}
          <div className={styles.quizProgress}>
            <div
              className={styles.quizProgressBar}
              style={{ width: `${((currentQuestion + 1) / quiz.perguntas.length) * 100}%` }}
            ></div>
          </div>
        </motion.div>
        <AnimatePresence mode="wait">
          {!quizCompleted && currentQuestion < quiz.perguntas.length ? (
            <motion.div
              key={currentQuestion}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className={styles.questionContainer}
            >
              <div className={styles.questionHeader}>
                <h3 className={styles.questionTitle}>
                  Pergunta {currentQuestion + 1} de {quiz.perguntas.length}
                </h3>
                {quiz.perguntas[currentQuestion].imagemPergunta && (
                  <img
                    src={quiz.perguntas[currentQuestion].imagemPergunta}
                    alt="Imagem da pergunta"
                    className={styles.questionImage}
                  />
                )}
              </div>
              <p className={styles.questionText}>{quiz.perguntas[currentQuestion].pergunta}</p>
              <ul className={styles.answerList}>
                {quiz.perguntas[currentQuestion].alternativas.map((alternative, index) => (
                  <motion.li
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleAnswerSelection(index)}
                    className={`${styles.answerItem} ${
                      selectedAnswer === index ? styles.selectedAnswer : ''
                    } ${answeredQuestions.includes(currentQuestion) ? styles.answeredQuestion : ''}`}
                  >
                    {alternative.alternativa}
                    {answeredQuestions.includes(currentQuestion) && (
                      <MdLock className={styles.lockedIcon} />
                    )}
                  </motion.li>
                ))}
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleNextQuestion}
                disabled={selectedAnswer === null || isSubmitting}
                className={`${styles.nextButton} ${
                  selectedAnswer !== null ? styles.nextButtonActive : ''
                }`}
              >
                {isSubmitting ? (
                  <div className={styles.buttonSpinner}></div>
                ) : currentQuestion === quiz.perguntas.length - 1 ? (
                  'Finalizar Quiz'
                ) : (
                  'Próxima Pergunta'
                )}
              </motion.button>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className={styles.quizCompletionContainer}
            >
              <h3 className={styles.quizCompletionTitle}>Quiz Concluído!</h3>
              <p className={styles.quizCompletionText}>
                Você acertou {score} de {quiz.perguntas.length} perguntas.
              </p>
              <p className={styles.quizReward}>
                Recompensa:
                <span className={styles.rewardPoints}>
                  <img src="/points.gif" alt="Pontos" />
                  {quiz.recompensa} pontos
                </span>
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={styles.closeButton}
                onClick={handleCloseQuiz}
              >
                <MdClose size={24} />
                <span>Fechar</span>
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Quizz;