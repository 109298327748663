import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import PageResetarSenhaEnviarEmailReset from "../components/page-resetar-senha-enviar-email-reset";
import PageResetarSenhaTrocarSenhaToken from "../components/page-resetar-senha-trocar-senha-token";
import "./resetar-senha.css";

const ResetarSenha = () => {
  const history = useHistory();
  const { token } = useParams();

  const handleSendResetPasswordLink = async (email) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DATABASE}/auth/send-email/password-reset`, {
        email,
      });
      toast.success(response.data.message);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Erro ao enviar o link de redefinição de senha.");
      }
    }
  };

  const handleResetPassword = async (token, newPassword) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DATABASE}/auth/reset-password`, {
        token,
        newPassword,
      });
      toast.success(response.data.message);
      history.push('/login')
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Erro ao redefinir senha.");
      }
    }
  };

  return (
    <div className="resetar-senha-container">
      <Helmet>
        <title>Resetar Senha - Body Dry</title>
        <meta property="og:title" content="Resetar-Senha - Body Dry" />
      </Helmet>
      <div className="resetar-senha-recuperarsenha">
        <img
          alt="L21141"
          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2be29237-57c8-44a6-a051-680d46410acb/12cbdd86-fa41-48f6-9883-32b8ec662a3f?org_if_sml=18065&amp;force_format=original"
          className="resetar-senha-l2"
        />
        {!token ? (
          <PageResetarSenhaEnviarEmailReset
            onSendResetPasswordLink={handleSendResetPasswordLink}
          />
        ) : (
          <PageResetarSenhaTrocarSenhaToken
            onResetPassword={handleResetPassword}
            token={token}
          />
        )}
      </div>
    </div>
  );
};

export default ResetarSenha;