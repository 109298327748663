import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './popup-editadd-aula.module.css';

const PopupNovoEditarAula = ({ isEditing, initialData, onSave, onCancel, courses, selectedCourse }) => {
  const [formData, setFormData] = useState({
    _id: initialData?._id || '',
    curso: initialData?.curso?._id || selectedCourse || '',
    nome: initialData?.nome || '',
    link: initialData?.link || '',
    descricao: initialData?.descricao || '',
    arquivos: initialData?.arquivos || [],
    arquivosToRemove: [],
  });
  const [newFiles, setNewFiles] = useState([]);

  useEffect(() => {
    if (isEditing && initialData) {
      setFormData({
        ...initialData,
        curso: initialData.curso._id,
        arquivosToRemove: [],
      });
    }
  }, [isEditing, initialData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'arquivos') {
      setNewFiles(Array.from(files));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveFile = (index, isNewFile = false) => {
    if (isNewFile) {
      setNewFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    } else {
      const fileToRemove = formData.arquivos[index];
      setFormData((prevData) => ({
        ...prevData,
        arquivos: prevData.arquivos.filter((_, i) => i !== index),
        arquivosToRemove: [...prevData.arquivosToRemove, fileToRemove],
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSave = {
      ...formData,
      newFiles,
      arquivosToRemove: formData.arquivosToRemove,
    };
    onSave(dataToSave);
  };

  return (
    <motion.div 
      className={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className={styles.modal}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
      >
        <h2 className={styles.title}>{isEditing ? 'Editar Aula' : 'Nova Aula'}</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="curso">Curso</label>
            <select
              id="curso"
              name="curso"
              value={formData.curso}
              onChange={handleChange}
              className={styles.input}
              required
            >
              <option value="">Selecione um curso</option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>{course.nome}</option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="nome">Nome da Aula</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="link">Link do Vídeo</label>
            <input
              type="text"
              id="link"
              name="link"
              value={formData.link}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="descricao">Descrição</label>
            <textarea
              id="descricao"
              name="descricao"
              value={formData.descricao}
              onChange={handleChange}
              className={styles.textarea}
              required
            ></textarea>
          </div>
          <div className={styles.formGroup}>
            <label>Arquivos</label>
            <div className={styles.fileUpload}>
              <input
                type="file"
                name="arquivos"
                multiple
                onChange={handleChange}
                className={styles.fileInput}
              />
              <span className={styles.fileIcon}>+</span>
            </div>
            <AnimatePresence>
              {formData.arquivos.map((file, index) => (
                <motion.div 
                  key={`existing-${index}`} 
                  className={styles.fileItem}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  <span>{file}</span>
                  <button
                    type="button"
                    className={styles.removeFile}
                    onClick={() => handleRemoveFile(index)}
                  >
                    &times;
                  </button>
                </motion.div>
              ))}
              {newFiles.map((file, index) => (
                <motion.div 
                  key={`new-${index}`} 
                  className={styles.fileItem}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  <span>{file.name}</span>
                  <button
                    type="button"
                    className={styles.removeFile}
                    onClick={() => handleRemoveFile(index, true)}
                  >
                    &times;
                  </button>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          <div className={styles.buttonGroup}>
            <motion.button 
              type="button" 
              className={styles.cancelButton} 
              onClick={onCancel}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Cancelar
            </motion.button>
            <motion.button 
              type="submit" 
              className={styles.saveButton}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isEditing ? 'Salvar' : 'Criar'}
            </motion.button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default PopupNovoEditarAula;