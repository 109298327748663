import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBell, FaQuestionCircle, FaStar, FaChevronDown } from "react-icons/fa";
import { MdLogout, MdPerson, MdMenu, MdClose, MdHome, MdPeople, MdAttachMoney, MdSchool, MdGroup, MdBuild, MdExtension } from "react-icons/md";
import { useUser } from "../states";
import axios from "axios";
import styles from "./header.module.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
  const { user, logOut } = useUser();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const location = useLocation();
  const headerRef = useRef(null);
  const notificationsRef = useRef(null);
  const userMenuRef = useRef(null);
  const toolsMenuRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useLayoutEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        const height = headerRef.current.offsetHeight;
        setHeaderHeight(height);
        document.body.style.paddingTop = `${height}px`;
      }
    };

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
      document.body.style.paddingTop = '0px';
    };
  }, []);

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 180000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setNotificationsOpen(false);
      }
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenuOpen(false);
      }
      if (toolsMenuRef.current && !toolsMenuRef.current.contains(event.target)) {
        setToolsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/notification`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNotifications(response.data.data);
      setUnreadCount(response.data.data.filter(n => !n.read).length);
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    }
  };

  const handleNotificationClick = async (notification) => {
    if (!notification.read) {
      try {
        const token = localStorage.getItem("token");
        await axios.put(`${process.env.REACT_APP_DATABASE}/notifications/${notification._id}/read`, {}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUnreadCount(prev => prev - 1);
        setNotifications(prev => prev.map(n => 
          n._id === notification._id ? { ...n, read: true } : n
        ));
      } catch (error) {
        console.error("Erro ao marcar notificação como lida:", error);
      }
    }
    if (notification.link) {
      window.location.href = notification.link;
    }
    setNotificationsOpen(false);
  };

  const getFirstAndLastName = (fullName) => {
    if (!fullName) return '';
    const names = fullName.split(" ");
    return `${names[0]} ${names[names.length - 1]}`;
  };

  const getUserInitials = (fullName) => {
    if (!fullName) return '';
    const names = fullName.split(" ");
    return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`.toUpperCase();
  };

  const isActiveLink = (path) => location.pathname === path;

  const navLinks = user?.type === "admin"
    ? [
        { to: "/", label: "Home", icon: <MdHome /> },
        { to: "/influenciadores", label: "Influenciadores", icon: <MdPeople /> },
        { to: "/comissoes", label: "Comissões", icon: <MdAttachMoney /> },
        { to: "/gerenciamento-academy", label: "Academy", icon: <MdSchool /> },
        { to: "/comunidade", label: "Comunidade", icon: <MdGroup /> },
      ]
    : [
        { to: "/", label: "Home", icon: <MdHome /> },
        { to: "/academy", label: "Academy", icon: <MdSchool /> },
        { to: "/comunidade", label: "Comunidade", icon: <MdGroup /> },
      ];

  return (
    <header 
      className={styles.header} 
      ref={headerRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}
    >
      <div className={styles.headerContent}>
          <Link to="/" className={styles.logoLink}>
            <img src="/external/l2i147-7ixe-200h.webp" alt="Logo" className={styles.logo} />
          </Link>

          <nav className={styles.desktopNav}
            style={user?.type !== 'admin' ? {
              marginRight: 'auto',
              marginLeft: '3rem'
            } : {}}
          >
            {navLinks.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className={`${styles.navLink} ${isActiveLink(link.to) ? styles.active : ""}`}
              >
                {link.icon}
                {link.label}
              </Link>
            ))}
            {user?.type === "admin" && (
              <div className={styles.toolsMenuContainer} ref={toolsMenuRef}>
                <button 
                  className={`${styles.navLink} ${styles.toolsMenuButton} ${toolsMenuOpen ? styles.active : ""}`}
                  onClick={() => setToolsMenuOpen(!toolsMenuOpen)}
                >
                  <MdBuild />
                  Ferramentas
                  <FaChevronDown className={`${styles.chevron} ${toolsMenuOpen ? styles.open : ''}`} />
                </button>
                {toolsMenuOpen && (
                  <div className={styles.toolsDropdownMenu}>
                    <Link to="/extrator" className={styles.toolsDropdownItem}>
                      <MdExtension />
                      Extrator
                    </Link>
                  </div>
                )}
              </div>
            )}
          </nav>
        <div className={styles.headerActions}>
          <div className={styles.options}>
            {user?.type !== "admin" && (
              <>
                <Link to="/ajuda" className={styles.iconLink} title="Ajuda">
                  <FaQuestionCircle />
                </Link>
                {!user.avaliouApp && (
                  <Link to="/avaliar" className={styles.iconLink} title="Avaliar">
                    <FaStar />
                  </Link>
                )}
              </>
            )}

            <div className={styles.notificationsContainer} ref={notificationsRef}>
              <button 
                className={styles.iconButton} 
                onClick={() => setNotificationsOpen(!notificationsOpen)}
                title="Notificações"
              >
                <FaBell />
                {unreadCount > 0 && <span className={styles.notificationBadge}>{unreadCount}</span>}
              </button>
              {notificationsOpen && (
                <div className={styles.dropdownMenu}>
                  <h3 className={styles.dropdownTitle}>Notificações</h3>
                  {notifications.length === 0 ? (
                    <p className={styles.noItems}>Nenhuma notificação</p>
                  ) : (
                    <div className={styles.notificationsList}>
                      {notifications.map((notification) => (
                        <div
                          key={notification._id}
                          className={`${styles.notificationItem} ${notification.read ? styles.read : styles.unread}`}
                          onClick={() => handleNotificationClick(notification)}
                        >
                          <p className={styles.notificationMessage}>{notification.message}</p>
                          <span className={styles.notificationTime}>
                            {new Date(notification.createdAt).toLocaleString()}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.userMenuContainer} ref={userMenuRef}>
            <button 
              className={styles.userMenuButton} 
              onClick={() => setUserMenuOpen(!userMenuOpen)}
              title="Menu do usuário"
            >
              {user?.profilePicture ? (
                <img src={user.profilePicture} alt="Avatar" className={styles.userAvatar} />
              ) : (
                <div className={styles.userInitials}>{getUserInitials(user?.name)}</div>
              )}
            </button>
            {userMenuOpen && (
              <div className={styles.dropdownMenu}>
                <Link to="/perfil" className={styles.dropdownItem}>
                  <MdPerson />
                  Perfil
                </Link>
                <button className={styles.dropdownItem} onClick={logOut}>
                  <MdLogout />
                  Desconectar
                </button>
              </div>
            )}
          </div>

          <button 
            className={styles.mobileMenuButton} 
            onClick={() => setMenuOpen(!menuOpen)}
            aria-label={menuOpen ? "Fechar menu" : "Abrir menu"}
          >
            {menuOpen ? <MdClose /> : <MdMenu />}
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className={styles.mobileMenu}>
          {navLinks.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className={`${styles.mobileNavLink} ${isActiveLink(link.to) ? styles.active : ""}`}
              onClick={() => setMenuOpen(false)}
            >
              {link.icon}
              {link.label}
            </Link>
          ))}
          {user?.type === "admin" && (
            <div className={styles.mobileToolsMenu}>
              <button 
                className={`${styles.mobileToolsMenuButton} ${toolsMenuOpen ? styles.active : ""}`}
                onClick={() => setToolsMenuOpen(!toolsMenuOpen)}
              >
                <MdBuild />
                Ferramentas
                <FaChevronDown className={`${styles.chevron} ${toolsMenuOpen ? styles.open : ''}`} />
              </button>
              {toolsMenuOpen && (
                <div className={styles.mobileToolsDropdownMenu}>
                  <Link 
                    to="/extrator" 
                    className={styles.mobileToolsDropdownItem}
                    onClick={() => {
                      setToolsMenuOpen(false);
                      setMenuOpen(false);
                    }}
                  >
                    <MdExtension />
                    Extrator
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;