import React, { useState } from "react";
import "./page-resetar-senha-enviar-email-reset.css";

const PageResetarSenhaEnviarEmailReset = ({ onSendResetPasswordLink }) => {
  const [email, setEmail] = useState("");

  return (
    <div className="page-resetar-senha-enviar-email-reset-enviar-email-reset">
      <div className="page-resetar-senha-enviar-email-reset-frame347">
        <span className="page-resetar-senha-enviar-email-reset-text HeaderH1-24Medium">
          <span>Recuperar senha</span>
        </span>
        <span className="page-resetar-senha-enviar-email-reset-text2 TextMedium-16Regular">
          <span>
            Digite o endereço de e-mail que você usa para acessar a Body Dry
            Nutrition e enviaremos um link de redefinição de senha
          </span>
        </span>
      </div>
      <div className="page-resetar-senha-enviar-email-reset-textfields">
        <span className="page-resetar-senha-enviar-email-reset-text4 TextSmall-14Regular">
          <span>E-mail</span>
        </span>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Insira seu e-mail de cadastro"
          className="input"
        />
      </div>
      <button
        onClick={() => {
          onSendResetPasswordLink(email);
          setEmail("");
        }}
        className="page-resetar-senha-enviar-email-reset-buttons"
      >
        <span className="page-resetar-senha-enviar-email-reset-text6 TextMedium-16Medium">
          <span>Enviar</span>
        </span>
      </button>
    </div>
  );
};

export default PageResetarSenhaEnviarEmailReset;