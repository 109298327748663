import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './popup-editadd-quizz.module.css';

const PopupNovoEditarQuiz = ({ isEditing, initialData, onSave, onCancel, courses, selectedCourse }) => {
  const [quiz, setQuiz] = useState(initialData || {
    curso: selectedCourse || '',
    tempo: '',
    recompensa: '',
    perguntas: []
  });

  useEffect(() => {
    if (isEditing && initialData) {
      setQuiz({
        ...initialData,
        curso: initialData.curso._id
      });
    }
  }, [isEditing, initialData]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setQuiz(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleQuestionChange = useCallback((index, field, value) => {
    setQuiz(prev => {
      const updatedQuestions = [...prev.perguntas];
      updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
      return { ...prev, perguntas: updatedQuestions };
    });
  }, []);

  const handleAlternativeChange = useCallback((questionIndex, alternativeIndex, value) => {
    setQuiz(prev => {
      const updatedQuestions = [...prev.perguntas];
      updatedQuestions[questionIndex].alternativas[alternativeIndex].alternativa = value;
      return { ...prev, perguntas: updatedQuestions };
    });
  }, []);

  const handleCorrectAlternative = useCallback((questionIndex, alternativeIndex) => {
    setQuiz(prev => {
      const updatedQuestions = [...prev.perguntas];
      updatedQuestions[questionIndex].alternativas.forEach((alt, index) => {
        alt.correta = index === alternativeIndex;
      });
      return { ...prev, perguntas: updatedQuestions };
    });
  }, []);

  const addQuestion = useCallback(() => {
    setQuiz(prev => ({
      ...prev,
      perguntas: [...prev.perguntas, {
        pergunta: '',
        imagem_pergunta: null,
        posicao: prev.perguntas.length,
        alternativas: Array(5).fill().map(() => ({ alternativa: '', correta: false }))
      }]
    }));
  }, []);

  const removeQuestion = useCallback((index) => {
    setQuiz(prev => ({
      ...prev,
      perguntas: prev.perguntas.filter((_, i) => i !== index)
    }));
  }, []);

  const handleImageUpload = useCallback((questionIndex, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleQuestionChange(questionIndex, 'imagem_pergunta', reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, [handleQuestionChange]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    setQuiz(prev => {
      const questions = Array.from(prev.perguntas);
      const [reorderedItem] = questions.splice(result.source.index, 1);
      questions.splice(result.destination.index, 0, reorderedItem);
      
      return {
        ...prev,
        perguntas: questions.map((question, index) => ({
          ...question,
          posicao: index
        }))
      };
    });
  }, []);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedQuiz = {
      ...quiz,
      perguntas: quiz.perguntas.map((pergunta, index) => ({
        ...pergunta,
        posicao: index
      }))
    };
    onSave(updatedQuiz);
  };
  
  
  return (
    <motion.div 
      className={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className={styles.modal}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
      >
        <h2 className={styles.title}>{isEditing ? 'Editar Quiz' : 'Novo Quiz'}</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.grid}>
            <div className={styles.formGroup}>
              <label htmlFor="curso">Curso</label>
              <select id="curso" name="curso" value={quiz.curso} onChange={handleInputChange} required>
                <option value="">Selecione um curso</option>
                {courses.map((course) => (
                  <option key={course._id} value={course._id}>{course.nome}</option>
                ))}
              </select>
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="tempo">Tempo (minutos)</label>
              <input type="number" id="tempo" name="tempo" value={quiz.tempo} onChange={handleInputChange} required />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="recompensa">Recompensa</label>
              <input type="number" id="recompensa" name="recompensa" value={quiz.recompensa} onChange={handleInputChange} required />
            </div>
          </div>

          <h3 className={styles.subtitle}>Perguntas</h3>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="perguntas">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={styles.questionList}>
                  {quiz.perguntas.map((pergunta, index) => (
                    <Draggable key={index} draggableId={`pergunta-${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`${styles.questionCard} ${snapshot.isDragging ? styles.dragging : ''}`}
                        >
                          <div className={styles.questionHeader} {...provided.dragHandleProps}>
                            <h4 className={styles.questionTitle}>Pergunta {index + 1}</h4>
                            <button type="button" onClick={() => removeQuestion(index)} className={styles.removeBtn}>
                              <span className={styles.removeIcon}>×</span>
                            </button>
                          </div>
                          <input
                            type="text"
                            value={pergunta.pergunta}
                            onChange={(e) => handleQuestionChange(index, 'pergunta', e.target.value)}
                            placeholder="Digite a pergunta"
                            className={styles.questionInput}
                            required
                          />
                          <div className={styles.imageUpload}>
                            <label htmlFor={`imagem-pergunta-${index}`} className={styles.imageLabel}>
                              {pergunta.imagem_pergunta ? 'Trocar imagem' : 'Adicionar imagem'}
                            </label>
                            <input
                              type="file"
                              id={`imagem-pergunta-${index}`}
                              accept="image/*"
                              onChange={(e) => handleImageUpload(index, e)}
                              className={styles.imageInput}
                            />
                          </div>
                          {pergunta.imagem_pergunta && (
                            <div className={styles.imagePreviewContainer}>
                              <img src={pergunta.imagem_pergunta} alt={`Imagem da pergunta ${index + 1}`} className={styles.imagePreview} />
                            </div>
                          )}
                          <h5 className={styles.alternativesTitle}>Alternativas</h5>
                          {pergunta.alternativas.map((alternativa, altIndex) => (
                            <div key={altIndex} className={styles.alternativeItem}>
                              <input
                                type="text"
                                value={alternativa.alternativa}
                                onChange={(e) => handleAlternativeChange(index, altIndex, e.target.value)}
                                placeholder={`Alternativa ${altIndex + 1}`}
                                className={styles.alternativeInput}
                                required
                              />
                              <label className={styles.checkboxLabel}>
                                <input
                                  type="checkbox"
                                  checked={alternativa.correta}
                                  onChange={() => handleCorrectAlternative(index, altIndex)}
                                  className={styles.checkboxInput}
                                />
                                <span className={styles.checkboxCustom}></span>
                                Correta
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <motion.button 
            type="button" 
            onClick={addQuestion} 
            className={styles.addQuestionBtn}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className={styles.addIcon}>+</span> Adicionar pergunta
          </motion.button>
          <div className={styles.buttonGroup}>
            <motion.button 
              type="button" 
              onClick={onCancel} 
              className={styles.cancelBtn}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Cancelar
            </motion.button>
            <motion.button 
              type="submit" 
              className={styles.saveBtn}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {isEditing ? 'Salvar alterações' : 'Criar quiz'}
            </motion.button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default PopupNovoEditarQuiz;