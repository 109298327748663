import React, { useState } from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaArchive, FaArrowRight, FaWhatsapp, FaTiktok, FaInstagram } from 'react-icons/fa';
import styles from './InfluencerCard.module.css';
import { moveInfluencerToNextStage, archiveInfluencer } from './api';
import { useAction } from '../../states';

const InfluencerCard = ({ influencer, activeTab, onUpdate }) => {
  const { _id, name, profilePicture, instagram, tiktok, createdAt, keywords = [], txEngajamento } = influencer;
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addAction } = useAction();

  const formatRelativeTime = (dateString) => {
    if (!dateString) return 'Data desconhecida';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) throw new Error('Data inválida');
      const now = new Date();
      const diffInSeconds = Math.floor((now - date) / 1000);
      if (diffInSeconds < 60) return 'Agora';
      if (diffInSeconds < 86400) return formatDistanceToNow(date, { addSuffix: true, locale: ptBR });
      return format(date, "d 'de' MMMM 'de' yyyy", { locale: ptBR });
    } catch (error) {
      console.error('Erro ao formatar data:', error);
      return 'Data inválida';
    }
  };

  const handleArchive = async () => {
    setIsLoading(true);
    try {
      const response = await archiveInfluencer(_id);
      addAction(activeTab, { actionLogId: response.actionLogId, type: 'archived' });
      onUpdate();
    } catch (error) {
      console.error('Error archiving influencer:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMoveToNextStage = async () => {
    setIsLoading(true);
    try {
      const response = await moveInfluencerToNextStage(_id);
      addAction(activeTab, { actionLogId: response.actionLogId, type: 'status_changed' });
      onUpdate();
    } catch (error) {
      console.error('Error moving influencer to next stage:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopySignupLink = () => {
    const signupLink = `https://seuapp.com/cadastro/${_id}`;
    navigator.clipboard.writeText(signupLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const renderActionButton = () => {
    if (activeTab !== 'foi_para_whatsapp') {
      return (
        <button 
          onClick={handleMoveToNextStage} 
          className={styles.actionButton} 
          disabled={isLoading}
        >
          <FaArrowRight />
        </button>
      );
    }
    return null;
  };

  const cardStyle = tiktok ? styles.tiktokStyle : styles.instagramStyle;
  const profileUrl = tiktok || instagram;
  const socialIcon = tiktok ? <FaTiktok className={styles.socialIcon} /> : <FaInstagram className={styles.socialIcon} />;

  return (
    <div className={`${styles.influencerCard} ${cardStyle}`}>
      <div className={styles.cardHeader}>
        {profilePicture ? (
          <img src={profilePicture} alt={name} className={styles.profilePicture} />
        ) : (
          <div className={styles.profileAcronym}>{name.split(' ').map(n => n[0]).join('').slice(0, 2).toUpperCase()}</div>
        )}
        <div className={styles.headerInfo}>
          <h3 className={styles.userName}>{name}</h3>
          <p className={styles.extractionDate}>{formatRelativeTime(createdAt)}</p>
        </div>
        {socialIcon}
        <button onClick={handleArchive} className={styles.archiveButton} disabled={isLoading}>
          <FaArchive />
        </button>
      </div>
      
      <div className={styles.engagementRate}>
        <span className={styles.engagementLabel}>Taxa de Engajamento:</span>
        <span className={styles.engagementValue}>{txEngajamento}%</span>
      </div>
      
      <div className={styles.keywordsSection}>
        {keywords.slice(0, 3).map((keyword, index) => (
          <span key={index} className={styles.keyword}>{keyword}</span>
        ))}
        {keywords.length > 3 && (
          <span className={styles.keywordMore}>+{keywords.length - 3}</span>
        )}
      </div>
      
      <div className={styles.actionSection}>
        <a href={profileUrl} target="_blank" rel="noopener noreferrer" className={styles.profileButton}>
          Ver Perfil <FaArrowRight />
        </a>
        {renderActionButton()}
      </div>
      
      {activeTab === 'foi_para_whatsapp' && (
        <div className={styles.signupLinkSection} onClick={handleCopySignupLink}>
          <span className={styles.signupLinkLabel}>Link de Cadastro:</span>
          <span className={`${styles.signupLink} ${isCopied ? styles.copied : ''}`}>
            {isCopied ? 'Copiado!' : `https://influenciadores.bodydrynutrition.com.br/cadastro/${_id}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default InfluencerCard;