export function calcularGanhosMensais(visualizacoesStories, visualizacoesReels, influencia, recomendacoes, conteudosPorMes) {
  // Ticket médio e deduções
  const ticketMedio = 150;
  const imposto = 0.06;
  const custoGateway = 0.11;
  const faturamentoBrutoPorVenda = ticketMedio * (1 - imposto - custoGateway);

  // Comissões por influência em Stories e Reels
  const comissoes = {
      stories: [1.5, 2.5, 4], // Percentual de comissão
      reels: [0.05, 0.08, 0.1] // Percentual de comissão
  };
  const comissaoStories = comissoes.stories[influencia - 1] / 100;
  const comissaoReels = comissoes.reels[influencia - 1] / 100;

  // Investimento em tráfego pago e CPA
  let investimento, cpa;
  if (conteudosPorMes <= 1) {
      investimento = 250;
      cpa = 25;
  } else if (conteudosPorMes <= 4) {
      investimento = 500;
      cpa = 19;
  } else {
      investimento = 750;
      cpa = 15;
  }
  const vendasPago = investimento / cpa;
  const faturamentoLiquidoPago = vendasPago * faturamentoBrutoPorVenda - investimento; // Deduzindo o custo de investimento do faturamento bruto

  // Calculando as vendas orgânicas baseadas em visualizações e comissão
  const vendasOrgStories = visualizacoesStories * comissaoStories;
  const vendasOrgReels = visualizacoesReels * comissaoReels;
  const faturamentoLiquidoOrg = (vendasOrgStories + vendasOrgReels) * faturamentoBrutoPorVenda;

  // Cálculo dos ganhos
  const ganhosOrg = faturamentoLiquidoOrg * 0.20; // 20% de comissão sobre o faturamento líquido de vendas orgânicas
  const ganhosPago = faturamentoLiquidoPago * 0.08; // 8% de comissão sobre o faturamento líquido de tráfego pago
  const ganhosIndicacao = recomendacoes * faturamentoLiquidoOrg * 0.04; // 4% de comissão sobre o faturamento líquido gerado pelos indicados

  // Ganhos totais
  const ganhosTotais = ganhosOrg + ganhosPago + ganhosIndicacao;

  return `Seus ganhos totais estimados são de R$ ${ganhosTotais.toFixed(2).replace(".", ",")}`
}