import React from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import styles from './SearchBar.module.css';

const SearchBar = ({ searchTerm, onSearchChange }) => {
  return (
    <div className={styles.searchContainer}>
      <input
        type="text"
        placeholder="Pesquisar por cupom, username, nome ou email"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        className={styles.searchInput}
      />
      <IoSearchOutline className={styles.searchIcon} />
    </div>
  );
};

export default SearchBar;