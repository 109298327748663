import React from 'react';
import './popup-excluir-academy.css';

const PopupExcluir = ({ type, data, onClose, onConfirm }) => {
  const getTitle = () => {
    switch (type) {
      case 'excluir-aula':
        return 'Excluir aula';
      case 'excluir-curso':
        return 'Excluir curso';
      case 'excluir-quiz':
        return 'Excluir quiz';
      default:
        return 'Excluir item';
    }
  };

  const getMessage = () => {
    if (!data) return 'Você tem certeza que deseja excluir este item?';

    switch (type) {
      case 'excluir-aula':
        return `Você tem certeza que deseja excluir a aula "${data.nome}"?`;
      case 'excluir-curso':
        return `Você tem certeza que deseja excluir o curso "${data.nome}"?`;
      case 'excluir-quiz':
        return data.curso && data.curso.nome
          ? `Você tem certeza que deseja excluir o quiz do curso "${data.curso.nome}"?`
          : 'Você tem certeza que deseja excluir este quiz?';
      default:
        return 'Você tem certeza que deseja excluir este item?';
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <h2>{getTitle()}</h2>
        <div className="popup-excluir-content">
          <p className="popup-excluir-message">{getMessage()}</p>
          <p className="popup-excluir-description">Essa ação não poderá ser desfeita.</p>
        </div>
        <div className="button-group">
          <button className="cancel-button" onClick={onClose}>
            Cancelar
          </button>
          <button className="delete-button" onClick={() => onConfirm(data?._id, type.split('-')[1])}>
            Excluir
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupExcluir;