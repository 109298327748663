import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./home-ranking.css";

const Ranking = (props) => {
  const [ranks, setRanks] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [activeTab, setActiveTab] = useState("geral");

  const fetchRanking = async (type) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATABASE}/user/ranking`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: { type },
        }
      );
      setRanks(response.data.data.ranking);
      setUserRank(response.data.data.userRank);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRanking(activeTab);
  }, [activeTab]);

  function getAcronym(name) {
    if (!name) return "";
    const nameParts = name.trim().split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    }
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }

  return (
    <div className="ranking__container">
      <div className="ranking__content">
        <div className="ranking__filters">
          {["geral", "mensal", "semanal"].map((tab) => (
            <div
              key={tab}
              className={`ranking__filter ${activeTab === tab ? "ranking__filter--active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </div>
          ))}
        </div>
        <div className="ranking__top-three">
          {[ranks[1], ranks[0], ranks[2]].map((rank, idx) => (
            <div key={idx} className={`ranking__top-item ranking__top-item--${idx + 1}`}>
              <div className="ranking__photo">
                <img
                  alt="moldura"
                  src={`/${idx === 1 ? "gold" : idx === 0 ? "silver" : "bronze"}.png`}
                  className="ranking__frame"
                />
                {rank?.profilePicture ? (
                  <img
                    alt="perfil"
                    src={rank.profilePicture}
                    className="ranking__profile-pic"
                  />
                ) : (
                  <div className="ranking__acronym">
                    {getAcronym(rank?.name)}
                  </div>
                )}
              </div>
              <span className="ranking__name">{rank?.name}</span>
              <span className="ranking__points">
                <img className="points-gif" src="./points.gif" /> {rank?.totalPoints}
              </span>
            </div>
          ))}
        </div>
        <div className="ranking__list">
          {ranks.slice(3).map((rank, idx) => (
            <div
              key={idx}
              className={`ranking__list-item`}
            >
              <div className="ranking__index">{idx + 4}</div>
              <div className="ranking__list-content">
                {rank?.profilePicture ? (
                  <img
                    alt="perfil"
                    src={rank.profilePicture}
                    className="ranking__profile-pic ranking__profile-pic--small"
                  />
                ) : (
                  <div className="ranking__acronym--small">
                    {getAcronym(rank?.name)}
                  </div>
                )}
                <div className="ranking__details">
                  <span className="ranking__name">{rank?.name}</span>
                  <span className="ranking__points">
                    <img className="points-gif" src="./points.gif" /> {rank?.totalPoints}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {userRank && (
            <div className="ranking__list-item ranking__list-item--user">
              <div className="ranking__index">{userRank.position}</div>
              <div className="ranking__list-content">
                {userRank?.profilePicture ? (
                  <img
                    alt="perfil"
                    src={userRank.profilePicture}
                    className="ranking__profile-pic ranking__profile-pic--small"
                  />
                ) : (
                  <div className="ranking__acronym--small">
                    {getAcronym(userRank?.name)}
                  </div>
                )}
                <div className="ranking__details">
                  <span className="ranking__name">{userRank?.name}</span>
                  <span className="ranking__points">
                    <img className="points-gif" src="./points.gif" /> {userRank?.totalPoints}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Ranking.propTypes = {
  awardstarSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  awardstarAlt: PropTypes.string,
};

export default Ranking;