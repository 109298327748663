import React, { useEffect, useState } from "react";
import { MdStar, MdAttachMoney, MdTrendingUp, MdClose } from "react-icons/md";
import { FaGraduationCap, FaBookOpen, FaUnlock, FaStar, FaUsers, FaTrophy, FaGift, FaBook } from "react-icons/fa";
import { useUser } from "../states";
import { Link } from "react-router-dom";
import axios from "axios";
import "./home-estatisticas.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { toast } from 'react-toastify';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HomeEstatisticas = ({ selectedDate }) => {
  const { user } = useUser();
  const [dashboardData, setDashboardData] = useState({
    commissions: {
      organic: 0,
      ads: 0,
      referral: 0
    },
    indications: 0
  });
  const [showPopup, setShowPopup] = useState(false);
  const [eligiblePoints, setEligiblePoints] = useState(0);
  const [potentialVoucherValue, setPotentialVoucherValue] = useState(0);
  const [isGeneratingVoucher, setIsGeneratingVoucher] = useState(false);
  const [generatedVoucher, setGeneratedVoucher] = useState(null);
  const [lastVoucher, setLastVoucher] = useState(null);
  const [showMonthlyVoucherPopup, setShowMonthlyVoucherPopup] = useState(false);

  const handleGenerateMonthlyVoucher = async () => {
    setIsGeneratingVoucher(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DATABASE}/voucher/generate-monthly`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setGeneratedVoucher(response.data.voucher);
      setShowMonthlyVoucherPopup(false);
      setShowPopup(true);
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || "Erro ao gerar voucher mensal");
    } finally {
      setIsGeneratingVoucher(false);
    }
  };

  const chartData = {
    labels: ["Orgânico", "Ads", "Indicação"],
    datasets: [
      {
        label: "Comissão (R$)",
        data: [
          dashboardData.commissions.organic,
          dashboardData.commissions.ads,
          dashboardData.commissions.referral,
        ],
        backgroundColor: ["#8884d8", "#82ca9d", "#ffc658"],
        borderColor: ["#625dba", "#4bb874", "#ffbc2b"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return "R$ " + value.toLocaleString();
          },
        },
      },
    },
  };

  const fetchLastVoucher = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DATABASE}/voucher/last`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success) {
        setLastVoucher(response.data.voucher);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATABASE}/user/dashboard`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              month: selectedDate.getMonth() + 1,
              year: selectedDate.getFullYear(),
            },
          }
        );
        setDashboardData(response.data.data);
      } catch (err) {
        console.error(err);
        toast.error("Erro ao carregar dados do dashboard");
      }
    };

    const fetchEligiblePoints = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATABASE}/voucher/eligible-points`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setEligiblePoints(response.data.eligiblePoints);
        setPotentialVoucherValue(response.data.potentialVoucherValue);
      } catch (err) {
        console.error(err);
        toast.error("Erro ao carregar pontos elegíveis");
      }
    };

    fetchDashboardData();
    fetchEligiblePoints();
    fetchLastVoucher();
  }, [selectedDate]);

  const togglePopup = () => setShowPopup((prev) => !prev);

  const handleGenerateVoucher = async () => {
    setIsGeneratingVoucher(true);
    try {
      if (lastVoucher) {
        setGeneratedVoucher(lastVoucher);
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_DATABASE}/voucher/generate`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setGeneratedVoucher(response.data.voucher);
        await fetchLastVoucher();
      }
      setShowPopup(true);
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || "Erro ao gerar voucher");
    } finally {
      setIsGeneratingVoucher(false);
    }
  };

  const copyVoucherToClipboard = () => {
    navigator.clipboard.writeText(generatedVoucher.code);
    toast.success("Código do voucher copiado com sucesso!");
    setShowPopup(false);
  };

  const MonthlyVoucherPopup = ({ onClose, onGenerate, isLoading }) => {

    const handleGenerateVoucher = async () => {
      try {
        await onGenerate();
      } catch(err) {
        console.log(err);
      }
    };

    return (
      <div className="monthly-voucher-popup">
        <div className="monthly-voucher-popup-content">
          <button className="monthly-voucher-popup-close" onClick={onClose}>
            <MdClose size={24} />
          </button>
          <h2 className="monthly-voucher-popup-title">Voucher Exclusivo 50% OFF</h2>
          <div className="monthly-voucher-info">
            <FaGift className="monthly-voucher-icon" />
            <p>
              Aproveite esta oportunidade exclusiva para adquirir produtos Body Dry com 50% de desconto!
            </p>
          </div>
          <div className="monthly-voucher-details">
            <h3>Detalhes importantes:</h3>
            <ul>
              <li>Este voucher é pessoal e intransferível.</li>
              <li>Produtos destinados apenas para divulgação, não para revenda.</li>
              <li>O compartilhamento do cupom pode resultar em penalidades.</li>
            </ul>
          </div>
          <div className="monthly-voucher-instructions">
            <h3>Como usar:</h3>
            <ol>
              <li>Clique em "Gerar Voucher" abaixo.</li>
              <li>Copie o código gerado.</li>
              <li>Visite www.bodydrynutrition.com.br e faça suas compras.</li>
              <li>Aplique o código no checkout para obter 50% de desconto.</li>
            </ol>
          </div>
          <button className="generate-monthly-voucher-button" disabled={isLoading} onClick={handleGenerateVoucher}>
            {isLoading ? (
              <span className="loader"></span>
            ) : (
              'Gerar Voucher'
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="home-estatisticas">
      <div className="statistics">
        <div className="card card-comissoes">
          <div className="card-header">
            <MdAttachMoney />
            <h2>
              Comissões -{" "}
              {new Date(selectedDate).toLocaleString("pt-BR", {
                month: "long",
                year: "numeric",
              })}
            </h2>
          </div>
          <div className="card-content">
            <Bar data={chartData} options={chartOptions} />
            <div className="comission-details">
              {Object.entries(dashboardData.commissions).map(
                ([key, value]) => (
                  <div key={key} className="comission-item">
                    <h3>
                      Comissão{" "}
                      {key === "organic"
                        ? "Orgânico"
                        : key === "ads"
                        ? "Tráfego pago"
                        : "Indicações"}
                    </h3>
                    <p>
                      {value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </p>
                  </div>
                )
              )}
            </div>
            <Link to="/vendas" className="view-all-button">
              Ver histórico completo
            </Link>
          </div>
        </div>
        <div className="card card-meta">
          <div className="card-header">
            <FaGift />
            <h2>Troque Pontos por Produtos</h2>
          </div>
          <div className="card-content">
            <div className="goal-progress">
              <div
                className="goal-progress-bar"
                style={{
                  "--progress": `${(eligiblePoints / 160) * 100}%`,
                }}
              >
                <div className="progress"></div>
              </div>
              <div className="goal-progress-label">
                <img
                  src="./points.gif"
                  alt="Pontos"
                  className="points-icon"
                />
                <span>{eligiblePoints} / 160 pontos</span>
              </div>
            </div>
            <div>
            {eligiblePoints < 160 && !lastVoucher ? (
              <>
                <p>
                  Faltam {160 - eligiblePoints} pontos para você poder gerar um voucher em compras de produtos BodyDry Nutrition®. A cada ponto acumulado, você ganha R$ 2,50 em produtos.
                </p>
                <button className="info-button" onClick={togglePopup}>
                  Como ganhar pontos?
                </button>
              </>
            ) : (
              <>
                <p>
                  {lastVoucher 
                    ? `Você já gerou um voucher ainda não utilizado no valor de ${lastVoucher.code.startsWith("BODYDRY50OFF") ? `50% OFF limitado à R$ 400,00` : `R$ ${lastVoucher.value.toFixed(2).replace(".",",")}`}`
                    : `Você já pode gerar um voucher de compras de produtos BodyDry Nutrition® no valor de R$ ${potentialVoucherValue.toFixed(2).replace(".",",")}`
                  }
                </p>
                <button 
                  className="generate-voucher-button" 
                  onClick={handleGenerateVoucher}
                  disabled={isGeneratingVoucher && !showMonthlyVoucherPopup}
                >
                  {isGeneratingVoucher && !showMonthlyVoucherPopup ? "Carregando..." : (lastVoucher ? "Ver voucher" : "Gerar voucher")}
                </button>
              </>
            )}
            {eligiblePoints < 160 && !lastVoucher && (
              <button className="generate-monthly-voucher-button" onClick={() => setShowMonthlyVoucherPopup(true)}>
                Gerar Voucher 50% OFF
              </button>
            )}
            </div>
            {showMonthlyVoucherPopup && (
              <MonthlyVoucherPopup
                onClose={() => setShowMonthlyVoucherPopup(false)}
                onGenerate={handleGenerateMonthlyVoucher}
                isLoading={isGeneratingVoucher}
              />
            )}
            {showPopup && !generatedVoucher && (
              <div className="gamification-popup">
                <div className="gamification-popup-content">
                  <button
                    className="gamification-popup-close"
                    onClick={() => setShowPopup(false)}
                  >
                    <MdClose size={24} />
                  </button>
                  <>
                      <h2 className="gamification-popup-title">Acumule pontos e ganhe prêmios!</h2>
                      <p className="gamification-popup-description">
                        A cada meta de pontos que você atinge, você fica mais perto de ficar entre os melhores no ranking e ganhar os maiores prêmios. Confira as missões e quantos
                        pontos você pode ganhar com cada uma:
                      </p>
                      <div className="gamification-grid">
                        {[
                          { icon: MdAttachMoney, title: 'Venda orgânica', points: 0.10 },
                          { icon: MdAttachMoney, title: 'Venda por tráfego pago', points: 0.04 },
                          { icon: FaTrophy, title: 'Post destacado', points: 50 },
                          { icon: FaUsers, title: 'Indicação aprovada', points: 10 },
                          { icon: FaUsers, title: 'Venda do seu indicado', points: 0.02 },
                          { icon: MdStar, title: 'Avaliar o app', points: 5 },
                        ].map(({ icon: Icon, title, points }) => (
                          <div key={title} className="gamification-card">
                            <div className="gamification-card-icon">
                              <Icon size={32} />
                            </div>
                            <h4 className="gamification-card-title">{title}</h4>
                            <p className="gamification-card-points">+{points} pontos</p>
                            {['Venda orgânica', 'Venda por tráfego pago', 'Venda do seu indicado'].includes(title) && (
                              <small>A cada R$ 1,00 vendido</small>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="academy-quiz-section">
                        <h3 className="academy-quiz-title">
                          <FaGraduationCap className="academy-quiz-title-icon" />
                          Potencialize seus pontos com quizzes do Academy
                        </h3>
                        <div className="academy-quiz-content">
                          <div className="academy-quiz-steps">
                            <div className="academy-quiz-step">
                              <div className="step-icon">
                                <FaBookOpen />
                              </div>
                              <p>Complete um curso</p>
                            </div>
                            <div className="academy-quiz-step">
                              <div className="step-icon">
                                <FaUnlock />
                              </div>
                              <p>Desbloqueie o quiz</p>
                            </div>
                            <div className="academy-quiz-step">
                              <div className="step-icon">
                                <FaStar />
                              </div>
                              <p>Ganhe pontos extras</p>
                            </div>
                          </div>
                          <div className="academy-quiz-example">
                            <div className="quiz-progress-container">
                              <div className="quiz-progress-bar">
                                <div className="quiz-progress" style={{ width: '75%' }}></div>
                              </div>
                              <div className="quiz-progress-labels">
                                <span>0%</span>
                                <span>100%</span>
                              </div>
                            </div>
                            <p className="quiz-example-text">Exemplo: 75% de acertos = 75% dos pontos do quiz</p>
                          </div>
                        </div>
                      </div>
                    </>
                </div>
              </div>
            )}

            {showPopup && generatedVoucher && (
              <div className="voucher-popup">
                <div className="voucher-popup-content">
                  <button
                    className="voucher-popup-close"
                    onClick={() => setShowPopup(false)}
                  >
                    <MdClose size={24} />
                  </button>
                  <h2 className="voucher-popup-title">Seu Voucher</h2>
                  <p>Código: <span onClick={copyVoucherToClipboard} style={{cursor: 'pointer', fontWeight: 'bold'}}>{generatedVoucher.code}</span></p>
                  <p>Valor: {generatedVoucher.code.startsWith("BODYDRY50OFF") ? `50% OFF` : `R$ ${generatedVoucher.value.toFixed(2)}`}</p>
                  <button className="voucher-copy-button" onClick={copyVoucherToClipboard}>Copiar Código</button>
                </div>
              </div>
            )}

          </div>
        </div>
        <div className="card card-cupons">
          <div className="card-header">
            <MdAttachMoney />
            <h2>Seus Cupons de Desconto</h2>
          </div>
          <div className="card-content coupons">
            <div className="coupon-item">
              <h3>Cupom Orgânico</h3>
              <p>{user?.couponOrg}</p>
            </div>
            <div className="coupon-item">
              <h3>Cupom Tráfego Pago</h3>
              <p>{user?.couponTP}</p>
            </div>
          </div>
        </div>
        <div className="card card-academy">
          <div className="card-header">
            <FaBook />
            <h2>Academy</h2>
          </div>
          <div className="card-content academy">
            <p>
              Estude na Academy para encontrar as melhores formas de vender os
              produtos da marca e aumentar suas comissões.
            </p>
            <Link to="/academy" className="view-courses-button">
              Ver cursos
            </Link>
          </div>
        </div>
        <div className="card card-indicacoes">
          <div className="card-header">
            <FaUsers />
            <h2>Indicações</h2>
          </div>
          <div className="card-content indication">
            <div className="indication-count">
              <FaTrophy />
              <p>{dashboardData.indications} Indicações</p>
            </div>
            <Link to="/indicacoes" className="view-all-button">
              Ver todos os indicados
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeEstatisticas;