import React, { useState } from "react";
import "./accordion.css";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <button onClick={() => setIsActive(!isActive)} class="accordion">
        {title} {isActive ? <span>-</span> : <span>+</span>}
      </button>
      {isActive && (
        <div class="panel">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
