import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import "./perfil-form-dados-bancarios.css";

const PerfilFormDadosBancarios = (props) => {
  const [open, setOpen] = useState(false);
  const [bankSearchQuery, setBankSearchQuery] = useState("");
  const [bankSearchResults, setBankSearchResults] = useState([]);
  const [shouldSearch, setShouldSearch] = useState(false);

  const formData = props.formData;

  const [data, setData] = useState({
    pixKey: formData?.pixKey,
    bankAccount: formData?.bankAccount,
    bankAgency: formData?.bankAgency,
    bankID: formData?.bankID,
  });

  useEffect(() => {
    if (formData?.bankName) {
      setBankSearchQuery(formData.bankName);
    }
  }, [formData?.bankName]);
  
  const searchByBankName = async () => {
    if (bankSearchQuery.length > 2) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DATABASE}/search/banks?name=${bankSearchQuery}`
        );
        setBankSearchResults(response.data.banks);
      } catch (error) {
        toast.error("Nenhum banco encontrado.");
        console.error("Error searching banks:", error);
        setBankSearchResults([]);
      }
    } else {
      setBankSearchResults([]);
    }
  };
  

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (bankSearchQuery && shouldSearch) {
        searchByBankName();
      }
      setShouldSearch(false);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [bankSearchQuery, shouldSearch]);

  useEffect(() => {
    if(props.formData && !data.bankID) {
      setData({
        pixKey: formData?.pixKey,
        bankAccount: formData?.bankAccount,
        bankAgency: formData?.bankAgency,
        bankID: formData?.bankID,
      })
    } 
  },[props.formData])

  const handleSelectBank = (bank) => {
    setBankSearchQuery(bank.bankName);
    setShouldSearch(false);
    setBankSearchResults([]);
    setData({
      ...data,
      bankID: bank._id,
    })
  };

  const handleFocus = () => {
    setBankSearchQuery("");
    setBankSearchResults([]);
    setShouldSearch(true);
    setData({
      ...data,
      bankID: "",
    })
  };

  return (
    <div className="perfil-form-dados-bancarios-perfil-form-dados-bancarios">
      <div
        onClick={() => setOpen(!open)}
        className="perfil-form-dados-bancarios-frame354"
      >
        <span className="perfil-form-dados-bancarios-text HeaderH3-18Medium">
          Dados bancários
        </span>
        {open ? (
          <img
            src="/external/keyboardarrowup1141-5bxq.svg"
            className="perfil-form-dados-bancarios-keyboardarrowup"
          />
        ) : (
          <img
            src="/external/keyboardarrowup1141-5bxq.svg"
            className="perfil-form-dados-bancarios-keyboardarrowdown"
          />
        )}
      </div>
      {open && (
        <Fragment>
          <div className="perfil-form-dados-bancarios-frame308">
            <span className="perfil-form-dados-bancarios-text01 TextMedium-16Medium">
              <span>Informações bancárias</span>
            </span>
            <div className="perfil-form-dados-bancarios-frame309">
            <div className="perfil-form-dados-bancarios-textfields">
        <span className="perfil-form-dados-bancarios-text03 TextSmall-14Regular">
          <span>Pesquisar banco</span>
        </span>
        <div className="perfil-form-dados-bancarios-frame2">
          <img
            src="/external/searchi523-8nw.svg"
            className="perfil-form-dados-bancarios-search"
          />
          <input
            value={bankSearchQuery}
            onFocus={handleFocus}
            onChange={(e) => {
              setBankSearchQuery(e.target.value);
              setShouldSearch(true);
            }}
            type="text"
            placeholder={
              "Pesquise pelo nome do banco"
            }
            className="perfil-form-dados-bancarios-textinput input"
          />                
          </div>
                {bankSearchResults.length > 0 && (
                  <div className="page-cadastro-form-step4-container1">
                    {bankSearchResults.map((bank) => {
                      return (
                        <div
                          key={bank._id}
                          onClick={() => handleSelectBank(bank)}
                          className="page-cadastro-form-step4-bank-item"
                        >
                          {bank.bankCode} - {bank.bankName}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="perfil-form-dados-bancarios-frame312">
                <div className="perfil-form-dados-bancarios-textfields1">
                  <span className="perfil-form-dados-bancarios-text05 TextSmall-14Regular">
                    <span>Agência</span>
                  </span>
                  <input
                    value={data.bankAgency}
                    onChange={(e) =>
                      setData({
                        ...data,
                        bankAgency: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Agência"
                    className="input"
                  />
                </div>
                <div className="perfil-form-dados-bancarios-textfields2">
                  <span className="perfil-form-dados-bancarios-text07 TextSmall-14Regular">
                    <span>Conta</span>
                  </span>
                  <input
                    value={data.bankAccount}
                    onChange={(e) =>
                      setData({
                        ...data,
                        bankAccount: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Conta"
                    className="input"
                  />
                </div>
              </div>
              <div className="perfil-form-dados-bancarios-textfields4">
                <span className="perfil-form-dados-bancarios-text13 TextSmall-14Regular">
                  <span>Chave pix</span>
                </span>
                <input
                  value={data.pixKey}
                  onChange={(e) => {
                    setData({
                      ...data,
                      pixKey: e.target.value,
                    })
                  }}
                  type="text"
                  placeholder="Chave pix"
                  className="input"
                />
              </div>
            </div>
          </div>
            <div className="perfil-form-dados-bancarios-frame306">
              <button
                className="perfil-form-dados-bancarios-buttons"
                onClick={() => {
                  props.handleUpdateUser(data);
                }}
              >
                <span className="perfil-form-dados-bancarios-text15 TextMedium-16Medium">
                  <span>Atualizar informações</span>
                </span>
              </button>
            </div>
        </Fragment>
      )}
    </div>
  );
};

export default PerfilFormDadosBancarios;