import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import { useUser } from "../states";

import "./login.css";

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useUser();
  const history = useHistory();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DATABASE}/auth/login`,
        {
          login: username,
          password,
        }
      );
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setUser(response.data.user);
        history.push('/');
      } else {
        toast.error(response.data.message || "Erro ao efetuar login");
      }
    } catch (error) {
      console.error("Login error", error);
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Erro interno no servidor";
      toast.error(errorMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e);
    }
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Body Dry</title>
        <meta property="og:title" content="Login - Body Dry" />
      </Helmet>
      <div className="login-conectar">
        <img
          alt="L21101"
          src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/2be29237-57c8-44a6-a051-680d46410acb/05b7f3b2-ceec-4aed-a239-6b20a01b1020?org_if_sml=18065&amp;force_format=original"
          className="login-l2"
        />
        <div className="login-frame300">
          <div className="login-frame347">
            <span>
              <span>Conecte-se</span>
            </span>
            <span className="login-text02 TextMedium-16Regular">
              <span>Insira seu nome de usuário e sua senha</span>
            </span>
          </div>
          <div className="login-frame309">
            <div className="login-textfields">
              <span className="login-text04 TextSmall-14Regular">
                <span>Usuário</span>
              </span>
              <input
                type="text"
                placeholder="Usuário"
                className="input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="login-textfields1">
              <span className="login-text06 TextSmall-14Regular">
                <span>Senha</span>
              </span>
              <div className="login-frame2">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Senha"
                  className="login-textinput1 input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <img
                  alt="visibilityI110"
                  src="/external/visibilityi110-1rb8.svg"
                  className="login-visibility"
                  onClick={togglePasswordVisibility}
                />
              </div>
              <Link
                to="/resetar-senha"
                className="login-text08 TextExtrasmall-12Regular"
              >
                <span>Recuperar senha</span>
              </Link>
            </div>
          </div>
          <button className="login-buttons" onClick={handleLogin}>
            <span className="login-text10 TextMedium-16Medium">
              <span>Conectar</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
