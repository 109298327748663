import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../components/header';
import PopupExcluir from '../components/popup-excluir-academy';
import PopupNovoEditarAula from '../components/popup-editadd-aula';
import PopupNovoEditarCurso from '../components/popup-editadd-curso';
import PopupNovoEditarQuiz from '../components/popup-editadd-quiz';
import { toast } from 'react-toastify';
import styles from './academy-admin.module.css';

const AcademyAdmin = () => {
  const [activeTab, setActiveTab] = useState('Cursos');
  const [showPopup, setShowPopup] = useState(null);
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const fetchData = async () => {
    try {
      const [coursesResponse, lessonsResponse, quizzesResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_DATABASE}/admin/academy/courses`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_DATABASE}/admin/academy/lessons`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${process.env.REACT_APP_DATABASE}/admin/academy/quizzes`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        })
      ]);

      const [coursesData, lessonsData, quizzesData] = await Promise.all([
        coursesResponse.json(),
        lessonsResponse.json(),
        quizzesResponse.json()
      ]);

      if (coursesResponse.ok && lessonsResponse.ok && quizzesResponse.ok) {
        setCourses(coursesData.courses || []);
        setLessons(lessonsData.lessons || []);
        setQuizzes(quizzesData.quizzes || []);
      } else {
        toast.error('Erro ao carregar dados');
        setCourses([]);
        setLessons([]);
        setQuizzes([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Erro ao carregar dados');
      setCourses([]);
      setLessons([]);
      setQuizzes([]);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedCourse('');
  };

  const handleSave = async (item, type) => {
    try {
      const formData = new FormData();
      
      for (let key in item) {
        if (key === 'perguntas' && Array.isArray(item[key])) {
          formData.append('perguntas', JSON.stringify(item[key]));
        } else if (key === 'capa' && item[key] instanceof File) {
          formData.append('capa', item[key]);
        } else if (key === 'newFiles' && Array.isArray(item[key])) {
          item[key].forEach((file, index) => {
            formData.append(`arquivos`, file);
          });
        } else if (key === 'arquivosToRemove' && Array.isArray(item[key])) {
          formData.append('arquivosToRemove', JSON.stringify(item[key]));
        } else if (item[key] !== null && item[key] !== undefined) {
          formData.append(key, item[key].toString());
        }
      }
  
      let url, method;
      if (type === 'course') {
        url = `${process.env.REACT_APP_DATABASE}/admin/academy/course${item._id ? `/${item._id}` : '/create'}`;
        method = item._id ? 'PUT' : 'POST';
      } else if (type === 'lesson') {
        url = `${process.env.REACT_APP_DATABASE}/admin/academy/lesson${item._id ? `/${item._id}` : '/create'}`;
        method = item._id ? 'PUT' : 'POST';
      } else if (type === 'quiz') {
        url = `${process.env.REACT_APP_DATABASE}/admin/academy/quiz${item._id ? `/${item._id}` : '/create'}`;
        method = item._id ? 'PUT' : 'POST';
      }
  
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        const action = item._id ? 'editado' : 'cadastrado';
        const itemType = type === 'course' ? 'Curso' : type === 'lesson' ? 'Aula' : 'Quiz';
        toast.success(`${itemType} ${action} com sucesso!`);
        fetchData();
        setShowPopup(null);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || `Erro ao salvar ${type}`);
      }
    } catch (error) {
      console.error('Error saving item:', error);
      toast.error(`Erro ao salvar ${type}`);
    }
  };

  const handleDelete = async (id, type) => {
    let typeDelete;

    if(type === 'curso') {
      typeDelete = 'course';
    } else if(type === 'aula') {
      typeDelete = 'lesson';
    } else {
      typeDelete = 'quiz';
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/academy/${typeDelete}/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response.ok) {
        const itemType = type === 'curso' ? 'Curso' : type === 'aula' ? 'Aula' : 'Quiz';
        toast.success(`${itemType} excluído com sucesso`);
        fetchData();
        setShowPopup(null);
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || `Erro ao excluir ${type}`);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error(`Erro ao excluir ${type}`);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination || !selectedCourse) return;
  
    const items = Array.from(lessons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setLessons(items);
  
    const updatedPositions = items.map((item, index) => ({
      id: item._id,
      posicao: index + 1,
    }));
  
    try {
      const response = await fetch(`${process.env.REACT_APP_DATABASE}/admin/academy/lessons/reorder`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ lessonOrder: updatedPositions.map(item => item.id) }),
      });
  
      if (response.ok) {
        toast.success('Aulas reordenadas com sucesso');
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Erro ao reordenar itens');
      }
    } catch (error) {
      console.error('Error updating positions:', error);
      toast.error('Erro ao reordenar itens');
    }
  };

  const filteredLessons = React.useMemo(() => {
    return selectedCourse
      ? lessons.filter(lesson => lesson.curso._id === selectedCourse)
      : lessons;
  }, [selectedCourse, lessons]);

  const filteredQuizzes = React.useMemo(() => {
    return selectedCourse
      ? quizzes.filter(quiz => quiz.curso._id === selectedCourse)
      : quizzes;
  }, [selectedCourse, quizzes]);

  return (
    <div className={styles.academyAdmin}>
      <Header />
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Academy</h1>
          <button className={styles.previewButton} onClick={() => window.open('/academy', '_blank')}>
            Preview Academy
          </button>
        </div>
        <div className={styles.tabs}>
          {['Cursos', 'Aulas', 'Quizzes'].map((tab) => (
            <button
              key={tab}
              className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {(activeTab === 'Aulas' || activeTab === 'Quizzes') && (
          <div className={styles.filterSection}>
            <select
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              className={styles.courseFilter}
            >
              <option value="">Todos os cursos</option>
              {courses.map((course) => (
                <option key={course._id} value={course._id}>
                  {course.nome}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.content}>
          {activeTab === 'Cursos' && (
            <CourseSection
              courses={courses}
              setShowPopup={setShowPopup}
            />
          )}
          {activeTab === 'Aulas' && (
            <LessonSection
              lessons={filteredLessons}
              setShowPopup={setShowPopup}
              onDragEnd={onDragEnd}
              selectedCourse={selectedCourse}
            />
          )}
          {activeTab === 'Quizzes' && (
            <QuizSection
              quizzes={filteredQuizzes}
              setShowPopup={setShowPopup}
              selectedCourse={selectedCourse}
            />
          )}
        </div>
      </div>
      
      {showPopup && showPopup.type.includes('excluir') && (
        <PopupExcluir
          type={showPopup.type}
          onClose={() => setShowPopup(null)}
          onConfirm={() => handleDelete(showPopup.data._id, showPopup.type.split('-')[1])}
        />
      )}

      {showPopup && showPopup.type === 'novo-curso' && (
        <PopupNovoEditarCurso
          isEditing={false}
          onSave={(course) => handleSave(course, 'course')}
          onCancel={() => setShowPopup(null)}
        />
      )}

      {showPopup && showPopup.type === 'editar-curso' && (
        <PopupNovoEditarCurso
          isEditing={true}
          initialData={showPopup.data}
          onSave={(course) => handleSave(course, 'course')}
          onCancel={() => setShowPopup(null)}
        />
      )}

      {showPopup && showPopup.type === 'nova-aula' && (
        <PopupNovoEditarAula
          isEditing={false}
          onSave={(lesson) => handleSave(lesson, 'lesson')}
          onCancel={() => setShowPopup(null)}
          courses={courses}
          selectedCourse={selectedCourse}
        />
      )}

      {showPopup && showPopup.type === 'editar-aula' && (
        <PopupNovoEditarAula
          isEditing={true}
          initialData={showPopup.data}
          onSave={(lesson) => handleSave(lesson, 'lesson')}
          onCancel={() => setShowPopup(null)}
          courses={courses}
        />
      )}

      {showPopup && showPopup.type === 'novo-quiz' && (
        <PopupNovoEditarQuiz
          isEditing={false}
          onSave={(quiz) => handleSave(quiz, 'quiz')}
          onCancel={() => setShowPopup(null)}
          courses={courses}
          selectedCourse={selectedCourse}
        />
      )}

      {showPopup && showPopup.type === 'editar-quiz' && (
        <PopupNovoEditarQuiz
          isEditing={true}
          initialData={showPopup.data}
          onSave={(quiz) => handleSave(quiz, 'quiz')}
          onCancel={() => setShowPopup(null)}
          courses={courses}
        />
      )}
    </div>
  );
};

const CourseSection = ({ courses, setShowPopup }) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <h2 className={styles.sectionTitle}>Cursos ({courses.length})</h2>
      <button className={styles.addButton} onClick={() => setShowPopup({ type: 'novo-curso' })}>
        Novo curso
      </button>
    </div>
    {courses.length === 0 ? (
      <EmptyState
        title="Nenhum curso encontrado"
        description="Comece criando seu primeiro curso e inspire seus influenciadores!"
        buttonText="Criar primeiro curso"
        onClick={() => setShowPopup({ type: 'novo-curso' })}
      />
    ) : (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Categoria</th>
            <th>Descrição</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course._id}>
              <td>{course.nome}</td>
              <td>{course.categoria}</td>
              <td>{course.descricao}</td>
              <td>
                <button className={styles.editButton} onClick={() => setShowPopup({ type: 'editar-curso', data: course })}>Editar</button>
                <button className={styles.deleteButton} onClick={() => setShowPopup({ type: 'excluir-curso', data: course })}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

const LessonSection = ({ lessons, setShowPopup, onDragEnd, selectedCourse }) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <h2 className={styles.sectionTitle}>Aulas ({lessons.length})</h2>
      <button className={styles.addButton} onClick={() => setShowPopup({ type: 'nova-aula' })}>
        Nova aula
      </button>
    </div>
    {lessons.length === 0 ? (
      <EmptyState
        title="Nenhuma aula encontrada"
        description={selectedCourse ? "Nenhuma aula para este curso. Crie sua primeira aula!" : "Crie sua primeira aula e comece a construir conteúdo incrível!"}
        buttonText="Criar primeira aula"
        onClick={() => setShowPopup({ type: 'nova-aula' })}
      />
    ) : (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="lessons" isDropDisabled={!selectedCourse}>
          {(provided) => (
            <table className={styles.table} {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Curso</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {lessons.map((lesson, index) => (
                  <Draggable key={lesson._id} draggableId={lesson._id} index={index} isDragDisabled={!selectedCourse}>
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td>{lesson.nome}</td>
                        <td>{lesson.descricao}</td>
                        <td>{lesson.curso.nome}</td>
                        <td>
                          <button className={styles.editButton} onClick={() => setShowPopup({ type: 'editar-aula', data: lesson })}>Editar</button>
                          <button className={styles.deleteButton} onClick={() => setShowPopup({ type: 'excluir-aula', data: lesson })}>Excluir</button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    )}
  </div>
);

const QuizSection = ({ quizzes, setShowPopup, selectedCourse }) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <h2 className={styles.sectionTitle}>Quizzes ({quizzes.length})</h2>
      <button className={styles.addButton} onClick={() => setShowPopup({ type: 'novo-quiz' })}>
        Novo quiz
      </button>
    </div>
    {quizzes.length === 0 ? (
      <EmptyState
        title="Nenhum quiz encontrado"
        description={selectedCourse ? "Nenhum quiz para este curso. Crie seu primeiro quiz!" : "Crie seu primeiro quiz e teste o conhecimento dos seus influencers!"}
        buttonText="Criar primeiro quiz"
        onClick={() => setShowPopup({ type: 'novo-quiz' })}
      />
    ) : (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Curso</th>
            <th>Perguntas</th>
            <th>Tempo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {quizzes.map((quiz) => (
            <tr key={quiz._id}>
              <td>{quiz.curso.nome}</td>
              <td>{quiz.perguntas.length}</td>
              <td>{quiz.tempo} minutos</td>
              <td>
                <button className={styles.editButton} onClick={() => setShowPopup({ type: 'editar-quiz', data: quiz })}>Editar</button>
                <button className={styles.deleteButton} onClick={() => setShowPopup({ type: 'excluir-quiz', data: quiz })}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

const EmptyState = ({ title, description, buttonText, onClick }) => (
  <div className={styles.emptyState}>
    <div className={styles.emptyStateIcon}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
    </div>
    <h3 className={styles.emptyStateTitle}>{title}</h3>
    <p className={styles.emptyStateDescription}>{description}</p>
    <button className={styles.emptyStateButton} onClick={onClick}>{buttonText}</button>
  </div>
);

export default AcademyAdmin;