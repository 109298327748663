import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FaStar } from 'react-icons/fa';
import Header from '../components/header';
import './avaliacoes-admin.css';

const Avaliacoes = () => {
  const history = useHistory();
  const [reviews, setReviews] = useState([]);
  const [expandedReview, setExpandedReview] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchReviews();
  }, [currentPage]);

  const fetchReviews = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_DATABASE}/review`, {
        params: { page: currentPage, limit: 5 },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setReviews(response.data.reviews);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setError('Erro ao carregar avaliações. Por favor, tente novamente.');
      setLoading(false);
    }
  };

  const handleExpandReview = (id) => {
    setExpandedReview(expandedReview === id ? null : id);
  };

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, index) => (
      <FaStar key={index} color={index < rating ? '#ffc107' : '#e4e5e9'} />
    ));
  };

  const timeSince = (date) => {
    const now = new Date();
    const reviewDate = new Date(date);
    const seconds = Math.floor((now - reviewDate) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return `${interval} anos atrás`;
    if (interval === 1) return '1 ano atrás';

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} meses atrás`;
    if (interval === 1) return '1 mês atrás';

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} dias atrás`;
    if (interval === 1) return '1 dia atrás';

    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} horas atrás`;
    if (interval === 1) return '1 hora atrás';

    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutos atrás`;
    if (interval === 1) return '1 minuto atrás';

    return 'agora mesmo';
  };

  const getTotalPages = () => {
    return Math.ceil(reviews.length / reviewsPerPage);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const renderReviewCard = (review) => {
    const { id, name, username, profilePicture, comment, rating, date } = review;
    const acronym = name.split(' ').map((word) => word.charAt(0)).join('').slice(0, 2).toUpperCase();
    const isExpanded = expandedReview === id;

    return (
      <div key={id} className="review-card" onClick={() => {
        history.push(`/usuario/${username}`);
      }}>
        <div className="review-header">
          <div className="profile-image">
            {profilePicture ? (
              <img src={profilePicture} alt={name} className="profile-picture" />
            ) : (
              <div className="profile-acronym">{acronym}</div>
            )}
          </div>
          <div className="review-info">
            <h3 className="review-name">
              <a href={`/usuarios/${username}`}>{name}</a>
            </h3>
            <div className="review-rating">{renderStars(rating)}</div>
            <p className="review-date">{timeSince(date)}</p>
          </div>
        </div>
        <div className="review-comment">
          {isExpanded ? comment : `${comment.slice(0, 100)}${comment.length > 100 ? '...' : ''}`}
          {comment.length > 100 && (
            <button className="expand-button" onClick={() => handleExpandReview(id)}>
              {isExpanded ? 'Mostrar menos' : 'Mostrar mais'}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="avaliacoes-main">
        <div className="avaliacoes-container">
        <h1 className="avaliacoes-title">Avaliações dos Usuários</h1>
        <div className="review-list">
          {reviews && reviews.map((review) => renderReviewCard(review))}
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index}
                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default Avaliacoes;
