import React, { useState, useEffect, useCallback } from 'react';
import './post-comments.css';
import Header from '../components/header';
import { useParams, useHistory } from 'react-router-dom';
import PopupExcluirPost from '../components/popup-excluir-post';
import axios from 'axios';
import { useUser } from "../states";
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const PostComments = () => {
    const { id } = useParams();
    const { user } = useUser();
    const [post, setPost] = useState({});
    const [comments, setComments] = useState([]);
    const [replyBoxVisibility, setReplyBoxVisibility] = useState({});
    const [replyContents, setReplyContents] = useState({});
    const [mobileReplyPlaceholder, setMobileReplyPlaceholder] = useState('');
    const [filterOption, setFilterOption] = useState('all');
    const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
    const [isDeleteCommentPopupOpen, setIsDeleteCommentPopupOpen] = useState(false);
    const [currentComment, setCurrentComment] = useState(null);
    const [newCommentContent, setNewCommentContent] = useState('');
  
    const formatPostDate = useCallback((date) => {
        const postDate = new Date(date);
        const now = new Date();
        const diffInHours = Math.abs(now - postDate) / 36e5;

        if (diffInHours < 24) {
            return formatDistanceToNow(postDate, { addSuffix: true, locale: ptBR });
        } else if (diffInHours < 48) {
            return `Ontem às ${format(postDate, 'HH:mm')}`;
        } else {
            return format(postDate, "d 'de' MMMM 'às' HH:mm", { locale: ptBR });
        }
    }, []);

    const fetchComments = useCallback(async (postId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DATABASE}/community/comments?postId=${postId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setPost(response.data.post);
            setComments(response.data.comments);
            setMobileReplyPlaceholder(`Responder @${response.data.post.author.name}`);
        } catch (error) {
            console.error('Error fetching comments:', error);
            history.replace('/comunidade');
        }
    }, [history]);

    useEffect(() => {
        fetchComments(id);
    }, [id, fetchComments]);

    const handleReply = async (commentId, content, parentCommentId = null) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_DATABASE}/community/comments`,
                {
                    postId: id,
                    content: content,
                    parentCommentId: parentCommentId || commentId
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.data.success) {
                fetchComments(id);
                setReplyBoxVisibility((prevState) => ({
                    ...prevState,
                    [commentId]: false,
                }));
                setReplyContents((prevState) => ({
                    ...prevState,
                    [commentId]: '',
                }));
            }
        } catch (error) {
            console.error('Error replying to comment:', error);
        }
    };

    const handleLikeComment = async (commentId) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_DATABASE}/community/comments/${commentId}/like`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.data.success) {
                fetchComments(id);
            }
        } catch (error) {
            console.error('Error liking comment:', error);
        }
    };

    const handleDeleteComment = (commentId, commentContent) => {
        setCurrentComment({ id: commentId, popupContent: commentContent });
        setIsDeleteCommentPopupOpen(true);
    };

    const confirmDeleteComment = async () => {
        if (currentComment) {
            try {
                await axios.delete(`${process.env.REACT_APP_DATABASE}/community/comments/${currentComment.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                fetchComments(id);
                setIsDeleteCommentPopupOpen(false);
                setCurrentComment(null);
            } catch (error) {
                console.error('Error deleting comment:', error);
            }
        }
    };

    const getUserInitials = (fullName) => {
        const names = fullName.split(' ');
        const firstInitial = names[0].charAt(0).toUpperCase();
        const lastInitial = names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : '';
        return `${firstInitial}${lastInitial}`;
    };

    const toggleReplyBox = (commentId, replyId = null, author) => {
        const key = replyId ? `${commentId}-${replyId}` : `${commentId}`;
        setReplyBoxVisibility((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
        if (window.innerWidth <= 1015) {
            setMobileReplyPlaceholder(`Responder @${author}`);
        }
    };

    const goBack = () => {
        window.history.back();
    };

    const filterComments = (comments, option) => {
        if (option === 'all') {
            return comments;
        } else if (option === 'yours') {
            return comments.filter((comment) => comment.author._id === user._id);
        }
        return comments;
    };

    const toggleFilterDropdown = () => {
        setIsFilterDropdownVisible(!isFilterDropdownVisible);
    };

    const selectFilterOption = (option) => {
        setFilterOption(option);
        setIsFilterDropdownVisible(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.post-comments__filter-dropdown')) {
                setIsFilterDropdownVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleCommentInputChange = (e) => {
        setNewCommentContent(e.target.value);
    };

    const handleReplyInputChange = (e, commentId) => {
        setReplyContents((prevState) => ({
            ...prevState,
            [commentId]: e.target.value,
        }));
    };

    const handleSubmitComment = async () => {
        if (!newCommentContent.trim()) return;

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_DATABASE}/community/comments`,
                {
                    postId: id,
                    content: newCommentContent,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.data.success) {
                fetchComments(id);
                setNewCommentContent('');
            }
        } catch (error) {
            console.error('Error creating comment:', error);
        }
    };

    const renderComment = (comment, level = 0) => {
        return (
            <div key={comment._id} className={`post-comments__comment ${level > 0 ? `post-comments__comment--level-${level}` : ''}`}>
                <div className="post-comments__comment-main">
                    <img className="post-comments__avatar" src={comment.author.profilePicture || ''} alt="Avatar" onError={(e) => e.target.style.display = 'none'} />
                    {!comment.author.profilePicture && (
                        <div className="post-comments__avatar-initials">
                            {getUserInitials(comment.author.name)}
                        </div>
                    )}
                    <div className="post-comments__comment-content">
                        <div className="post-comments__comment-header">
                            <div className="post-comments__comment-author-timestamp">
                                <div className="post-comments__comment-author">
                                    {comment.author.name}
                                    {comment.author.type === 'admin' && (
                                        <span className="comunidade__author-admin-badge">Admin</span>
                                    )}
                                </div>
                                <div className="post-comments__comment-timestamp">{formatPostDate(comment.createdAt)}</div>
                            </div>
                        </div>
                        <div className="post-comments__comment-text">{comment.content}</div>
                        <div className="post-comments__comment-actions">
                            <button className="post-comments__like-button" onClick={() => handleLikeComment(comment._id)}>
                                <svg className="post-comments__like-icon" viewBox="0 0 24 24">
                                    <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"/>
                                </svg>
                                <span className="post-comments__like-count">{comment.likes.length}</span>
                            </button>
                            {level < 2 && (
                                <button
                                    className="post-comments__reply-button"
                                    onClick={() => toggleReplyBox(comment._id, null, comment.author.name)}
                                >
                                    <svg className="post-comments__reply-icon" viewBox="0 0 24 24">
                                        <path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"/>
                                    </svg>
                                    <span>Responder</span>
                                </button>
                            )}
                            {(comment.author._id === user._id || user.type === 'admin') && (
                                <button
                                    className="post-comments__delete-button"
                                    onClick={() => handleDeleteComment(comment._id, comment.author._id === user._id ? 'seu comentário' : 'esse comentário')}
                                >
                                    <svg className="post-comments__delete-icon" viewBox="0 0 24 24">
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                                    </svg>
                                    <span>Excluir</span>
                                </button>
                            )}
                        </div>
                        {replyBoxVisibility[comment._id] && (
                            <div className="post-comments__reply-input">
                                <textarea
                                    className="post-comments__reply-textarea"
                                    placeholder="Adicione um comentário"
                                    rows="3"
                                    value={replyContents[comment._id] || ''}
                                    onChange={(e) => handleReplyInputChange(e, comment._id)}
                                />
                                <button 
                                    className="post-comments__send-button"
                                    onClick={() => handleReply(comment._id, replyContents[comment._id])}
                                >
                                    Enviar
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {comment.replies && comment.replies.length > 0 && level < 2 && (
                    <div className="post-comments__replies">
                        {comment.replies.map(reply => renderComment(reply, level + 1))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="post-comments">
            <Header />
            <div className="post-comments__header">
                <button className="post-comments__back-button" onClick={goBack}>
                    <svg className="post-comments__back-icon" viewBox="0 0 24 24">
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
                    </svg>
                </button>
                {post && post.author && (
                    <h2 className="post-comments__title">Post de {post.author.name}</h2>
                )}
            </div>
            <div className="post-comments__content">
                <div className="post-comments__info">
                    <span className="post-comments__count">{comments?.length || 0} comentários</span>
                    <div className="post-comments__filter">
                        <div className="post-comments__filter-dropdown" onClick={toggleFilterDropdown}>
                            <span className="post-comments__filter-text">{filterOption === 'all' ? 'Todos os comentários' : 'Seus comentários'}</span>
                            <svg className="post-comments__filter-icon" viewBox="0 0 24 24">
                                <path d="M7 10l5 5 5-5z"/>
                            </svg>
                            {isFilterDropdownVisible && (
                                <div className="post-comments__filter-options">
                                    <button
                                        className={`post-comments__filter-option ${filterOption === 'all' ? 'post-comments__filter-option--active' : ''}`}
                                        onClick={() => selectFilterOption('all')}
                                    >
                                        Todos os comentários
                                    </button>
                                    <button
                                        className={`post-comments__filter-option ${filterOption === 'yours' ? 'post-comments__filter-option--active' : ''}`}
                                        onClick={() => selectFilterOption('yours')}
                                    >
                                        Seus comentários
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="post-comments__list">
                    {filterComments(comments, filterOption).map(comment => renderComment(comment))}
                </div>
            </div>
            <div className="post-comments__current-user-reply">
                <div className="post-comments__current-user-reply-content">
                    {user.profilePicture ? (
                        <img 
                            className="post-comments__current-user-reply-avatar" 
                            src={user.profilePicture} 
                            alt="Avatar" 
                        />
                    ) : (
                        <div className="post-comments__avatar-initials">
                            {getUserInitials(user.name)}
                        </div>
                    )}
                    <input
                        type="text"
                        className="post-comments__current-user-reply-input"
                        placeholder={mobileReplyPlaceholder}
                        value={newCommentContent}
                        onChange={handleCommentInputChange}
                    />
                    <button 
                        className="post-comments__current-user-reply-button"
                        onClick={handleSubmitComment}
                    >
                        <svg className="post-comments__current-user-reply-icon" viewBox="0 0 24 24">
                            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/>
                        </svg>
                    </button>
                </div>
            </div>

            {isDeleteCommentPopupOpen && (
                <PopupExcluirPost
                    type="comment"
                    commentContent={currentComment?.popupContent}
                    onClose={() => setIsDeleteCommentPopupOpen(false)}
                    onConfirm={confirmDeleteComment}
                />
            )}
        </div>
    );
};

export default PostComments;
